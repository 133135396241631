/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRID SYSTEM ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.grid {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;

	&.grid_reverse {
		flex-direction:row-reverse;
	}

	&.grid_valign-middle {
		align-items:center;
	}

	@media screen and (max-width:768px)
	{
		margin-left:0 !important; margin-right:0 !important;
	}

	/* Widths */
	.width_01-12 { width:calc(1 / 12 * 100%); }
	.width_02-12 { width:calc(2 / 12 * 100%); }
	.width_03-12 { width:calc(3 / 12 * 100%); }
	.width_04-12 { width:calc(4 / 12 * 100%); }
	.width_05-12 { width:calc(5 / 12 * 100%); }
	.width_06-12 { width:calc(6 / 12 * 100%); }
	.width_07-12 { width:calc(7 / 12 * 100%); }
	.width_08-12 { width:calc(8 / 12 * 100%); }
	.width_09-12 { width:calc(9 / 12 * 100%); }
	.width_10-12 { width:calc(10 / 12 * 100%); }
	.width_11-12 { width:calc(11 / 12 * 100%); }
	.width_12-12 { width:calc(12 / 12 * 100%); }

	/* Column */
	.column {
		@extend .flex_column;
		box-sizing:border-box;

		@media screen and (max-width:768px)
		{
			padding-left:0 !important; padding-right:0 !important;
			width:100%;
		}
	}

	/* Gutter 96px */
	&.gutter_96 {
		margin-left:-48px; margin-right:-48px;

		& > .column { padding-left:48px; padding-right:48px; }
	}

	/* Gutter 64px */
	&.gutter_64 {
		margin-left:-32px; margin-right:-32px;

		& > .column { padding-left:32px; padding-right:32px; }
	}

	/* Gutter 48px */
	&.gutter_48 {
		margin-left:-24px; margin-right:-24px;

		& > .column { padding-left:24px; padding-right:24px; }
	}

	/* Gutter 24px */
	&.gutter_24 {
		margin-left:-12px; margin-right:-12px;

		& > .column { padding-left:12px; padding-right:12px; }
	}

	/* Gutter 12px */
	&.gutter_12 {
		margin-left:-6px; margin-right:-6px;

		& > .column { padding-left:6px; padding-right:6px; }
	}

	/* Gutter 6px */
	&.gutter_6 {
		margin-left:-3px; margin-right:-3px;

		& > .column { padding-left:3px; padding-right:3px; }
	}
}
