/* --------------------------------------------------------------------------------------------------- */
/* WIDGETS ------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.widgets {
	flex:1 1 auto;
	margin-top:-12px;

	&:not(:first-child) {
		padding-top:48px;
	}

	@media screen and (max-width:768px)
	{
		margin-top:-6px; padding-bottom:24px;

      // Hide navigation widget border when it becomes sticky - must be first widget!
      .widget {
        &:first-child {
          border: none;
          padding: 0;
        }
      }

		&:not(:first-child) {
			padding-top:24px;
		}
	}
}
	.widget {
		margin-top:12px; padding:24px 24px 0;
		border:2px solid $color_theme_01-05;

		@media screen and (max-width:768px)
		{
			margin-top:6px;
		}

		/*
		.nav-side {
			padding-bottom:24px;
		}
		*/
	}
		.widget-heading {
			font-size:18px;
			font-weight:700;

			&:not(:first-child) {
				padding-top:18px;
			}
		}
		.widget-image_wrapper,
		.widget-button {
			margin:0 -26px;
			width:calc(100% + 52px);
		}
		.widget-image_wrapper {

			&:first-child {
				margin-top:-26px;
			}
			&:not(:first-child) {
				padding-top:18px;
			}
		}
		.widget-text {
			padding-top:12px;

			&:last-child {
				padding-bottom:24px;
			}
		}
		.widget-button {
			margin-top:18px; margin-bottom:-2px;
			text-align:center;
		}

.nav-side + .widgets {

	@media screen and (max-width:768px)
	{
		padding-top:0;
	}
}
