/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list {

	& + .pagination {
		padding:96px 0 0;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			padding:48px 0 0;
		}
		@media screen and (max-width:768px)
		{
			padding:24px 0 0;
		}
	}
}

	.list_item-thumb {
		@extend .relative;
		display:block;
		background:$color_theme_01-01;
		overflow:hidden;

		&:before {
			@extend .relative;
			content:"";
			display:block;
			padding-top:56.25%;
			width:100%; height:0;
			overflow:hidden;
		}

		@media screen and (min-width:769px)
		{
			&:hover .list_item-thumb_image,
			&:focus .list_item-thumb_image {
				opacity:.32;
				transform:scale(1.05);
			}
		}
	}
		.list_item-thumb_image {
			@extend .animate;
			position:absolute !important;
			top:0; left:0; right:0; bottom:0;
			z-index:2 !important;
			width:100% !important; height:100% !important;
			object-fit:cover; font-family:'object-fit:cover;';
		}

	.list_item-info_heading {
		margin-top:6px;
	}
		.list_item-info_heading-link {
			@extend .animate;
			@extend .relative;
			display:inline-block;
			color:$color_theme_01-01;

			@media screen and (min-width:769px)
			{
				padding-left:24px;
			}

			&:before {
				@extend .animate;
				@extend .absolute;
				content:"";

				@media screen and (min-width:769px)
				{
					top:6px; left:0; bottom:6px;
					width:2px;
					background:$color_theme_01-01;
				}
			}

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:$color_theme_02-01;

					&:before {
						background:$color_theme_02-01;
					}
				}
			}
		}

	.list_item-info_categories,
	.list_item-info_categories-list {
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		color:$color_theme_01-03;
	}
		.list_item-info_categories-list {
			list-style:none;
		}
			.list_item-info_categories-list_item {
				@extend .flex_row;

				&:not(:last-child) {
					padding-right:4px;

					&:after {
						content:",";
					}
				}
			}
			.list_item-info_categories-list_item-link {
				@extend .animate;
				color:$color_theme_01-03;

				@media screen and (min-width:769px)
				{
					&:hover,
					&:focus {
						color:$color_theme_02-01;
					}
				}
			}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list-01 {
	margin-top:-24px; padding-top:48px;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		&.grid {
			margin-left:-6px; margin-right:-6px;

			& > .column {
				padding-left:6px; padding-right:6px;
			}
		}
	}
	@media screen and (max-width:768px)
	{
		padding-top:24px;
	}

	.slick-slide {

		@media screen and (min-width:1025px)
		{
			max-width:calc(33.33% - 24px);
		}
		@media screen and (max-width:1024px) and (min-width:769px)
		{
			max-width:calc(50% - 24px);
		}
		@media screen and (min-width:769px)
		{
			padding:0 12px;
		}
		@media screen and (max-width:1220px) and (min-width:769px)
		{
			padding:0 6px;
			// max-width:calc(33.33% - 12px);
		}
	}

	&.color-inverted {
		color:#FFF;

		@media screen and (max-width:768px)
		{
			margin-top:-48px;
			width:calc(100% + 48px);
			transform:translateX(-24px);
		}

		.list-01_item-info_heading-link {
			color:#FFF;

			@media screen and (max-width:768px)
			{
				padding-left:24px;
			}

			&:before {
				background:#FFF;

				@media screen and (max-width:768px)
				{
					top:6px; left:0; bottom:6px;
					width:2px;
				}
			}

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:#FFF;
				}
			}
		}

		& + .btn-prev,
		& + .btn-prev + .btn-next {
			bottom:10px;
		}
	}

	&:not(.color-inverted) {

		.list-01_item-info {

			@media screen and (max-width:768px)
			{
				padding:0 24px 24px;
				border:1px solid $color_theme_01-05; border-top:none;
			}
		}
		.list-01_item-info_date,
		.list-01_item-info_excerpt,
		.list-01_item-info_categories,
		.list-01_item-info_btn-wrapper {

			@media screen and (max-width:768px)
			{
				padding-left:0;
			}
		}
	}
}
	.list-01_item {
		display:flex !important;
		padding-top:24px;
	}
		.list-01_item-info {
			@extend .flex_column;
			flex:1 1 auto;
			padding:0 24px;

			@media screen and (min-width:769px)
			{
				align-items:flex-start;
			}
		}
			.list-01_item-info_date,
			.list-01_item-info_btn-wrapper {
				padding:24px 0 0 24px;

				@media screen and (min-width:769px)
				{
					width:calc(100% - 24px);
				}
			}
			.list-01_item-info_heading {

				&:first-child {
					margin-top:24px;
				}
			}
			.list-01_item-info_excerpt,
			.list-01_item-info_categories {
				padding:12px 0 0 24px;

				@media screen and (min-width:769px)
				{
					width:calc(100% - 24px);
				}
			}
			.list-01_item-info_categories span {
				padding-right:4px;
			}
			.list-01_item-info_btn-wrapper {
				@extend .flex_row;
				justify-content:space-between;
				margin-top:auto;

				@media screen and (max-width:1410px) and (min-width:1025px)
				{
					flex-direction:column;
				}
			}
				.list-01_item-info_btn {

					&:not(:only-child) {
						width:calc(50% - 3px);

						@media screen and (max-width:1410px) and (min-width:1025px)
						{
							margin-top:3px;
							width:auto;
						}
					}
				}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 2 & 3) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list-02_item-thumb,
.list-03_item-thumb {
	flex:1 1 auto;

	&:before {
		padding-top:75%;
	}
}

.list-02_item-info,
.list-03_item-info {

	@media screen and (min-width:769px)
	{
		flex-direction:row !important;
	}
	@media screen and (max-width:768px)
	{
		border:1px solid $color_theme_01-05; border-top:none;
	}
}

	.list-02_item-info_content-heading,
	.list-03_item-info_heading {

		@media screen and (max-width:768px)
		{
			margin-top:0; padding:24px 24px 0;
		}
	}
		.list-02_item-info_content-heading_link,
		.list-03_item-info_heading-link {
			color:$color_theme_03-01;

			&:before {
				background:$color_theme_03-01 !important;
			}

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:$color_theme_01-01;

					&:before {
						background:$color_theme_01-01 !important;
					}
				}
			}
		}

	.list-02_item-info_content-excerpt,
	.list-03_item-info_excerpt {
		padding:12px 0 0 24px;
		color:$color_theme_01-03;

		@media screen and (max-width:768px)
		{
			padding:12px 24px 0;
		}
	}

	.list-02_item-info_content-detail,
	.list-03_item-info_detail {
		padding:24px 0 12px 24px;

		@media screen and (max-width:768px)
		{
			padding:24px 24px 0;
		}
	}
		.list-02_item-info_content-detail_item,
		.list-03_item-info_detail-item {
			@extend .flex_row;
			align-items:baseline;
		}
			.list-02_item-info_content-detail_item-label,
			.list-03_item-info_detail-item_label {
				flex-shrink:0;
				width:20%;
				color:$color_theme_01-01;
				font-size:12px;
				font-weight:600;
				text-transform:uppercase;

				@media screen and (max-width:768px)
				{
					width:30%;
				}
			}
			.list-02_item-info_content-detail_item-value,
			.list-03_item-info_detail-item_value {
				flex:1 1 auto;
				padding-left:24px;
				color:$color_theme_01-03;
			}

	.list-02_item-info_btn-wrapper,
	.list-03_item-btn_wrapper {
		@extend .flex_column;
		flex-shrink:0;

		@media screen and (max-width:768px)
		{
			flex-direction:row;
			justify-content:space-between;
			margin-left:0; padding:24px;
		}
	}
		.list-02_item-info_btn,
		.list-03_item-btn {
			flex-shrink:0;

			&:not(:first-child) {

				@media screen and (min-width:769px)
				{
					margin-top:6px;
				}
			}
			&:not(:only-child) {

				@media screen and (max-width:768px)
				{
					width:calc(50% - 3px);
				}
			}
		}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 2) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list-02 {
	margin-top:-12px;
}
	.list-02_item {
		padding-top:12px;
	}
		.list-02_item-info_content {
			flex:1 1 auto;
		}
			.list-02_item-info_btn-wrapper {
				justify-content:center;
				margin-left:48px;
			}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 2 - VARIANT 2) ---------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list-02-02 {
	margin-top:-48px; padding-top:96px;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding-top:48px;
	}
	@media screen and (max-width:768px)
	{
		margin-top:-12px; padding-top:24px;
	}

	.list-02_item {
		padding-top:48px;

		@media screen and (max-width:768px)
		{
			padding-top:12px;

			& > .column:first-child {
				order:2;
			}
			& > .column:last-child {
				order:1;
			}
		}
	}

		.list-02_item-thumb {
			@extend .relative;

			&:before {
				padding-top:56.25%;
			}
		}

		.list-02_item-info_content {

			@media screen and (max-width:768px)
			{
				padding-bottom:24px;
			}
		}
			.list-02_item-info_content-detail_item {
				flex-direction:column;

				&:not(:first-child) {
					padding-top:12px;
				}
			}
				.list-02_item-info_content-detail_item-label {
					width:auto;
				}
				.list-02_item-info_content-detail_item-value {
					padding-left:0;
				}

			.list-02_item-info_btn {
				position:absolute;
				right:0; bottom:24px;
				z-index:2;

				@media screen and (max-width:768px)
				{
					right:-6px; bottom:6px;
				}
			}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 2 - VARIANT 3) ---------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list-02-03 {

	.list-02_item:not(:first-child) {

		@media screen and (min-width:769px)
		{
			margin-top:48px; padding-top:48px;
			border-top:1px solid $color_theme_01-05;
		}
		@media screen and (max-width:768px)
		{
			padding-top:12px;
		}
	}
		.list-02_item-thumb {
			flex:none;
		}
		.list-02_item-info_content-detail {
			padding-top:18px; padding-bottom:0;
		}
		.list-02_item-info_btn {

			@media screen and (min-width:769px)
			{
				min-width:162px;
			}

			&:only-child {

				@media screen and (max-width:768px)
				{
					width:100%;
				}
			}
		}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 3) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list-03 {
}

	.list-03_item {

		@media screen and (max-width:1100px) and (min-width:769px)
		{
			& > .grid > .column.width_03-12 {
				width:40%;
			}
			& > .grid > .column.width_09-12 {
				width:60%;
			}
		}

		&:not(:first-child) {

			@media screen and (min-width:769px)
			{
				margin-top:24px; padding-top:24px;
				border-top:1px solid $color_theme_01-05;
			}
			@media screen and (max-width:768px)
			{
				padding-top:12px;
			}
		}
	}
		.list-03_item-btn_wrapper {

			@media screen and (min-width:769px)
			{
				margin-top:6px;
			}
		}
			.list-03_item-btn {

				&:only-child {

					@media screen and (max-width:768px)
					{
						width:100%;
					}
				}
			}

	.list-03_item-info_heading + .list-03_item-info_detail {
		padding:12px 0 0 24px;
	}
	.list-03_item-info_detail + .list-03_item-info_excerpt {
		padding:24px 0 12px 24px;

		@media screen and (max-width:768px)
		{
			padding:24px 24px 0;
		}
	}
