/* --------------------------------------------------------------------------------------------------- */
/* BOOKING ------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.booking-table {
	padding-top:96px;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding-top:48px;
	}
	@media screen and (max-width:768px)
	{
		padding-top:24px;
	}
}
	.booking-table_heading {
	}
	.booking-table_subheading {
		font-size:24px;

		@media screen and (max-width:768px)
		{
			font-size:18px;
		}
	}

	.booking-table_month {
		padding-top:24px;
	}

	.booking-table_item {
		@extend .flex_row;
		margin-top:12px;
		border:4px solid $color_theme_01-05;

		@media screen and (max-width:768px)
		{
			flex-direction:column;
		}

		&.is-disabled {

			.booking-table_item-date {
				color:rgba($color_theme_01-02, .24);
			}
			.booking-table_item-timeslot {
				opacity:.24;

				button {
					background:$color_theme_01-03 !important;
					cursor:default;
				}
			}
		}
	}
		.booking-table_item-date,
		.booking-table_item-timeslots {
			flex-shrink:0;
		}
		.booking-table_item-date {
			padding:24px;
			font-weight:600;
			width:25%;
			border-right:2px solid $color_theme_01-05;
			box-sizing:border-box;

			@media screen and (max-width:768px)
			{
				padding:16px 18px;
				width:100%;
				border-right:none; border-bottom:2px solid $color_theme_01-05;
			}
		}
		.booking-table_item-timeslots {

			@media screen and (min-width:769px)
			{
				width:75%;
			}
		}
			.booking-table_item-timeslot {
				@extend .flex_row;
				align-items:center;
				padding:24px;
				box-sizing:border-box;

				@media screen and (max-width:768px)
				{
					padding:16px 18px;
				}

				&:not(:first-child) {
					border-top:2px solid $color_theme_01-05;
				}

				&.is-disabled {
					opacity:.24;

					button {
						background:$color_theme_01-03 !important;
						cursor:default;
					}
				}
			}
				.booking-table_item-timeslot_lhc {
					@extend .flex_row;
					flex:1 1 auto;
					align-items:center;

					@media screen and (max-width:768px)
					{
						flex-direction:column;
						align-items:flex-start;
					}
				}
					.booking-table_item-timeslot_message {
						flex:1 1 auto;
						font-size:12px;

						@media screen and (min-width:769px)
						{
							padding-left:24px;
						}
					}
						.booking-table_item-timeslot_message-block span:not(:last-child):after {
							content:",";
						}
				.booking-table_item-timeslot_link {
					flex-shrink:0;
					margin:-12px -12px -12px 0; padding-left:24px;

					@media screen and (max-width:768px)
					{
						padding-left:18px;
					}
				}
