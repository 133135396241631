/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pagination {
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION (TYPE 01) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pagination-01 {
	@extend .flex_column;
	align-items:center;
	padding:0 96px 96px;

	@media screen and (max-width:768px)
	{
		padding:0 24px 24px;
	}
}
	.pagination-01_list {
		@extend .flex_row;
		justify-content:center;
		width:100%;
		list-style:none;
	}
		.pagination-01_page {
			@extend .flex_column;
			justify-content:center;

			.pagination-01_list-item_link {
				@extend .animate;
				padding:12px;

				@media screen and (max-width:768px)
				{
					padding:6px 12px;
				}
			}

			&.is-current .pagination-01_list-item_link {
				color:$color_theme_03-01;
				font-weight:700;
			}

			@media screen and (min-width:769px)
			{
				&:not(.is-current):hover .pagination-01_list-item_link,
				&:not(.is-current):focus .pagination-01_list-item_link {
					color:$color_theme_02-01;
				}
			}
		}

		.pagination-01_prev,
		.pagination-01_next {

			.pagination-01_list-item_link {
				@extend .animate;
				@extend .flex_column;
				justify-content:center;
				align-items:center;
				width:48px; height:48px;
				background:$color_theme_01-01;
				border-radius:100%;

				@media screen and (max-width:768px)
				{
					width:32px; height:32px;
				}

				svg {
					display:block;
					width:18px; height:18px;
					fill:#FFF;

					@media screen and (max-width:768px)
					{
						width:12px; height:12px;
					}
				}
			}

			&.is-disabled .pagination-01_list-item_link {
				background:$color_theme_01-05;
			}

			@media screen and (min-width:769px)
			{
				&:not(.is-disabled):hover .pagination-01_list-item_link,
				&:not(.is-disabled):focus .pagination-01_list-item_link {
					background:$color_theme_02-01;
				}
			}
		}
		.pagination-01_prev {
			padding-right:48px;

			@media screen and (max-width:768px)
			{
				padding-right:24px;
			}

			.pagination-01_list-item_link svg {
				transform:rotate(180deg);
			}
		}
		.pagination-01_next {
			padding-left:48px;

			@media screen and (max-width:768px)
			{
				padding-left:24px;
			}
		}
