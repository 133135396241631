/* --------------------------------------------------------------------------------------------------- */
/* PANEL --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.panel {
	@extend .animate;
	position:fixed;
	top:0; left:0; right:0; bottom:0;
	z-index:1001;
	background:rgba($color_theme_01-01, .88);
	opacity:0;
	visibility:hidden;

	@media screen and (max-width:768px)
	{
		background:rgba(#FFF, .48);
	}

	&.is-active {
		opacity:1;
		visibility:visible;

		.panel-container {
			transform:translateX(0);
		}
	}
}

	.panel-close {
		@extend .flex_column;
		justify-content:center;
		align-items:center;
		position:absolute;
		top:0; left:0;
		z-index:1;
		width:60px; height:60px;
		background:#FFF !important;
		border:none;

		svg {
			@extend .animate;
			display:block;
			width:18px; height:18px;
			fill:$color_theme_01-01;
		}
	}

	.panel-container {
		@extend .animate;
		position:absolute;
		top:0; right:0; bottom:0;
		z-index:1;
		padding:48px;
		width:100%; height:100%;
		background:#FFF;
		box-sizing:border-box;
		overflow-y:auto;
		transform:translateX(12px);

		@media screen and (min-width:769px)
		{
			max-width:$site_width_xsmall;
		}
		@media screen and (max-width:768px)
		{
			padding:36px 48px;
			width:calc(100% - 60px);
			color:#FFF;
			background:$color_theme_01-02;
		}

		&.slim {
			bottom:auto;
			padding:0;
			height:60px;
		}
	}
		.panel-container_info {
			margin-top:36px; padding-top:36px;
			border-top:1px solid rgba(#FFF, .12);
		}
