/* --------------------------------------------------------------------------------------------------- */
/* SPEKTRIX ------------------------------------------------------------------------------------------ */
/* --------------------------------------------------------------------------------------------------- */
.spektrix-iframe {
	display:block;
	width:100%; min-height:770px;
	border:none;

	/*
	@media screen and (max-width:768px)
	{
		margin:-24px -24px 0;
		width:calc(100% + 48px);
	}
	*/
}

.pb-block_spektrix {

	&:not(:first-child) {
		margin-top:96px;
		border-top:4px solid $color_theme_01-05;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			margin-top:48px;
		}
		@media screen and (max-width:768px)
		{
			margin-top:24px;
		}
	}

	h6 {
		color:$color_theme_03-01;
	}
	iframe {
		margin-top:24px;
	}

	&.pb-block_spektrix_my-account iframe {
		height:460px;
	}
	&.pb-block_spektrix_cart iframe {
		height:410px;
	}
	&.pb-block_spektrix_error iframe {
		height:135px;
	}
}
