/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.header {
	@extend .flex_column;
	align-items:center;
	position:fixed;
	top:0; left:0; right:0;
	z-index:99;
	padding:32px;
	background:#FFF;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding:24px;
	}
	@media screen and (max-width:768px)
	{
		padding:6px;
	}

	&:after {
		@extend .animate;
		@extend .absolute;
		content:"";
		left:0; right:0; bottom:-6px;
		height:6px;
		background:linear-gradient(to bottom, rgba(#000, .06) 0%,rgba(#000, 0) 100%);
		opacity:0;
	}

	&.is-sticky:after {
		opacity:1;
	}
}
	.header-content {
		width:100%;

		@media screen and (max-width:940px) and (min-width:769px)
		{
			& > .grid {
				flex-wrap:nowrap;
				margin-left:-24px; margin-right:-24px;

				& > .column {
					padding-left:24px; padding-right:24px;
				}
			}
		}
	}
		.header-content_lhc,
		.header-content_rhc {
			flex-direction:row !important;

			@media screen and (max-width:1110px) and (min-width:769px)
			{
				flex-shrink:0;
				width:auto !important;
			}
		}
		.header-content_lhc {
		}
		.header-content_rhc {

			@media screen and (max-width:1110px) and (min-width:769px)
			{
				flex:1 1 auto;
				justify-content:flex-end;
			}
		}
			.header-block {
				@extend .flex_column;
				justify-content:center;
				padding-left:16px;
				width:100%; max-width:calc(55% - 16px);
				line-height:1.3;

				@media screen and (max-width:1220px)
				{
					display:none;
				}
			}
				.header-block_top,
				.header-block_bottom {
					@extend .flex_row;
				}
				.header-block_top .box-office_label {
					display:block;
				}
				.header-block_bottom {
					padding-top:9px;
				}
					.header-top_block-link,
					.header-block_bottom-link {
						@extend .animate;
						@extend .flex_row;
						align-items:center;
						font-size:$font_size_small;

						svg {
							@extend .animate;
							flex-shrink:0;
							width:18px; height:18px;
							fill:$color_theme_01-01;
						}
						span {
							padding-left:12px;
						}

						@media screen and (min-width:769px)
						{
							&:not(.is-current):hover,
							&:not(.is-current):focus {
								color:$color_theme_02-01;

								svg {
									fill:$color_theme_02-01;
								}
							}
						}

						&.is-current {

							svg {
								fill:$color_theme_03-01;
							}
						}
					}
					// Temp Rule START
					.header-top_block-link {

						&.js-search_trigger {
							display:none;
						}
					}
					// Temp Rule END
					.header-block_bottom-link {
						color:$color_theme_01-01;

						&:first-child {
							padding-right:16px;
						}
						& + .header-block_bottom-link:not(:last-child) {
							padding-left:16px;
						}
						&:not(:last-child) {
							width:100%;
						}

						// Temp Rule START
						&.js-search_trigger {
							opacity:0;
							visibility:hidden;
						}
						// Temp Rule END
					}
						spektrix-basket-item-count {
							@extend .flex_column;

							@media screen and (max-width:768px)
							{
								justify-content:center;
								align-items:center;
								position:absolute;
								top:6px; right:6px;
								z-index:2;
								width:24px; height:24px;
								color:#FFF;
								font-size:12px;
								font-weight:500;
								background:$color_theme_02-01;
								border-radius:100%;
							}

							span {
								@extend .flex_row;
								margin:0 !important; padding:0 !important;

								@media screen and (min-width:769px)
								{
									padding:0 0 0 3px !important;

									&:before {
										content:"(";
									}
									&:after {
										content:")";
									}
								}
							}
						}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER (TOP) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.header-top {
	display:none;
	margin:-24px -24px 24px; padding:16px 24px;
	line-height:1.2;
	width:100%;
	color:rgba(#FFF, .48);
	background:$color_theme_01-02;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		display:flex;
		flex-direction:row;
	}

	.header-content {
		@extend .flex_row;
	}

		.box-office {
			padding-right:12px;
			width:auto; max-width:none;
		}
			.box-office_label {
				font-size:12px;
				font-weight:400;
				text-transform:uppercase;
			}
			.box-office_link {
				color:#FFF;
				font-size:18px;
				font-weight:700;
			}

		.opening-hours {
			padding:4px 0 0;
			width:auto;
			color:rgba(#FFF, .48);
			font-size:$font_size_small;
		}

		.header-top_block {
			@extend .flex_row;
			justify-content:flex-end;
			flex:1 1 auto;
		}
			.header-top_block-link {
				color:rgba(#FFF, .48);

				svg {
					fill:#FFF;
				}
				span {
					padding-left:8px;
				}

				& + .header-top_block-link {
					padding-left:24px;
				}
			}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX OFFICE ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box-office {

	@media screen and (min-width:769px)
	{
		padding-right:16px;
		width:100%; max-width:calc(50% - 25px);
	}
	@media screen and (max-width:768px)
	{
		font-size:18px;
	}
}
	.box-office_link {
		@extend .animate;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:$color_theme_02-01;
			}
		}
		@media screen and (max-width:768px)
		{
			color:#FFF;
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* OPENING HOURS ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.opening-hours {

	@media screen and (min-width:769px)
	{
		padding-left:16px;
		color:$color_theme_01-03;
		width:100%; max-width:calc(50% - 25px);
	}
	@media screen and (max-width:768px)
	{
		padding-top:9px;
		color:rgba(#FFF, .64);
	}
}
