/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wrapper {
	@extend .flex_column;
	flex-shrink:0;
	margin-top:160px;
	min-height:calc(100vh - 160px);

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		margin-top:198px;
		min-height:calc(100vh - 198px);
	}
	@media screen and (max-width:768px)
	{
		margin-top:60px;
		min-height:calc(100vh - 60px);
	}

	&.page-has-sidebar {

		@media screen and (max-width:768px)
		{
			margin-bottom:54px;
			min-height:calc(100vh - 114px);
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-container {
	@extend .flex_column;
	flex:1 1 auto;
}
	.col-lhc {

		@media screen and (max-width:768px)
		{
			order:2;
			padding-top:24px;
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE TAG MANAGER ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.gtm-noscript {
	display:none;
	width:0; height:0;
	opacity:0;
	visibility:hidden;
}
