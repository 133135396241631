/* ------------------------------------------------------------------------------------------------------------------------ */
/* FILTERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.product-filters {

	.field-label,
	.fieldset-legend {

		@media screen and (max-width:768px)
		{
			color:#FFF;
		}
	}
	.fieldset-legend.js-accordion_trigger {

		@media screen and (max-width:768px)
		{
			border-bottom:1px solid rgba(#FFF, .12);

			svg {
				fill:#FFF;
			}
		}
	}

	.input-text input,
	.input-datepicker input,
	.input-datepicker .datepicker-clear,
	.field-select,
	.input-radio input + span,
	.input-checkbox input + span {

		@media screen and (max-width:768px)
		{
			color:#FFF;
			background:rgba(#FFF, .12);
			border:none;
		}
	}
	.input-text svg {
		position:absolute;
		top:50%; right:24px;
		z-index:4;
		width:18px; height:18px;
		fill:$color_theme_01-01;
		transform:translateY(-50%);

		@media screen and (max-width:768px)
		{
			fill:#FFF;
		}
	}
	.input-datepicker .datepicker-clear svg {

		@media screen and (max-width:768px)
		{
			fill:#FFF;
		}
	}
	.field-select {

		@media screen and (max-width:768px)
		{
			select {
				color:#FFF;
				background:none;
				border:none;
			}
			.chevron svg {
				fill:#FFF;
			}
		}
	}
	.input-radio input + span:after {

		@media screen and (max-width:768px)
		{
			background:#FFF;
		}
	}
	.input-checkbox input + span svg {

		@media screen and (max-width:768px)
		{
			fill:#FFF;
		}
	}
}
	.product-filters_container {
		@extend .flex_column;

		@media screen and (max-width:768px)
		{
			padding:24px;
		}
	}
		.product-filters_btn-wrapper {
			@extend .flex_row;
			justify-content:space-between;
			align-items:center;
			margin-top:auto; padding-top:48px;

			@media screen and (max-width:768px)
			{
				padding-top:24px;
			}
		}
			.product-filters_btn {
				flex:1 1 auto;
				// width:calc(50% - 3px);

				&:first-child {
					margin-right:6px;
				}
			}
			.product-filters_btn.btn-02 {

				span {
					flex:1 1 auto;
				}
				svg {
					width:14px; height:14px;
				}

				@media screen and (max-width:768px)
				{
					color:#FFF !important;
					border-color:rgba(#FFF, .12) !important;

					svg {
						fill:#FFF !important;
					}
				}
			}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FILTERS (TRIGGER) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.product-filters_trigger-wrapper {
	@extend .flex_row;
	justify-content:space-between;
	align-items:center;
}
	.product-filters_trigger-container {
		@extend .flex_row;

		@media screen and (min-width:769px)
		{
			padding-left:48px;
		}
		@media screen and (max-width:920px) and (min-width:769px)
		{
			flex-direction:column;
		}
		@media screen and (max-width:768px)
		{
			position:fixed;
			left:0; right:0; bottom:0;
			z-index:99;
			background:#FFF;
		}

		&:after {
			@extend .absolute;

			@media screen and (max-width:768px)
			{
				content:"";
				left:0; right:0; top:-6px;
				height:6px;
				background:linear-gradient(to bottom, rgba(#000, .0) 0%,rgba(#000, .06) 100%);
			}
		}

		.btn {
			flex-shrink:0;

			@media screen and (max-width:768px)
			{
				justify-content:space-between;
				flex:1 1 auto;
				order:2;
				padding:16px 24px;
				width:100%;
				color:$color_theme_01-01 !important;
				font-weight:500;
				background:none !important;
				border:none !important; border-left:1px solid $color_theme_01-05 !important;
			}

			svg {

				@media screen and (max-width:768px)
				{
					fill:$color_theme_01-01 !important;
				}
			}

			& + .btn {

				@media screen and (min-width:921px)
				{
					margin-left:6px;
				}
				@media screen and (max-width:920px) and (min-width:769px)
				{
					margin-top:6px;
				}
				@media screen and (max-width:768px)
				{
					order:1;
					border-left:none !important;
				}

				svg {

					@media screen and (max-width:768px)
					{
						width:18px !important; height:18px !important;
					}
				}
			}

			&:disabled {
				display:none;
			}
		}
	}

/*
.product-filters {
	flex:none;

	& > .box-content {
		@extend .animate;

		@media screen and (max-width:768px)
		{
			position:fixed;
			top:0; left:0; right:0; bottom:0;
			z-index:1001;
			background:rgba(#FFF, .48);
			opacity:0;
			visibility:hidden;

			&.is-active {
				opacity:1;
				visibility:visible;

				& > .grid {
					transform:translateX(0);
				}
			}
		}

		& > .grid {
			@extend .animate;

			@media screen and (max-width:768px)
			{
				display:block;
				position:absolute;
				top:0; right:0; bottom:0;
				z-index:1;
				padding:24px;
				width:calc(100% - 60px); height:100%;
				color:#FFF;
				background:$color_theme_01-02;
				box-sizing:border-box;
				overflow-y:auto;
				transform:translateX(12px);
			}
		}
	}

	.field {
		justify-content:flex-end;

		@media screen and (min-width:769px)
		{
			padding-top:0;
		}
	}

	.field-select {

		@media screen and (max-width:768px)
		{
			background:rgba(#FFF, .12);
			border:none;

			select {
				color:#FFF;
			}
			.chevron svg {
				fill:#FFF;
			}
		}
	}

	.input-checkbox {

		@media screen and (min-width:769px)
		{
			padding:16px 0;
		}
		@media screen and (max-width:768px)
		{
			input + span {
				background:rgba(#FFF, .12);
				border:none;

				svg {
					fill:#FFF;
				}
			}
		}
	}

	&.nav-side {

		.nav-side_list {

			@media screen and (max-width:768px)
			{
				padding:24px;
			}
		}

		h2 + .field {
			padding-top:24px;
		}
		.field {
			padding-top:6px;
		}

		.input-checkbox {
			padding:0;
		}
	}
}
*/
