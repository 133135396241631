/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (RESET) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
button,
input,
optgroup,
select,
textarea {
	margin:0;
	line-height:$line_height_base;
	font-family:$font_family_01;
	font-size:100%;
	border:0 solid;
	border-radius:0;
	appearance:none;
}

[type=tel],
[type=text],
[type=email],
[type=password] {
	display:block;
	width:100%;
	background:transparent;
}

button,
input {
	overflow:visible;
}

button,
select {
	text-transform:none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	appearance:none;
	background:none;
	cursor:pointer;
}

[type=submit] {
	cursor:pointer;
	overflow:visible;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style:none;
	padding:0;
}

fieldset {
	margin:0; padding:0;
	border:none;
}

progress {
	display:inline-block;
	vertical-align:baseline;
}

textarea {
	display:block;
	width:100%;
	background:transparent;
	resize:none;
	overflow:auto;
	box-sizing:border-box;
}

[type="checkbox"],
[type="radio"] {
	padding:0;
	box-sizing:border-box;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset:-2px;
	appearance:textfield;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	appearance:none;
}

::-webkit-file-upload-button {
	font:inherit;
	-webkit-appearance:button;
}

[hidden] {
	display:none;
}

select::-ms-expand {
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity:0;
}
