/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn {
	transition:color 300ms ease-in-out, background 300ms ease-in-out, border 300ms ease-in-out;
	display:inline-block;
	text-align:left;
	box-sizing:border-box;

	&.btn_has-icon {
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		align-items:center;

		span {
			padding-left:12px;

			@media screen and (max-width:768px)
			{
				padding-left:9px;
			}

			&:first-child {
				padding-left:0; padding-right:12px;

				@media screen and (max-width:768px)
				{
					padding-right:9px;
				}
			}
		}
		svg {
			display:block;
			flex-shrink:0;
			width:18px; height:18px;

			@media screen and (max-width:768px)
			{
				width:12px; height:12px;
			}
		}

		&.icon-close svg {

			@media screen and (min-width:769px)
			{
				width:14px; height:14px;
			}
		}
	}

	&.icon-minus-90-deg svg {
		transform:rotate(-90deg);
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 1) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-01 {
	padding:16px 24px;
	font-weight:500;

	@media screen and (max-width:768px)
	{
		padding:12px 18px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 2) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-02 {
	padding:15px 24px;
	font-weight:500;
	border:1px solid rgba($color_theme_01-01, .12);

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			border-color:$color_theme_01-01;
			cursor:pointer;
		}
	}
	@media screen and (max-width:768px)
	{
		padding:11px 18px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (PREV / NEXT) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-prev,
.btn-next {
	@extend .absolute;
	width:48px; height:48px;

	@media screen and (min-width:769px)
	{
		top:50%;
		transform:translateY(-50%);
	}
	@media screen and (max-width:1220px) and (min-width:769px)
	{
		width:32px; height:32px;
	}
	@media screen and (max-width:768px)
	{
		bottom:36px;
		width:24px; height:24px;
	}

	&.fill_white svg {
		opacity:.24;
	}

	svg {
		@extend .animate;
		display:block;
		width:48px; height:48px;
		fill:$color_theme_01-01;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			width:32px; height:32px;
		}
		@media screen and (max-width:768px)
		{
			width:24px; height:24px;
		}
	}

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:$color_theme_02-01;
			opacity:1;
		}
	}
}
.btn-prev {
	left:-96px;

	svg {
		transform:rotate(180deg);
	}

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		left:-48px;
	}
	@media screen and (max-width:768px)
	{
		left:-24px;
	}
}
.btn-next {
	right:-96px;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		right:-48px;
	}
	@media screen and (max-width:768px)
	{
		right:-24px;
	}
}
