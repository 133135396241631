/* ------------------------------------------------------------------------------------------------------------------------ */
/* ACCORDION -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-accordion {

	.js-accordion_block {

		.js-accordion_container {
			display:none;
		}

		&.open-disabled .js-accordion_container {
			display:block;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ACCORDION TRIGGER ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-accordion_trigger {
}
