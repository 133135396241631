/* ------------------------------------------------------------------------------------------------------------------------ */
/* SEARCH (MODAL) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.modal-search-desktop_wrapper {

	@media screen and (max-width:768px)
	{
		display:none !important;
	}
}
	.modal-search-desktop_content {
		@extend .flex_column;
		align-items:center;
		top:192px !important;
		max-width:calc(#{$site_width_base} + 96px);
		box-sizing:border-box;
		transform:translate(-50%, 0%) !important;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			top:96px !important;
		}
	}
		.form_modal-search {
			@extend .relative;
			width:100%;
			color:#FFF;

			.input-text {
				padding-right:112px;
				border-bottom:2px solid #FFF;

				@media screen and (max-width:1220px) and (min-width:769px)
				{
					padding-right:96px;
				}

				label,
				input {
					padding:0 0 24px;
					color:#FFF;
					font-size:96px;
					font-weight:300;
					background:none;
					border:none;

					@media screen and (max-width:1220px) and (min-width:769px)
					{
						font-size:48px;
					}
				}
				label {
					right:112px;

					@media screen and (max-width:1220px) and (min-width:769px)
					{
						right:96px;
					}
				}
				input {

					&:not([value=""]) + .label_in-field,
					&:focus + .label_in-field {
						padding:0 0 24px;
						font-size:$font_size_base;
						opacity:.48;
					}

					@media screen and (min-width:769px)
					{
						&:hover,
						&:focus {
							border:none;
						}
					}
				}
			}

			.input-submit {
				position:absolute;
				top:50%; right:24px;
				z-index:2;
				margin-top:-12px; padding:0;
				width:64px; height:64px;
				transform:translateY(-50%);

				@media screen and (max-width:1220px) and (min-width:769px)
				{
					width:48px; height:48px;
				}

				svg {
					@extend .animate;
					display:block;
					width:64px; height:64px;
					fill:#FFF;

					@media screen and (max-width:1220px) and (min-width:769px)
					{
						width:48px; height:48px;
					}
				}

				@media screen and (min-width:769px)
				{
					&:hover svg,
					&:focus svg {
						fill:$color_theme_02-01;
					}
				}
			}
		}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SEARCH (PANEL) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form_panel-search {
	@extend .flex_row;
	align-items:center;

	.input-text {
		width:100%;

		label {
			color:#FFF;
		}
		input {
			color:#FFF;
			background:none;
			border:none;
		}
	}

	.input-submit {
		@extend .flex_column;
		justify-content:center;
		align-items:center;
		flex-shrink:0;
		padding-top:0;
		width:60px; height:60px;

		svg {
			display:block;
			width:18px; height:18px;
			fill:#FFF;
		}
	}
}
