/* --------------------------------------------------------------------------------------------------- */
/* SLIDER -------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.js-slick:not(.hero-content) {
	display:flex !important;
	flex-direction:column;
	flex-wrap:nowrap;

	.slick-list,
	.slick-list .slick-track,
	.slick-list .slick-track .slick-slide,
	.slick-list .slick-track .slick-slide > div {
		display:flex !important;
		flex-direction:row;
		flex-wrap:nowrap;
		flex:1 1 auto;
	}

	&.has-adaptiveHeight {

		.slick-list,
		.slick-list .slick-track,
		.slick-list .slick-track .slick-slide,
		.slick-list .slick-track .slick-slide > div {
			align-items:flex-start;
		}
	}
}
