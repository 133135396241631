/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER NAVIGATIONS ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-header {
	@extend .flex_column;
	justify-content:center;
}
	.nav-header_list {
		list-style:none;
	}
		.nav-header_list-item {
			@extend .relative;

			&.submenu-is-open {
				z-index:10;

				.nav-header_list-item_link-chevron {

					@media screen and (min-width:769px)
					{
						&:before {
							opacity:1;
						}
						svg {
							fill:$color_theme_02-01;
							transform:rotate(270deg);
						}
					}
				}
				.nav-header_submenu {
					opacity:1;
					visibility:visible;
				}
			}
		}
			.nav-header_list-item_link {
				@extend .animate;
				@extend .flex_row;
				align-items:center;
				color:$color_theme_01-01;

				@media screen and (max-width:768px)
				{
					color:#FFF;
				}
				@media screen and (min-width:769px)
				{
					&:not(.is-current):hover,
					&:not(.is-current):focus {
						color:$color_theme_02-01;
					}
					/*
					&:focus {

						svg {
							transform:rotate(270deg);
						}

						& + .nav-header_submenu {
							opacity:1;
							visibility:visible;
						}
					}
					*/
				}

				&.is-current {
					color:$color_theme_02-01;
					cursor:default;
				}

				&.is-submenu-close {

					@media screen and (max-width:768px)
					{
						svg {
							transform:rotate(90deg);
						}
					}
				}
			}
				.nav-header_list-item_link-label {
					position:relative;
					z-index:2;
					line-height:1.2;
				}
				.nav-header_list-item_link-chevron {
					@extend .relative;
					display:block;
					margin-top:2px;
					cursor:pointer;

					&:before {
						@extend .animate;
						@extend .absolute;
						content:"";
						top:-50%; left:-50%;
						background:$color_theme_01-05;
						border-radius:100%;
						opacity:0;
					}

					svg {
						@extend .animate;
						display:block;
						position:relative;
						z-index:2;
						fill:$color_theme_01-01;
						transform:rotate(90deg);

						@media screen and (max-width:768px)
						{
							fill:#FFF;
							transform:rotate(270deg);
						}
					}
				}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER NAVIGATIONS (SUBMENU) ------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-header_submenu {
	list-style:none;

	@media screen and (min-width:769px)
	{
		position:absolute;
		left:auto;
		z-index:2;
		margin-left:-24px; padding:20px 24px;
		min-width:200px; max-width:300px;
		background:$color_theme_01-02;
		opacity:0;
		visibility:hidden;
		transition-duration:300ms;
		transition-timing-function:ease-in-out;

		&:after {
			content:"";
			position:absolute;
			top:-24px; left:0; right:0;
			height:24px;
			background:transparent;
		}
	}
	@media screen and (max-width:768px)
	{
		// padding-top:9px;
		padding-top:0;

		& > li:first-child {
			padding-top:9px;
		}
	}

	&:before {
		@extend .absolute;

		@media screen and (min-width:769px)
		{
			content:"";
			top:-6px; left:18px;
			width:0; height:0;
			border-left:6px solid transparent; border-right:6px solid transparent; border-bottom:6px solid $color_theme_01-02;
		}
	}
}
	.nav-header_submenu-item {

		&:not(:first-child) {
			padding-top:12px;

			@media screen and (max-width:768px)
			{
				padding-top:6px;
			}
		}
	}
		.nav-header_submenu-item_link {
			@extend .animate;
			display:block;
			line-height:1.3;
			color:#FFF;

			@media screen and (min-width:769px)
			{
				&:not(.is-current):hover,
				&:not(.is-current):focus {
					color:$color_theme_02-01;
				}
			}
			@media screen and (max-width:768px)
			{
				color:rgba(#FFF, .48);
			}

			&.is-current {
				color:$color_theme_02-01;
				cursor:default;
			}
		}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (PRIMARY) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-primary {

	@media screen and (min-width:941px)
	{
		padding-left:64px;
	}
	@media screen and (max-width:940px) and (min-width:769px)
	{
		padding-left:48px;
	}
	@media screen and (max-width:768px)
	{
		flex-direction:row;
		justify-content:flex-start;
	}
}
	.nav-primary_list {
		@extend .flex_row;

		@media screen and (max-width:1110px)
		{
			flex-direction:column;
			width:100%;
		}
	}
		.nav-primary_list-item {

			&:not(:first-child) {

				@media screen and (min-width:1111px)
				{
					padding-left:32px;
				}
				@media screen and (max-width:768px)
				{
					padding-top:18px;
				}
			}
			&:last-child {

				@media screen and (max-width:768px)
				{
					margin-bottom:-9px;
				}
			}
		}
			.nav-primary_list-item_link {
				font-size:24px;
				font-weight:700;

				@media screen and (max-width:1110px)
				{
					justify-content:space-between;
				}
				@media screen and (max-width:940px) and (min-width:769px)
				{
					font-size:$font_size_base;
				}
			}
				.nav-primary_list-item_link-label {
					padding-right:16px;
				}
				.nav-primary_list-item_link-chevron {
					width:12px; height:12px;

					&:before {
						margin-top:-20px; margin-left:-20px;
						width:64px; height:64px;
					}
					svg {
						width:12px; height:12px;
					}
				}

.nav-primary_submenu {

	@media screen and (min-width:769px)
	{
		top:50px;
	}
	@media screen and (max-width:768px)
	{
		// padding-bottom:9px;
		padding-bottom:0;

		& > li:last-child {
			padding-bottom:9px;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (SECONDARY) ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-secondary {
	width:100%;

	@media screen and (min-width:1221px)
	{
		padding-right:16px;
		max-width:calc(45% - 16px);
	}
	@media screen and (max-width:1110px) and (min-width:769px)
	{
		width:auto;
	}
	@media screen and (max-width:768px)
	{
		padding-top:36px;
	}
}
	.nav-secondary_list {
		@extend .flex_column;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			flex-direction:row;
		}
		@media screen and (max-width:940px) and (min-width:769px)
		{
			flex-wrap:wrap;
		}
	}
		.nav-secondary_list-item {

			&:first-child {

				@media screen and (max-width:940px) and (min-width:769px)
				{
					width:100%;
				}
			}
			&:not(:first-child) {

				@media screen and (max-width:768px)
				{
					padding-top:12px;
				}
			}
			&:not(:last-child) {
				padding-right:24px;
			}
		}
			.nav-secondary_list-item_link {
			}
				.nav-secondary_list-item_link-label {
					padding-right:8px;
				}
				.nav-secondary_list-item_link-chevron {
					width:8px; height:8px;

					&:before {
						margin-top:-8px; margin-left:-8px;
						width:32px; height:32px;
					}
					svg {
						width:8px; height:8px;
					}
				}

.nav-secondary_submenu {

	@media screen and (min-width:769px)
	{
		top:36px;
	}
	@media screen and (max-width:768px)
	{
		// padding-bottom:12px;

		& > li:last-child {
			padding-bottom:12px;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (MOBILE) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-header-mobile {
	display:none;
	flex-direction:column;
	flex-wrap:nowrap;
	flex:1 1 auto;
	margin:-6px -6px -6px 6px;

	@media screen and (max-width:768px)
	{
		display:flex;
	}
}
	.nav-header-mobile_list {
		@extend .flex_row;
		flex:1 1 auto;
		list-style:none;
	}
		.nav-header-mobile_list-item {
			@extend .flex_column;
			flex:1 1 auto;
			border-left:1px solid $color_theme_01-05;
		}
			.nav-header-mobile_list-item_link {
				@extend .flex_column;
				@extend .relative;
				justify-content:center;
				align-items:center;
				flex:1 1 auto;

				svg {
					display:block;
					width:18px; height:18px;
					fill:$color_theme_01-01;
				}

				&.is-current svg {
					fill:$color_theme_03-01;
				}
			}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (FOOTER) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-footer_list {
	margin-top:-48px;
	list-style:none;

	@media screen and (max-width:768px)
	{
		margin-top:-18px;
	}
}
	.nav-footer_list-item {
		padding-top:48px;

		@media screen and (max-width:768px)
		{
			padding-top:18px;
		}
	}
		.nav-footer_list-item_heading {
			color:#FFF;
			font-size:24px;
			font-weight:700;

			@media screen and (max-width:768px)
			{
				font-size:18px;
			}
		}

		.nav-footer_sublist {
			padding-top:12px;
			list-style:none;
		}
			.nav-footer_sublist-item_link {
				@extend .animate;
				color:rgba(#FFF, .48);

				@media screen and (min-width:769px)
				{
					&:not(.is-current):hover,
					&:not(.is-current):focus {
						color:#FFF;
					}
				}

				&.is-current {
					color:#FFF;
					cursor:default;
				}
			}

		.nav-footer_sublist-socials {
			display:flex;
			flex-direction:row;
			flex-wrap:wrap;
			padding-top:6px;
			list-style:none;
		}
			.nav-footer_sublist-socials_item {
				padding-top:6px;

				&:not(:last-child) {
					padding-right:6px;
				}
			}
				.nav-footer_sublist-socials_item-link {
					@extend .animate;
					@extend .flex_column;
					justify-content:center;
					align-items:center;
					width:32px; height:32px;
					background:rgba(#FFF, .24);
					border-radius:100%;

					svg {
						display:block;
						width:16px; height:16px;
						fill:$color_theme_01-02;
					}

					@media screen and (min-width:769px)
					{
						&:hover,
						&:focus {
							background:#FFF;
						}
					}
				}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (SIDE) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-side {
	@extend .animate;

	@media screen and (max-width:768px)
	{
		position:fixed;
		top:0; left:0; right:0; bottom:0;
		z-index:1001;
		background:rgba(#FFF, .48);
		opacity:0;
		visibility:hidden;

		&.is-active {
			opacity:1;
			visibility:visible;

			.nav-side_list {
				transform:translateX(0);
			}
		}
	}
}
	.nav-side_list {
		@extend .animate;
		list-style:none;

		@media screen and (max-width:768px)
		{
			position:absolute;
			top:0; right:0; bottom:0;
			z-index:1;
			padding:36px 48px;
			width:calc(100% - 60px); height:100%;
			color:#FFF;
			background:$color_theme_01-02;
			box-sizing:border-box;
			overflow-y:auto;
			transform:translateX(12px);
		}
	}
		.nav-side_list-item {

			&:not(:first-child) {
				padding-top:3px;

				@media screen and (max-width:768px)
				{
					padding-top:12px;
				}
			}

			@media screen and (min-width:769px)
			{
				&:not(.current-menu-item) .nav-side_list-item_link:hover,
				&:not(.current-menu-item) .nav-side_list-item_link:focus {
					color:$color_theme_03-01;
				}
			}

			&.current-menu-item .nav-side_list-item_link {
				color:$color_theme_03-01;
				cursor:default;
			}
		}
			.nav-side_list-item_link {
				@extend .animate;
				color:$color_theme_01-01;
				font-weight:500;

				@media screen and (max-width:768px)
				{
					color:#FFF;
					font-size:18px;
				}
			}

.nav-side_trigger {
	@extend .flex_row;
	justify-content:space-between;
	align-items:center;
	position:fixed;
	left:0; right:0; bottom:0;
	z-index:99;
	padding:16px 24px;
	width:100%;
	color:$color_theme_01-01;
	font-weight:500;
	background:#FFF !important;

	&:after {
		@extend .absolute;
		content:"";
		left:0; right:0; top:-6px;
		height:6px;
		background:linear-gradient(to bottom, rgba(#000, .0) 0%,rgba(#000, .06) 100%);
	}

	svg {
		@extend .animate;
		width:12px; height:12px;
		fill:$color_theme_01-01;
	}
}

.nav-side.is-affixed:not(.nav-side_border) .inner-wrapper-sticky {
	z-index:10;

	&:before {
		@extend .absolute;
		content:"";
		top:-24px; left:-24px; right:-24px; bottom:-24px;
		background:#FFF;
		border-top:1px solid $color_theme_01-05; border-bottom:1px solid $color_theme_01-05;
	}

	.nav-side_list {
		position:relative;
		z-index:2;
	}
}

.nav-side_border {

	.inner-wrapper-sticky .nav-side_list {

		@media screen and (min-width:769px)
		{
			margin-top:12px; padding:24px;
			background:#FFF;
			border:2px solid $color_theme_01-05;
		}
	}

	&.is-affixed .inner-wrapper-sticky {
	   z-index:10;

	   .nav-side_list {
	   		position:relative;
	   		z-index:2;
	   	}
   }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (SOCIALS) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-socials {

	@media screen and (max-width:768px)
	{
		padding-top:12px;
	}
}
	.nav-socials_list {
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		list-style:none;
	}
		.nav-socials_list-item {

			&:not(:last-child) {
				padding-right:12px;
			}
		}
			.nav-socials_list-item_link {
				@extend .animate;
				@extend .flex_column;
				justify-content:center;
				align-items:center;
				width:48px; height:48px;
				border-radius:100%;

				svg {
					display:block;
					width:18px; height:18px;
					fill:#FFF;
				}
			}
