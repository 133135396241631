/* ------------------------------------------------------------------------------------------------------------------------ */
/* DISPLAY FLEX ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.flex_row,
.flex_column {
	display:flex;
	flex-wrap:nowrap;
}
.flex_row {
	flex-direction:row;
}
.flex_column {
	flex-direction:column;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wLarge {
	max-width:$site_width_large;
}
.wBase {
	max-width:$site_width_base;
}
.wMedium {
	max-width:$site_width_medium;
}
.wSmall {
	max-width:$site_width_small;
}
.wXSmall {
	max-width:$site_width_xsmall;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BLEEDS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.bleed_24 {
	padding:24px;
}
.bleed_48 {
	padding:48px;
}
.bleed_96 {
	padding:96px;
}

.bleed_top_48 {
	padding-top:48px;
}

@media screen and (max-width:1220px) and (min-width:769px)
{
	.bleed_96 {
		padding:48px;
	}
}
@media screen and (max-width:768px)
{
	.bleed_48,
	.bleed_96 {
		padding:24px;
	}

	.bleed_top_48 {
		padding-top:24px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MARGINS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_margin {
	margin:0 !important;
}
.reset_margin_top {
	margin-top:0 !important;
}
.reset_margin_right {
	margin-right:0 !important;
}
.reset_margin_bottom {
	margin-bottom:0 !important;
}
.reset_margin_left {
	margin-left:0 !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PADDINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_padding {
	padding:0 !important;
}
.reset_padding_top {
	padding-top:0 !important;
}
.reset_padding_right {
	padding-right:0 !important;
}
.reset_padding_bottom {
	padding-bottom:0 !important;
}
.reset_padding_left {
	padding-left:0 !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_border {
	border:0 solid !important;
}
.reset_border_top {
	border-top:0 solid !important;
}
.reset_border_right {
	border-right:0 solid !important;
}
.reset_border_bottom {
	border-bottom:0 solid !important;
}
.reset_border_left {
	border-left:0 solid !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* POSITIONS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.relative {
	position:relative;
	z-index:1;
}
.absolute {
	position:absolute;
	z-index:1;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOURS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Black(ish) --- */
.color_01-01 {
	color:$color_theme_01-01 !important;
}
a.color_01-01,
button.color_01-01 {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			color:$color_theme_01-01 !important;
			cursor:pointer;
		}
	}
}

/* --- Grey --- */
.color_01-03 {
	color:$color_theme_01-03 !important;
}
a.color_01-03,
button.color_01-03 {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			color:$color_theme_01-02 !important;
			cursor:pointer;
		}
	}
}

/* --- Orange(ish) --- */
.color_02-01 {
	color:$color_theme_02-01 !important;
}
a.color_02-01,
button.color_02-01 {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			color:$color_theme_02-01 !important;
			cursor:pointer;
		}
	}
}

/* --- Green --- */
.color_03-01 {
	color:$color_theme_03-01 !important;
}
a.color_03-01,
button.color_03-01 {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			color:$color_theme_03-01 !important;
			cursor:pointer;
		}
	}
}

/* --- White --- */
.color_white {
	color:#FFF !important;
}
a.color_white,
button.color_white {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			color:#FFF !important;
			cursor:pointer;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BACKGROUNDS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Black(ish) --- */
.bg_01-01 {
	background:$color_theme_01-01 !important;
}
a.bg_01-01,
button.bg_01-01, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_theme_01-01, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Grey --- */
.bg_01-03 {
	background:$color_theme_01-03 !important;
}
a.bg_01-03,
button.bg_01-03, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_theme_01-03, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Darkest Gray --- */
.bg_01-02 {
	background:$color_theme_01-02 !important;
}
a.bg_01-02,
button.bg_01-02, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_theme_01-02, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Lightest Gray --- */
.bg_01-05 {
	background:$color_theme_01-05 !important;
}
a.bg_01-05,
button.bg_01-05, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_theme_01-05, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Orange(ish) --- */
.bg_02-01 {
	background:$color_theme_02-01 !important;
}
a.bg_02-01,
button.bg_02-01, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:$color_theme_02-02 !important;
			cursor:pointer;
		}
	}
}

/* --- Green --- */
.bg_03-01 {
	background:$color_theme_03-01 !important;
}
a.bg_03-01,
button.bg_03-01, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:$color_theme_03-02 !important;
			cursor:pointer;
		}
	}
}

/* --- Green --- */
.bg_03-02 {
	background:$color_theme_03-02 !important;
}
a.bg_03-02,
button.bg_03-02, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_theme_03-02, 3%) !important;
			cursor:pointer;
		}
	}
}

/* --- White --- */
.bg_white {
	background:#FFF !important;
}

/* --- Facebook --- */
.bg_facebook {
	background:$color_facebook !important;
}
a.bg_facebook,
button.bg_facebook, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_facebook, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Twitter --- */
.bg_twitter {
	background:$color_twitter !important;
}
a.bg_twitter,
button.bg_twitter, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_twitter, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- LinkedIn --- */
.bg_linkedin {
	background:$color_linkedin !important;
}
a.bg_linkedin,
button.bg_linkedin, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_linkedin, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Instagram --- */
.bg_instagram {
	background:$color_instagram !important;
}
a.bg_instagram,
button.bg_instagram, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_instagram, 9%) !important;
			cursor:pointer;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Lightest Gray --- */
.border_01-05 {
	border-color:$color_theme_01-05 !important;
}
a.border_01-05,
button.border_01-05, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			border-color:$color_theme_01-01 !important;
			cursor:pointer;
		}
	}
}

/* --- White --- */
.border_white {
	border-color:rgba(#FFF, .12) !important;
}
a.border_white,
button.border_white, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			border-color:#FFF !important;
			cursor:pointer;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FILLS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Black(ish) --- */
.fill_01-01 svg {
	fill:$color_theme_01-01 !important;
}

/* --- Orange(ish) --- */
.fill_02-01 svg {
	fill:$color_theme_02-01 !important;
}

/* --- Green --- */
.fill_03-01 svg {
	fill:$color_theme_03-01 !important;
}

/* --- White --- */
.fill_white svg {
	fill:#FFF !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL SIZE -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_size {
	position:absolute;
	top:0; left:0; right:0; bottom:0;
	z-index:2;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL WIDTH ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_width {
	display:block;
	width:100%; height:auto;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANIMATE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.animate {
	transition-duration:300ms;
	transition-timing-function:ease-in-out;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVENESS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hide-desktop {

	@media screen and (min-width:769px)
	{
		display:none !important;
	}
}

.hide-mobile {

	@media screen and (max-width:768px)
	{
		display:none !important;
	}
}

.visually_hidden {
	position:absolute;
	z-index:1;
	margin:-1px; padding:0;
	height:1px; width:1px;
	border:0;
	overflow:hidden;
	clip:rect(0 0 0 0);
}
