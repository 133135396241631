/* --------------------------------------------------------------------------------------------------- */
/* FAQS ---------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.faqs-wrapper {
}
	.faqs-item {

		&:not(:first-child) {
			padding-top:6px;

			@media screen and (max-width:768px)
			{
				padding-top:2px;
			}
		}

		&.open {

			.faqs-item_question {
				color:$color_theme_01-01 !important;
				background:none !important;

				svg {
					fill:$color_theme_01-01;
					transform:rotate(90deg);
				}

				@media screen and (min-width:769px)
				{
					&:hover,
					&:focus {
						color:$color_theme_01-01 !important;
						background:none !important;
					}
				}
			}
		}
	}
		.faqs-item_question {
			@extend .flex_row;
			align-items:center;
			padding:24px 32px;
			width:100%;
			font-weight:600;
			text-align:left;
			box-sizing:border-box;

			@media screen and (max-width:768px)
			{
				padding:16px 18px;
			}

			span {
				flex:1 1 auto;
				padding-right:24px;
			}
			svg {
				@extend .animate;
				display:block;
				width:18px; height:18px;
				fill:#FFF;

				@media screen and (max-width:768px)
				{
					width:14px; height:14px;
				}
			}
		}
		.faqs-item_answer {
			padding:0 32px 42px;

			@media screen and (max-width:768px)
			{
				padding:0 18px 24px;
			}
		}
