/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form-01 {

	&:not(:first-child) {
		margin-top:96px;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			margin-top:48px;
		}
		@media screen and (max-width:768px)
		{
			margin:24px -24px 0;
		}
	}

	.form-01_intro {
		width:100%;
	}
	.form-01_content {
		margin-top:-24px; padding-top:48px;

		@media screen and (max-width:768px)
		{
			margin-top:-18px; padding-top:24px;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD / FIELDSET ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field,
.fieldset {
	padding-top:24px;

	@media screen and (max-width:768px)
	{
		padding-top:18px;
	}
}
.fieldset {

	&.js-accordion {
		padding-top:48px;

		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}

		.js-accordion_block.open .js-accordion_trigger svg {
			transform:rotate(270deg);
		}
			.js-accordion_content {
				margin-top:-6px; padding-top:24px;
			}
	}
}

	.field-label,
	.fieldset-legend {
		@extend .flex_row;
		@extend .h6;
		padding-bottom:6px;

		em {
			margin-left:auto; padding-left:24px;
			color:$color_theme_01-03;
			font-weight:400;
			text-transform:none;
		}
	}
	.fieldset-legend {

		&.js-accordion_trigger {
			justify-content:space-between;
			align-items:center;
			width:100%;
			border-bottom:1px solid $color_theme_01-05;

			span {
				padding-right:12px;
			}
			svg {
				@extend .animate;
				display:block;
				width:12px; height:12px;
				fill:$color_theme_01-01;
				transform:rotate(90deg);
			}
		}
	}

.fieldset {

	.field {
		padding-top:6px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD FEEDBACK --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field-feedback {
	@extend .animate;
	@extend .relative;
	max-height:0;
	overflow:hidden;
	opacity:0;

	span {
		display:block;
		padding-top:12px;
		line-height:$line_height_small;
		font-size:$font_size_small;
		color:$color_error;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD BADGES ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.badge-valid,
.badge-invalid {
	@extend .animate;
	@extend .flex_column;
	justify-content:center;
	align-items:center;
	position:absolute;
	top:-12px; right:-12px;
	z-index:2;
	width:24px; height:24px;
	border-radius:100%;
	opacity:0;

	svg {
		display:block;
		width:12px; height:12px;
		fill:#FFF;
	}
}
.badge-valid {
	background:$color_valid;
}
.badge-invalid {
	background:$color_error;
}

.input-radio,
.input-checkbox {

	.badge-valid,
	.badge-invalid {
		top:50%; left:20px; right:auto;
		margin-top:-12px;
		width:8px; height:8px;
		transform:translateY(-4px);

		svg {
			display:none;
		}
	}
}

/* --- Valid --- */
.is-valid {

	.field-feedback,
	.badge-invalid {
		opacity:0;
	}
	.badge-valid {
		opacity:1;
	}
}

/* --- Invalid --- */
.is-invalid {

	.badge-valid {
		opacity:0;
	}
	.field-feedback,
	.badge-invalid {
		opacity:1;
	}

	.field-feedback {
		max-height:none;
	}
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT TEXT / TEXTAREA -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-text,
.input-datepicker {
	@extend .animate;
	@extend .relative;

	input,
	textarea {
		@extend .animate;
		margin:0; padding:16px 24px;
		width:100%; height:auto;
		line-height:$line_height_small;
		color:$color_theme_01-03;
		font-family:$font_family_01;
		font-size:100%; font-size:$font_size_base;
		box-sizing:border-box;
		background:#FFF;
		border:1px solid rgba($color_theme_01-01, .12);
		border-radius:0;
		appearance:none;
	}
	input {

		&:not([value=""]) + .label_in-field,
		&:focus + .label_in-field {
			z-index:1;
			padding:6px 24px;
			font-size:8px;
			opacity:.48;
		}

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				position:relative;
				z-index:2;
				border:1px solid rgba($color_theme_01-01, .24);
			}
		}

		&:disabled {
			color:rgba($color_theme_01-03, .24);
			cursor:default;

			& + svg {
				opacity:.24;
			}
		}
	}

	.label_in-field {
		@extend .animate;
		@extend .full_size;
		padding:16px 24px;
		line-height:$line_height_small;
		color:$color_theme_01-01;
		font-size:$font_size_base;
		box-sizing:border-box;
		cursor:pointer;
	}
}

.input-datepicker {
	@extend .flex_row;

	.datepicker-clear {
		@extend .flex_column;
		justify-content:center;
		align-items:center;
		flex-shrink:0;
		width:56px; height:56px;
		background:#FFF;
		border:1px solid rgba($color_theme_01-01, .12); border-left:none;

		svg {
			@extend .animate;
			display:block;
			width:12px; height:12px;
			fill:$color_theme_01-01;
		}
	}

	input:disabled + .datepicker-clear {
		cursor:default;

		svg {
			opacity:.24;
		}
	}

	@media screen and (min-width:769px)
	{
		input:not(:disabled) + .datepicker-clear:hover svg,
		input:not(:disabled) + .datepicker-clear:focus svg {
			fill:$color_theme_02-01;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field-select {
	@extend .relative;

	select {
		@extend .animate;
		@extend .relative;
		margin:0; padding:16px 24px;
		width:100%; height:auto;
		line-height:$line_height_small;
		color:$color_theme_01-03;
		font-size:100%; font-size:$font_size_base;
		text-transform:none;
		box-sizing:border-box;
		background:#FFF;
		border:1px solid rgba($color_theme_01-01, .12);
		border-radius:0;
		cursor:pointer;
		appearance:none;

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				border:1px solid rgba($color_theme_01-01, .24);
			}
			&:not(:disabled):focus + .chevron {
				transform:rotate(270deg);
			}
		}

		&:disabled {
			color:rgba($color_theme_01-03, .24);
			cursor:default;

			& + .chevron {
				opacity:.24;
			}
		}
	}

	.chevron {
		@extend .animate;
		position:absolute;
		top:50%; right:24px;
		z-index:2;
		margin-top:-6px;
		width:12px; height:12px;
		transform:rotate(90deg);

		svg {
			display:block;
			width:12px; height:12px;
			fill:$color_theme_01-01;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT RADIO / CHECKBOX ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-radio,
.input-checkbox {
	@extend .animate;
	@extend .relative;
	@extend .flex_row;
	align-items:center;

	input {
		position:absolute;
		top:50%; left:0;
		z-index:1;
		margin:0; padding:0;
		width:24px; height:24px;
		line-height:1;
		color:$color_theme_01-01;
		font-size:100%; font-size:$font_size_base;
		box-sizing:border-box;
		background:none;
		border:0 solid;
		border-radius:0;
		opacity:0;
		cursor:pointer;
		appearance:none;
		transform:translateY(-50%);

		&:checked:not(:disabled) + span,
		&:checked:not(:disabled) + span {

			&:after,
			svg {
				opacity:1;
			}
		}

		@media screen and (min-width:769px)
		{

			&:not(:disabled):hover + span,
			&:not(:disabled):focus + span {
				border-color:rgba($color_theme_01-01, .24);
			}
		}

		&:disabled {
			cursor:default;

			& + span + label {
				opacity:.24;
				cursor:default;
			}
		}
	}

	input + span {
		@extend .animate;
		@extend .flex_column;
		justify-content:center;
		align-items:center;
		width:24px; height:24px;
		background:#FFF;
		border:1px solid rgba($color_theme_01-01, .12);
		box-sizing:border-box;

		&:after,
		svg {
			@extend .animate;
			display:block;
			opacity:0;
		}
	}

	input + span + label {
		flex:1 1 auto;
		flex-direction:column;
		margin:0; padding:0 0 0 12px;
		line-height:1.2;
		cursor:pointer;

		a {
			@extend .animate;
			color:$color_theme_01-01;
			text-decoration:underline;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:$color_theme_02-01;
				}
			}
		}
	}

	& + .field-feedback:before {
		left:6px;
	}
}

.input-radio {

	input + span {
		border-radius:100%;

		&:after {
			content:"";
			width:8px; height:8px;
			background:$color_theme_03-01;
			border-radius:100%;
		}
	}
}

.input-checkbox {

	input + span {

		svg {
			width:12px; height:12px;
			fill:$color_theme_03-01;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT SUBMIT ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-submit {
	@extend .flex_column;
	align-items:center;
	padding-top:48px;

	@media screen and (max-width:768px)
	{
		padding-top:24px;
	}

	button {
		min-width:200px;
		text-align:center;
	}
}
