/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer {
	@extend .flex_column;
	align-items:center;
	padding:96px 96px 48px;
	background:$color_theme_01-02;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding:48px 48px 24px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
	}
}
	.footer-content {
		width:100%;
	}
		.footer-copyright {
			padding-top:96px;
			color:rgba(#FFF, .48);

			@media screen and (max-width:1220px) and (min-width:769px)
			{
				padding-top:48px;
			}
			@media screen and (max-width:768px)
			{
				padding-top:24px;
				font-size:12px;
			}
		}
			.footer-copyright_link {
				@extend .animate;
				color:rgba(#FFF, .48);
				text-decoration:none;
				border-bottom:1px solid rgba(#FFF, .48);

				@media screen and (min-width:769px)
				{
					&:hover,
					&:focus {
						color:#FFF;
						border-bottom:1px solid transparent;
					}
				}
			}
