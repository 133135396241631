/* ------------------------------------------------------------------------------------------------------------------------ */
/* AJAX LOADER ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
body {

	&:before,
	&:after {
		@extend .animate;
		content:"";
		position:fixed;
		opacity:0;
		visibility:hidden;
	}
	&:before {
		top:0; left:0; right:0; bottom:0;
		z-index:99998;
		width:100%; height:100%;
		background:rgba(#000, .88);
	}
	&:after {
		top:50%; left:50%;
		z-index:99999;
		width:24px; height:24px;
		margin-top:-12px; margin-left:-12px;
		border:2px solid #FFF; border-top:2px solid transparent; border-radius:50%;
		box-sizing:border-box;
		animation:spin 600ms linear infinite;
	}

	&.is-ajax-loading {

		&:before,
		&:after {
			opacity:1;
			visibility:visible;
		}
	}
}
