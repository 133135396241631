/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo,
.maddermarket-theatre_logo {
	display:block;
	width:auto; height:96px;

	@media screen and (max-width:768px)
	{
		margin:0 12px;
		height:48px;
	}
}
	.maddermarket-theatre_logo {
		padding:6px;
		border:2px solid $color_theme_02-01;
		box-sizing:border-box;
	}
		.maddermarket-theatre_logo-madder,
		.maddermarket-theatre_logo-market {
			fill:$color_theme_02-01;
		}

_:-ms-fullscreen, :root .logo,
_:-ms-fullscreen, :root .maddermarket-theatre_logo {
	width:110px;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER LOGO ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo-header {

	.maddermarket-theatre_theatre {
		fill:$color_theme_01-01;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER LOGO ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo-footer {

	.maddermarket-theatre_theatre {
		fill:#FFF;
	}
}
