/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-wrapper {
	@extend .flex_column;
	@extend .relative;
	min-height:calc(100vh - 332px);
	color:#FFF;
	background:$color_theme_01-01;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		min-height:calc(100vh - 351px);
	}
	@media screen and (max-width:768px)
	{
		min-height:calc(100vh - (60px + (48px * 3)));
	}

	&:before {
		@extend .relative;
		content:"";
		display:block;
		padding-top:28%;
		width:100%; height:0;
		overflow:hidden;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			padding-top:27%;
		}
	}
}
	.hero.js-slick {
		@extend .animate;
		width:100% !important; height:100% !important;
		opacity:0;

		&.is-visible {
			opacity:1;
		}

		.slick-dots {
			@extend .flex_row;
			position:absolute;
			left:50%; bottom:-54px;
			z-index:1;
			transform:translateX(-50%);
			height:12px;
			list-style:none;

			@media screen and (max-width:1220px) and (min-width:769px)
			{
				bottom:-30px;
			}
			@media screen and (max-width:768px)
			{
				display:none;
			}

			li {
				margin:0 3px;
				width:12px; height:12px;

				button {
					@extend .animate;
					display:block;
					width:12px; height:12px;
					font-size:0px;
					text-indent:-999em;
					background:#FFF !important;
					border-radius:100%;
					overflow:hidden;
					opacity:.48;
				}

				&.slick-active button {
					opacity:1;
				}
			}
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (BACKGROUND) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-background {
	@extend .flex_column;
	position:absolute !important;
	top:0; left:0; right:0; bottom:0;
	z-index:1 !important;

	.slick-track {
		position: absolute;
		height: 100%;
	}
}
	.hero-background_slide {
		@extend .relative;
        flex:1 1 auto;
	}
		.hero-background_slide-image,
		.hero-background_slide-image picture,
		.hero-background_slide-image picture source,
		.hero-background_slide-image picture img {
			position:absolute !important;
			top:0; left:0; right:0; bottom:0;
			z-index:1 !important;
			width:100% !important; height:100% !important;
		}
			.hero-background_slide-image picture img {
				object-fit:cover; font-family:'object-fit:cover;';
			}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (CONTENT) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-content_wrapper {
	@extend .relative;
	@extend .flex_row;
	justify-content:center;
	align-items:center;
	position:absolute;
	left:0; right:0; bottom:0;
	z-index:2;
	margin:96px 48px;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		margin:48px 12px;
	}
	@media screen and (max-width:768px)
	{
		margin:24px 0;
	}
}
	.hero-content_center {
		margin:0 96px;
		width:calc(100% - 192px);

		.slick-slide {
			> div {
				line-height: 0;
			}
		}

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			margin:0 48px;
			width:calc(100% - 96px);
		}
		@media screen and (max-width:768px)
		{
			margin:0 24px;
			width:calc(100% - 48px);

			.slick-track {
				display:flex !important;
				flex-direction:row;
				align-items:flex-end;
			}
		}
	}

			.hero-content_slide {
				@extend .relative;
				flex: 100%;
			}
				.hero-content_slide-content {
                  	flex: 100%;
				}
					.hero-content_slide-content_heading-wrapper {
						justify-content:center;
					}
						.hero-content_slide-content_heading {

							@media screen and (max-width:1220px) and (min-width:769px)
							{
								font-size:48px;
								font-weight:600;
							}
							@media screen and (max-width:768px)
							{
								padding:0 18px;
								font-weight:700;
							}
						}
						.hero-content_slide-content_text {
							padding-top:24px;
							line-height:$line_height_base;
							font-size:$font_size_base;

							@media screen and (max-width:768px)
							{
								padding:0 18px;
							}
						}
						.hero-content_slide-content_link-wrapper {

							@media screen and (min-width:769px)
							{
								justify-content:flex-end;
								align-items:flex-end;
							}
							@media screen and (max-width:768px)
							{
								align-items:flex-start;
								margin-top:24px;
							}
						}
							.hero-content_slide-content_link {
								padding:18px 24px;
								line-height:1.3;
								color:#FFF;
								font-size:20px;
								font-weight:500;

								@media screen and (max-width:768px)
								{
									margin:0 18px;
									font-size:16px;
								}

								&.bg_01-01 {
									@extend .animate;

									@media screen and (min-width:769px)
									{
										&:not(:disabled):hover,
										&:not(:disabled):focus {
											background:$color_theme_02-01 !important;
										}
									}
								}
								&.bg_white {
									@extend .animate;
									color:$color_theme_02-01;

									@media screen and (min-width:769px)
									{
										&:not(:disabled):hover,
										&:not(:disabled):focus {
											color:#FFF !important;
											background:$color_theme_02-01 !important;
										}
									}
								}
							}
					.hero-content_slide-content_info {
						@extend .flex_row;
						flex-wrap:wrap;
						flex:1 1 auto;
						background:#FFF;

						&:first-child {

							@media screen and (max-width:768px)
							{
								margin-top:24px;
							}
						}
						&:not(:first-child) {
							border-top:1px solid $color_theme_01-05;
						}
					}
						.hero-content_slide-content_info-item {
							@extend .flex_column;
							justify-content:center;
							padding:18px 24px;
							width:50%;
							line-height:1.3;
							color:$color_theme_01-01;
							box-sizing:border-box;

							@media screen and (max-width:768px)
							{
								padding:18px;
							}

							&:only-child {
								width:100%;
							}
							&:not(:first-child) {
								border-left:1px solid $color_theme_01-05;
							}

							/*
							&:nth-child(even) {
								border-left:1px solid $color_theme_01-05;
							}
							&:nth-child(n+2) {
								border-top:1px solid $color_theme_01-05;
							}
							*/
						}
							.hero-content_slide-content_info-item_label {
								font-size:12px;
								font-weight:500;
								text-transform:uppercase;

								@media screen and (max-width:768px)
								{
									font-size:10px;
								}
							}
							.hero-content_slide-content_info-item_value {

								strong {
									font-size:20px;
									font-weight:500;

									@media screen and (max-width:768px)
									{
										font-size:14px;
									}
								}
								span {

									@media screen and (max-width:768px)
									{
										font-size:12px;
									}
								}
								a {
									@extend .animate;
									color:$color_theme_01-01;

									@media screen and (min-width:769px)
									{
										&:hover,
										&:focus {
											color:$color_theme_02-01;
										}
									}
								}
							}
						.hero-content_slide-content_info-link_wrapper,
						.hero-content_slide-content_info-link {
							@extend .flex_column;
							justify-content:center;
							align-items:center;
							width:50%;
							box-sizing:border-box;
						}
						.hero-content_slide-content_info-link_wrapper .hero-content_slide-content_info-link {
							flex:1 1 auto;
							width:100%;
						}
						.hero-content_slide-content_info-link {
							padding:18px 24px;
							line-height:1.3;
							font-size:20px;
							font-weight:500;

							@media screen and (max-width:768px)
							{
								font-size:16px;
							}

							&.link_more-info {
								color:#FFF;

								@media screen and (min-width:769px)
								{
									&:hover,
									&:focus {
										color:$color_theme_02-01;
										background:$color_theme_01-01 !important;
									}
								}
							}
						}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (PREV / NEXT) ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-content_controls {
	@extend .absolute;

	&:first-child {
		left:0;
	}
	&:last-child {
		right:0;
	}

	@media screen and (min-width:769px)
	{
		top:50%;
		transform:translateY(-50%);
	}
	@media screen and (max-width:768px)
	{
		top:auto; bottom:12px;
	}
}
.hero-prev,
.hero-next {
	width:96px; height:96px;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		width:48px; height:48px;
	}
	@media screen and (max-width:768px)
	{
		width:24px; height:24px;
	}

	svg {
		@extend .animate;
		display:block;
		width:96px; height:96px;
		fill:rgba(#FFF, .48);

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			width:48px; height:48px;
		}
		@media screen and (max-width:768px)
		{
			width:24px; height:24px;
			fill:#FFF;
		}
	}

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:#FFF;
		}
	}
}
.hero-prev {

	svg {
		transform:rotate(180deg);
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (STANDARD) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-standard {
	@extend .flex_column;
	@extend .relative;
	justify-content:center;
	align-items:center;
	padding:96px;
	min-height:50vh;
	color:#FFF;
	background:$color_theme_01-01;
	box-sizing:border-box;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		justify-content:flex-end;
		padding:24px;
		min-height:calc(100vh - 114px);
	}
}

	.hero-standard_bg {
		@extend .full_size;

		&:before {
			content:"";
			position:absolute;
			top:0; left:0; right:0; bottom:0;
			z-index:3;
			background:linear-gradient(to bottom, rgba(#000, 0) 0%, rgba(#000, 1) 100%);
			opacity:.32;
		}

		img {
			@extend .full_size;
			width:100% !important; height:100% !important;
			opacity:.64;
			object-fit:cover; font-family:'object-fit:cover;';
		}

		&.align-top img {
			object-position:top center; font-family:'object-fit:cover;object-position:top center;';
		}
		&.align-bottom img {
			object-position:bottom center; font-family:'object-fit:cover;object-position:bottom center;';
		}
	}

	.hero-standard_content-wrapper {
		position:relative;
		z-index:3;
		width:100%;
	}
		.hero-standard_content {
			@extend .flex_row;

			@media screen and (max-width:768px)
			{
				flex-direction:column;
				align-items:center;
			}

			&.pos-right {
				justify-content:flex-end;
			}
		}

			.hero-standard_content-logo {
				padding-right:48px;
				width:100%; max-width:174px;

				@media screen and (max-width:768px)
				{
					padding-right:0;
					max-width:96px;
				}
			}
			.hero-standard_content-heading_wrapper {
				flex:1 1 auto;
				width:100%; max-width:600px;

				@media screen and (max-width:768px)
				{
					padding-top:24px;
					text-align:center;
				}
			}
				.hero-standard_content-heading {
					font-weight:300;
				}
				.hero-standard_content-subheading {
					padding-top:12px;
					font-weight:400;

					@media screen and (max-width:768px)
					{
						font-size:18px;
					}
				}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (STANDARD) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-404 {
	align-items:center;
	box-sizing:border-box;

	&:before {
		display:none;
	}
}
	.hero-404_content-wrapper {
		position:relative;
		z-index:3;
		width:100%;
	}
		.hero-404_content-heading {
			font-size:96px;
		}
		.hero-404_content-subheading {
			padding-top:24px;
		}
		.hero-404_content-text {
			padding-top:12px;
		}
		a.hero-404_content-link {
			margin-top:64px;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:#FFF !important;
					background:$color_theme_02-01 !important;
				}
			}
			@media screen and (max-width:768px)
			{
				margin-top:24px;
			}
		}
