/* --------------------------------------------------------------------------------------------------- */
/* GALLERY ------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.gallery {
	padding-top:12px;

	@media screen and (max-width:768px)
	{
		margin-left:-3px !important; margin-right:-3px !important;

		& > .column {
			padding-left:3px !important; padding-right:3px !important;
			width:50%;
		}
	}
}
	.gallery_item {
		@extend .relative;
		display:block !important;
		margin-top:12px;
		background:$color_theme_01-01;
		overflow:hidden;

		@media screen and (min-width:769px)
		{
			&:not(.js-slick_slide):hover .gallery_item-image,
			&:not(.js-slick_slide):focus .gallery_item-image {
				opacity:.32;
				transform:scale(1.05);
			}
		}
		@media screen and (max-width:768px)
		{
			margin-top:6px;
		}
	}
		.gallery_item-btn {
			@extend .full_size;
			display:block;
			width:100%; height:100%;
		}
		.gallery_item-image {
			@extend .animate;
			@extend .relative;
			display:block;
			width:100%; height:auto;

			&:before {
				content: "(url: " attr(src) ")";
			}
		}

/* --------------------------------------------------------------------------------------------------- */
/* GALLERY (INLINE) ---------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.gallery-inline {
	padding-top:48px;

	@media screen and (max-width:768px)
	{
		padding-top:18px;
	}
}
	.gallery-inline_current {

		.gallery_item {
			margin:0;
		}
	}

	.gallery-inline_thumbnails {
		margin:6px -3px 0;
		width:calc(100% + 6px);

			.gallery_item-wrapper {
				padding:0 3px;
			}
				.gallery_item {
					margin:0;
					cursor:pointer;
				}

		.slick-current .gallery_item-image {
			opacity:.32;
			transform:scale(1.05);
		}
	}

/* --------------------------------------------------------------------------------------------------- */
/* GALLERY (MODAL) ----------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.modal-gallery_wrapper {

	@media screen and (max-width:768px)
	{
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		justify-content:center;
		padding:0 12px;
		background:rgba(#FFF, .88) !important;
	}
}
	.modal-gallery_content,
	.modal-gallery {
		height:calc(100vh - 144px);

		@media screen and (max-width:768px)
		{
			width:calc(100vw - 24px); height:calc(100vh - 96px);
		}
	}
		.modal-gallery {
		}
			.modal-gallery_item {
				@extend .relative;
				display:flex !important;
				flex-direction:column;
				flex-wrap:nowrap;
				flex:1 1 auto;
			}
				.modal-gallery_item-image {
					@extend .full_size;
					width:100%; height:100%;
					object-fit:contain; font-family:'object-fit:contain;';
				}

.modal-gallery_controls {
	@extend .flex_row;
	position:fixed;
	top:24px; right:24px;

	@media screen and (max-width:768px)
	{
		top:auto; left:50%; right:auto; bottom:8px;
		transform:translateX(-50%);
	}
}
	.modal-gallery_btn {
		width:24px; height:24px;

		@media screen and (max-width:768px)
		{
			display:flex !important;
			flex-direction:column;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:center;
			margin:0 2px;
			width:32px; height:32px;
			background:$color_theme_01-01 !important;
			border-radius:3px;
		}

		&:not(:last-child) {

			@media screen and (min-width:769px)
			{
				margin-right:12px;
			}
		}

		svg {
			@extend .animate;
			display:block;
			width:24px; height:24px;
			fill:#FFF;

			@media screen and (max-width:768px)
			{
				width:16px; height:16px;
			}
		}

		@media screen and (min-width:769px)
		{
			&:hover svg,
			&:focus svg {
				fill:$color_theme_02-01;
			}
		}
	}
	.modal-gallery_btn-prev svg {
		transform:rotate(180deg);
	}
