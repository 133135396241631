/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRODUCT INFO ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.product-info {
	width:100%; max-width:calc((#{$site_width_base} / 2) - 32px);
}

	.product-info_dates {
		@extend .flex_row;
		padding-bottom:9px;
		line-height:1.3;
	}
		.product-info_dates-divider {
			flex-shrink:0;
			margin:10px 12px 0;
			width:24px; height:1px;
			background:#FFF;
		}

	.product-info_block,
	.product-info_btn-wrapper {
		@extend .flex_row;
		justify-content:space-between;
	}
		.product-info_categories,
		.product-info_price,
		.product-info_badge {
			width:calc(50% - 16px);

			@media screen and (max-width:768px)
			{
				width:calc(50% - 12px);
			}
		}

	.product-info_block {
		padding-top:18px;
	}

		.product-info_categories {

			span {
				display:block;
			}
		}
			.product-info_categories-list {
				display:flex;
				flex-direction:row;
				flex-wrap:wrap;
				list-style:none;
			}
				.product-info_categories-list_item {

					&:not(:last-child) {
						padding-right:4px;

						&:after {
							content:",";
						}
					}
				}
				.product-info_categories-list_item-link {
					@extend .animate;
					color:#FFF;
					font-weight:500;

					@media screen and (min-width:769px)
					{
						&:hover,
						&:focus {
							color:$color_theme_02-01;
						}
					}
				}

		.product-info_price {

			strong {
				font-weight:500;
			}
		}

	.product-info_btn-wrapper {
		padding-top:48px;
		align-items:center;

		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}
	}
		.product-info_btn {
			width:auto;
		}

		.product-info_badge {
			@extend .flex_row;
			align-items:center;
		}
			.product-info_badge-symbol {
				@extend .flex_column;
				flex-shrink:0;
				justify-content:center;
				align-items:center;
				width:48px; height:48px;
				font-weight:700;
				border:2px solid #FFF;
				border-radius:100%;

				svg {
					flex-shrink:0;
					display:block;
					width:18px; height:18px;
					fill:#FFF;
				}
			}
			.product-info_badge-label {
				padding-left:16px;
				color:rgba(#FFF, .48);
			}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRODUCT REVIEWS -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.product-reviews_wrapper {
	padding-top:96px;

	&:first-child {
		margin-top:-96px;
	}

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding-top:48px;

		&:first-child {
			margin-top:-48px;
		}
	}
	@media screen and (max-width:768px)
	{
		padding-top:24px;

		&:first-child {
			margin-top:-24px;
		}
	}
}
	.product-reviews {

		@media screen and (max-width:768px)
		{
			padding:0;
			border:none;
		}
	}

		.product-reviews_heading {
		}

		.product-reviews_slider-wrapper {
			flex:1 1 auto;
			overflow:hidden;

			@media screen and (max-width:768px)
			{
				padding-top:12px;
			}
		}
			.product-reviews_slider {
				width:100%;
			}
				.product-reviews_slider-item {
				}
					.product-reviews_slider-item_rating {
						@extend .relative;
					}
						.product-reviews_slider-item_rating-bg {
							@extend .relative;
							display:block;
							width:auto; height:24px;

							svg {
								display:block;
								width:auto; height:24px;
								fill:$color_theme_01-05;
							}
						}
						.product-reviews_slider-item_rating-over {
							display:block;
							position:absolute;
							top:0; left:0;
							z-index:2;
							width:auto; height:24px;
							overflow:hidden;

							&.rating_10-50 { width:24px; }
							&.rating_15-50 { width:40px; }
							&.rating_20-50 { width:52px; }
							&.rating_25-50 { width:68px; }
							&.rating_30-50 { width:80px; }
							&.rating_35-50 { width:96px; }
							&.rating_40-50 { width:108px; }
							&.rating_45-50 { width:124px; }
							&.rating_50-50 { width:136px; }

							svg {
								display:block;
								width:auto; height:24px;
								fill:#FF8F00;
							}
						}
				.product-reviews_slider-item_text {
					padding-top:24px;

					@media screen and (max-width:768px)
					{
						padding-top:12px;
					}
				}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRODUCT SHARE ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.product-share {
	align-items:center;
	margin-top:96px;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		margin-top:48px;
	}
	@media screen and (max-width:768px)
	{
		align-items:flex-start;
		margin-top:24px;
	}
}

.product-share_link-back_wrapper {
	@extend .pb-block;
	@extend .flex_row;
	justify-content:flex-start;
}
	.product-share_link-back {
		@extend .animate;
		@extend .flex_row;
		align-items:center;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:$color_theme_02-01;

				svg {
					fill:$color_theme_02-01;
				}
			}
		}

		svg {
			@extend .animate;
			display:block;
			width:18px; height:18px;
			fill:$color_theme_01-01;
			transform:rotate(90deg);
		}
		span {
			padding-left:12px;
			font-weight:500;
		}
	}
