/* --------------------------------------------------------------------------------------------------- */
/* POST PASSWORD FORM -------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.post-password-form {
    p:first-child {
        margin-bottom:24px;
        font-weight:bold;
    }

    label {
        line-height: 1.4;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
    }

    input[type="password"] {
        margin: 0 0 24px;
        padding: 16px 24px;
        width: 100%;
        height: auto;
        line-height: 1.4;
        color: #666;
        font-family: Quicksand,sans-serif;
        font-size: 100%;
        font-size: 16px;
        box-sizing: border-box;
        background: #fff;
        border: 1px solid #0c04021f;
        border-radius: 0;
        appearance: none;
    }

    input[type="submit"] {
        margin: 0;
        line-height: 1.6;
        font-family: Quicksand,sans-serif;
        font-size: 100%;
        border: 0 solid;
        border-radius: 0;
        text-transform: none;
        color: #fff !important;
        background: #e9423a !important;
        appearance: none;
        cursor: pointer;
        overflow: visible;
        transition: color .3s ease-in-out, background .3s ease-in-out, border .3s ease-in-out;
        display: inline-block;
        box-sizing: border-box;
        padding: 16px 24px;
        font-weight: 500;
        width: auto;
        transition-duration: .3s;
        transition-timing-function: ease-in-out;
        min-width: 200px;
        text-align: center;

        &:hover {
            background: #cc271f !important;
        }
    }
}