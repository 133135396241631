/* --------------------------------------------------------------------------------------------------- */
/* FLASH MESSAGE -------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.flash-messages {
	position:fixed;
	left:12px; bottom:12px;
	z-index:99999;
	line-height:$line_height_small;
	color:#FFF;
	font-size:$font_size_small;

	@media screen and (max-width:768px)
	{
		right:12px;
	}

	&.is-all-dismissed {
		display:none;
	}
}

	.flash-message {
		@extend .animate;
		@extend .flex_row;
		justify-content:space-between;
		position:relative;
		top:auto; left:auto;
		padding:0;
		max-width:360px;
		transform:none;

		& + .flash-message {
			margin-top:6px;
		}

		&.flash_error,
		&.flash_invalid {
			background:$color_error;

			.flash-message_close {
				background:darken($color_error, 9%);
			}
		}
		&.flash_alert {
			background:$color_alert;

			.flash-message_close {
				background:darken($color_alert, 9%);
			}
		}
		&.flash_valid {
			background:$color_valid;

			.flash-message_close {
				background:darken($color_valid, 9%);
			}
		}
		&.flash_update {
			background:$color_update;

			.flash-message_close {
				background:darken($color_update, 9%);
			}
		}

		&.is-dismissed {
			opacity:0;
			transform:translateX(12px);
			visibility:hidden;
		}
	}

	.flash-message_close {
		@extend .flex_column;
		justify-content:center;
		align-items:center;
		flex-shrink:0;
		z-index:1;
		width:48px;

		svg {
			@extend .animate;
			display:block;
			width:14px; height:14px;
			fill:#FFF;
			opacity:.48;
		}

		@media screen and (min-width:769px)
		{
			&:hover svg,
			&:focus svg {
				opacity:1;
			}
		}
	}
