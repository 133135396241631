/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE HEADING ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page-heading_wrapper {
	padding:48px 96px;
	flex:0 0 auto;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
	}

	.page-preheading,
	.page-subheading {
		display:block;
		padding-bottom:3px;
		color:rgba(#FFF, .64);
		font-size:$font_size_small;
		font-weight:400;
	}
	.page-preheading {
		padding-bottom:3px;
	}
	.page-subheading {
		padding-top:12px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page-builder {
}
	.pb-block {

		&:not(:first-child) {
			padding-top:96px;

			@media screen and (max-width:1220px) and (min-width:769px)
			{
				padding-top:48px;
			}
			@media screen and (max-width:768px)
			{
				padding-top:24px;
			}
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (MEDIA + TEXT) -------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_media-text {
}
	.pb-block_media-text_text {

		@media screen and (max-width:768px)
		{
			padding-top:18px;
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (GRID OF TEXT ELEMENTS) ----------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_grid-text {

	.column {
		padding-top:1.5em;

		@media screen and (max-width:768px)
		{
			padding-top:1em;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (CTA) ----------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_cta {
}
	.pb-block_cta-content {
		padding:48px 96px;
		text-align:center;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			padding:48px;
		}
		@media screen and (max-width:768px)
		{
			padding:24px;
		}
	}
