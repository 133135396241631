/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography {
	color:$color_theme_01-03;

	h1, h2, h3, h4, h5, h6, p, ul, ol {
		padding-top:1.5em;

		@media screen and (max-width:768px)
		{
			padding-top:1em;
		}

		&:first-child {
			padding-top:0;
		}
	}

	&.half-spacing {

		h1, h2, h3, h4, h5, h6, p, ul, ol {
			padding-top:.75em;

			@media screen and (max-width:768px)
			{
				padding-top:.375em;
			}
		}
	}

	&.typo-center {
		text-align:center;
	}

	h1, h2, h3, h4, h5, h6, strong, .BoldText {
		color:$color_theme_01-01;
	}

	ul {
		list-style:none;
		counter-reset:counter;

		@media screen and (min-width:769px)
		{
			margin-top:-.5em;
		}

		li {
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			line-height:$line_height_small;

			&:before {
				flex-shrink:0;
			}
		}
	}
	ul li {

		&:before {
			content:"";
			margin:10px 12px 0 0;
			width:5px; height:5px;
			background:$color_theme_01-01;
			border-radius:100%;

			@media screen and (max-width:768px)
			{
				margin-top:7px;
			}
		}
	}
	ol {
		padding-left:16px;
		color:$color_theme_03-01;
		font-weight:500;

		li {
			color:$color_theme_01-03;
			font-weight:400;
		}
	}

	a:not(.btn):not(.link) {
		@extend .animate;
		@extend .relative;
		text-decoration:none;
		word-break:break-word;
		border-bottom:1px solid $color_theme_01-01;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:darken($color_theme_01-01, 9%);
				border-bottom:1px solid transparent;
			}
		}
	}

	blockquote {
		@extend .relative;
		padding:48px 0;
		text-align:center;

		@media screen and (max-width:768px)
		{
			padding:18px 0;
		}

		&:before,
		&:after {
			@extend .absolute;
			content:"";
			left:96px; right:96px;
			height:1px;
			background:$color_theme_01-05;

			@media screen and (max-width:768px)
			{
				left:24px; right:24px;
			}
		}
		&:before {
			top:0;
		}
		&:after {
			bottom:0;
		}
	}

	.typography-table_wrapper {
		margin-top:-12px;
		overflow-x:auto;

		table {
			min-width:600px;
		}
	}
		table {
			width:100%;
			border:none;
			border-collapse:collapse;
		}
		th, td {
			padding:16px 24px 8px 0;
			border-bottom:1px solid $color_theme_01-05;
		}
		th {
			@extend .h6;
			color:$color_theme_01-01;
			text-align:left;
		}

	.BoldText {
		font-weight:700;
	}
	.ItalicText {
		font-style:italic;
	}
	.UnderlineText {
		text-decoration:underline;
	}
}
