/* BASE */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- PRIMARY --- */
/* --- SYSTEM COLORS --- */
/* --- SOCIAL COLORS --- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAYOUT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* SPIN ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@import url(../vendor/flatpickr/flatpickr.css);
@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* DOCUMENT --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
* {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline; }

html {
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  background-color: #FFF; }

body {
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #2D2D2D;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: default;
  overflow-x: auto;
  overflow-y: scroll; }
  @media screen and (max-width: 768px) {
    body {
      line-height: 1.4;
      font-size: 14px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SECTIONS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECTIONS ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
::selection {
  color: #FFF;
  background-color: #E9423A; }

::-moz-selection {
  color: #FFF;
  background-color: #E9423A; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* DISPLAY FLEX ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.flex_row, .nav-header_list-item_link, .nav-primary_list, .nav-header-mobile_list, .nav-side_trigger, .field-label,
.fieldset-legend, .input-datepicker, .input-radio,
.input-checkbox, .modal-gallery_controls, .box-02, .list_item-info_categories-list_item, .list-01_item-info_btn-wrapper, .list-02_item-info_content-detail_item,
.list-03_item-info_detail-item, .pagination-01_list, .hero.js-slick .slick-dots, .hero-content_wrapper, .hero-content_slide-content_info, .hero-standard_content, .product-info_dates, .product-info_block,
.product-info_btn-wrapper, .product-info_badge, .product-share_link-back_wrapper, .product-share_link-back, .form_panel-search, .product-filters_btn-wrapper, .product-filters_trigger-wrapper, .product-filters_trigger-container, .booking-table_item, .booking-table_item-timeslot, .booking-table_item-timeslot_lhc, .faqs-item_question, .social-feed_filters-wrapper, .social-feed_filters-trigger, .social-feed_filters-list, .flash-message, .header-block_top,
.header-block_bottom, .header-top_block-link,
.header-block_bottom-link, spektrix-basket-item-count span, .header-top .header-content, .header-top .header-top_block,
.flex_column,
.grid .column,
.nav-header,
.nav-secondary_list,
.nav-header-mobile_list-item,
.nav-header-mobile_list-item_link,
.nav-footer_sublist-socials_item-link,
.nav-socials_list-item_link,
.badge-valid,
.badge-invalid,
.input-datepicker .datepicker-clear,
.input-radio input + span,
.input-checkbox input + span,
.input-submit,
.box-01,
.panel-close,
.list-01_item-info,
.list-02_item-info_btn-wrapper,
.list-03_item-btn_wrapper,
.pagination-01,
.pagination-01_page,
.pagination-01_prev .pagination-01_list-item_link,
.pagination-01_next .pagination-01_list-item_link,
.hero-wrapper,
.hero-background,
.hero-content_slide-content_info-item,
.hero-content_slide-content_info-link_wrapper,
.hero-content_slide-content_info-link,
.hero-standard,
.product-info_badge-symbol,
.modal-search-desktop_content,
.form_panel-search .input-submit,
.product-filters_container,
.item_news .social-feed_item,
.item_news .social-feed_item-text,
.item_news-btn_wrapper,
.flash-message_close,
.wrapper,
.main-container,
.header,
.header-block,
spektrix-basket-item-count,
.footer {
  display: flex;
  flex-wrap: nowrap; }

.flex_row, .nav-header_list-item_link, .nav-primary_list, .nav-header-mobile_list, .nav-side_trigger, .field-label,
.fieldset-legend, .input-datepicker, .input-radio,
.input-checkbox, .modal-gallery_controls, .box-02, .list_item-info_categories-list_item, .list-01_item-info_btn-wrapper, .list-02_item-info_content-detail_item,
.list-03_item-info_detail-item, .pagination-01_list, .hero.js-slick .slick-dots, .hero-content_wrapper, .hero-content_slide-content_info, .hero-standard_content, .product-info_dates, .product-info_block,
.product-info_btn-wrapper, .product-info_badge, .product-share_link-back_wrapper, .product-share_link-back, .form_panel-search, .product-filters_btn-wrapper, .product-filters_trigger-wrapper, .product-filters_trigger-container, .booking-table_item, .booking-table_item-timeslot, .booking-table_item-timeslot_lhc, .faqs-item_question, .social-feed_filters-wrapper, .social-feed_filters-trigger, .social-feed_filters-list, .flash-message, .header-block_top,
.header-block_bottom, .header-top_block-link,
.header-block_bottom-link, spektrix-basket-item-count span, .header-top .header-content, .header-top .header-top_block {
  flex-direction: row; }


.flex_column,
.grid .column,
.nav-header,
.nav-secondary_list,
.nav-header-mobile_list-item,
.nav-header-mobile_list-item_link,
.nav-footer_sublist-socials_item-link,
.nav-socials_list-item_link,
.badge-valid,
.badge-invalid,
.input-datepicker .datepicker-clear,
.input-radio input + span,
.input-checkbox input + span,
.input-submit,
.box-01,
.panel-close,
.list-01_item-info,
.list-02_item-info_btn-wrapper,
.list-03_item-btn_wrapper,
.pagination-01,
.pagination-01_page,
.pagination-01_prev .pagination-01_list-item_link,
.pagination-01_next .pagination-01_list-item_link,
.hero-wrapper,
.hero-background,
.hero-content_slide-content_info-item,
.hero-content_slide-content_info-link_wrapper,
.hero-content_slide-content_info-link,
.hero-standard,
.product-info_badge-symbol,
.modal-search-desktop_content,
.form_panel-search .input-submit,
.product-filters_container,
.item_news .social-feed_item,
.item_news .social-feed_item-text,
.item_news-btn_wrapper,
.flash-message_close,
.wrapper,
.main-container,
.header,
.header-block,
spektrix-basket-item-count,
.footer {
  flex-direction: column; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wLarge {
  max-width: 1440px; }

.wBase {
  max-width: 1220px; }

.wMedium {
  max-width: 980px; }

.wSmall {
  max-width: 860px; }

.wXSmall {
  max-width: 440px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BLEEDS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.bleed_24 {
  padding: 24px; }

.bleed_48 {
  padding: 48px; }

.bleed_96 {
  padding: 96px; }

.bleed_top_48 {
  padding-top: 48px; }

@media screen and (max-width: 1220px) and (min-width: 769px) {
  .bleed_96 {
    padding: 48px; } }

@media screen and (max-width: 768px) {
  .bleed_48,
  .bleed_96 {
    padding: 24px; }
  .bleed_top_48 {
    padding-top: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MARGINS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_margin {
  margin: 0 !important; }

.reset_margin_top {
  margin-top: 0 !important; }

.reset_margin_right {
  margin-right: 0 !important; }

.reset_margin_bottom {
  margin-bottom: 0 !important; }

.reset_margin_left {
  margin-left: 0 !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PADDINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_padding {
  padding: 0 !important; }

.reset_padding_top {
  padding-top: 0 !important; }

.reset_padding_right {
  padding-right: 0 !important; }

.reset_padding_bottom {
  padding-bottom: 0 !important; }

.reset_padding_left {
  padding-left: 0 !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_border {
  border: 0 solid !important; }

.reset_border_top {
  border-top: 0 solid !important; }

.reset_border_right {
  border-right: 0 solid !important; }

.reset_border_bottom {
  border-bottom: 0 solid !important; }

.reset_border_left {
  border-left: 0 solid !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* POSITIONS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.relative, .lazyload_wrapper, .lazyload_wrapper picture,
.lazyload_wrapper img, .nav-header_list-item, .nav-header_list-item_link-chevron, .nav-header-mobile_list-item_link, .field-feedback, .input-text, .input-datepicker, .field-select, .field-select select, .input-radio,
.input-checkbox, .gallery_item, .gallery_item-image, .modal-gallery_item, .wrapper_video, .list_item-thumb, .list_item-thumb:before, .list_item-info_heading-link, .list-02-02 .list-02_item-thumb, .hero-wrapper, .hero-wrapper:before, .hero-background_slide, .hero-content_wrapper, .hero-content_slide, .hero-standard, .product-reviews_slider-item_rating, .product-reviews_slider-item_rating-bg, .form_modal-search, .social-feed_item, .typography a:not(.btn):not(.link), .typography blockquote {
  position: relative;
  z-index: 1; }

.absolute, .nav-header_list-item_link-chevron:before, .nav-header_submenu:before, .nav-side_trigger:after, .nav-side.is-affixed:not(.nav-side_border) .inner-wrapper-sticky:before, .btn-prev,
.btn-next, .list_item-info_heading-link:before, .hero-content_controls, .product-filters_trigger-container:after, .typography blockquote:before, .typography blockquote:after, .header:after {
  position: absolute;
  z-index: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOURS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Black(ish) --- */
.color_01-01 {
  color: #0C0402 !important; }

@media screen and (min-width: 769px) {
  a.color_01-01:not(:disabled):hover, a.color_01-01:not(:disabled):focus,
  button.color_01-01:not(:disabled):hover,
  button.color_01-01:not(:disabled):focus {
    color: #0C0402 !important;
    cursor: pointer; } }

/* --- Grey --- */
.color_01-03 {
  color: #666 !important; }

@media screen and (min-width: 769px) {
  a.color_01-03:not(:disabled):hover, a.color_01-03:not(:disabled):focus,
  button.color_01-03:not(:disabled):hover,
  button.color_01-03:not(:disabled):focus {
    color: #2D2D2D !important;
    cursor: pointer; } }

/* --- Orange(ish) --- */
.color_02-01 {
  color: #E9423A !important; }

@media screen and (min-width: 769px) {
  a.color_02-01:not(:disabled):hover, a.color_02-01:not(:disabled):focus,
  button.color_02-01:not(:disabled):hover,
  button.color_02-01:not(:disabled):focus {
    color: #E9423A !important;
    cursor: pointer; } }

/* --- Green --- */
.color_03-01 {
  color: #008D61 !important; }

@media screen and (min-width: 769px) {
  a.color_03-01:not(:disabled):hover, a.color_03-01:not(:disabled):focus,
  button.color_03-01:not(:disabled):hover,
  button.color_03-01:not(:disabled):focus {
    color: #008D61 !important;
    cursor: pointer; } }

/* --- White --- */
.color_white {
  color: #FFF !important; }

@media screen and (min-width: 769px) {
  a.color_white:not(:disabled):hover, a.color_white:not(:disabled):focus,
  button.color_white:not(:disabled):hover,
  button.color_white:not(:disabled):focus {
    color: #FFF !important;
    cursor: pointer; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BACKGROUNDS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Black(ish) --- */
.bg_01-01 {
  background: #0C0402 !important; }

@media screen and (min-width: 769px) {
  a.bg_01-01:not(:disabled):hover, a.bg_01-01:not(:disabled):focus,
  button.bg_01-01:not(:disabled):hover,
  button.bg_01-01:not(:disabled):focus {
    background: black !important;
    cursor: pointer; } }

/* --- Grey --- */
.bg_01-03 {
  background: #666 !important; }

@media screen and (min-width: 769px) {
  a.bg_01-03:not(:disabled):hover, a.bg_01-03:not(:disabled):focus,
  button.bg_01-03:not(:disabled):hover,
  button.bg_01-03:not(:disabled):focus {
    background: #4f4f4f !important;
    cursor: pointer; } }

/* --- Darkest Gray --- */
.bg_01-02 {
  background: #2D2D2D !important; }

@media screen and (min-width: 769px) {
  a.bg_01-02:not(:disabled):hover, a.bg_01-02:not(:disabled):focus,
  button.bg_01-02:not(:disabled):hover,
  button.bg_01-02:not(:disabled):focus {
    background: #161616 !important;
    cursor: pointer; } }

/* --- Lightest Gray --- */
.bg_01-05 {
  background: #EFEFEF !important; }

@media screen and (min-width: 769px) {
  a.bg_01-05:not(:disabled):hover, a.bg_01-05:not(:disabled):focus,
  button.bg_01-05:not(:disabled):hover,
  button.bg_01-05:not(:disabled):focus {
    background: #d8d8d8 !important;
    cursor: pointer; } }

/* --- Orange(ish) --- */
.bg_02-01 {
  background: #E9423A !important; }

@media screen and (min-width: 769px) {
  a.bg_02-01:not(:disabled):hover, a.bg_02-01:not(:disabled):focus,
  button.bg_02-01:not(:disabled):hover,
  button.bg_02-01:not(:disabled):focus {
    background: #CC271F !important;
    cursor: pointer; } }

/* --- Green --- */
.bg_03-01 {
  background: #008D61 !important; }

@media screen and (min-width: 769px) {
  a.bg_03-01:not(:disabled):hover, a.bg_03-01:not(:disabled):focus,
  button.bg_03-01:not(:disabled):hover,
  button.bg_03-01:not(:disabled):focus {
    background: #00734E !important;
    cursor: pointer; } }

/* --- Green --- */
.bg_03-02 {
  background: #00734E !important; }

@media screen and (min-width: 769px) {
  a.bg_03-02:not(:disabled):hover, a.bg_03-02:not(:disabled):focus,
  button.bg_03-02:not(:disabled):hover,
  button.bg_03-02:not(:disabled):focus {
    background: #006444 !important;
    cursor: pointer; } }

/* --- White --- */
.bg_white {
  background: #FFF !important; }

/* --- Facebook --- */
.bg_facebook {
  background: #3B5998 !important; }

@media screen and (min-width: 769px) {
  a.bg_facebook:not(:disabled):hover, a.bg_facebook:not(:disabled):focus,
  button.bg_facebook:not(:disabled):hover,
  button.bg_facebook:not(:disabled):focus {
    background: #2e4677 !important;
    cursor: pointer; } }

/* --- Twitter --- */
.bg_twitter {
  background: #4099FF !important; }

@media screen and (min-width: 769px) {
  a.bg_twitter:not(:disabled):hover, a.bg_twitter:not(:disabled):focus,
  button.bg_twitter:not(:disabled):hover,
  button.bg_twitter:not(:disabled):focus {
    background: #1280ff !important;
    cursor: pointer; } }

/* --- LinkedIn --- */
.bg_linkedin {
  background: #0077B5 !important; }

@media screen and (min-width: 769px) {
  a.bg_linkedin:not(:disabled):hover, a.bg_linkedin:not(:disabled):focus,
  button.bg_linkedin:not(:disabled):hover,
  button.bg_linkedin:not(:disabled):focus {
    background: #005987 !important;
    cursor: pointer; } }

/* --- Instagram --- */
.bg_instagram {
  background: #125688 !important; }

@media screen and (min-width: 769px) {
  a.bg_instagram:not(:disabled):hover, a.bg_instagram:not(:disabled):focus,
  button.bg_instagram:not(:disabled):hover,
  button.bg_instagram:not(:disabled):focus {
    background: #0d3c5f !important;
    cursor: pointer; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Lightest Gray --- */
.border_01-05 {
  border-color: #EFEFEF !important; }

@media screen and (min-width: 769px) {
  a.border_01-05:not(:disabled):hover, a.border_01-05:not(:disabled):focus,
  button.border_01-05:not(:disabled):hover,
  button.border_01-05:not(:disabled):focus {
    border-color: #0C0402 !important;
    cursor: pointer; } }

/* --- White --- */
.border_white {
  border-color: rgba(255, 255, 255, 0.12) !important; }

@media screen and (min-width: 769px) {
  a.border_white:not(:disabled):hover, a.border_white:not(:disabled):focus,
  button.border_white:not(:disabled):hover,
  button.border_white:not(:disabled):focus {
    border-color: #FFF !important;
    cursor: pointer; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FILLS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Black(ish) --- */
.fill_01-01 svg {
  fill: #0C0402 !important; }

/* --- Orange(ish) --- */
.fill_02-01 svg {
  fill: #E9423A !important; }

/* --- Green --- */
.fill_03-01 svg {
  fill: #008D61 !important; }

/* --- White --- */
.fill_white svg {
  fill: #FFF !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL SIZE -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_size, .input-text .label_in-field,
.input-datepicker .label_in-field, .gallery_item-btn, .modal-gallery_item-image, .hero-standard_bg, .hero-standard_bg img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL WIDTH ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_width, .lazyload_wrapper picture,
.lazyload_wrapper img {
  display: block;
  width: 100%;
  height: auto; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANIMATE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.animate, a.color_01-01,
button.color_01-01, a.color_01-03,
button.color_01-03, a.color_02-01,
button.color_02-01, a.color_03-01,
button.color_03-01, a.color_white,
button.color_white, a.bg_01-01,
button.bg_01-01, a.bg_01-03,
button.bg_01-03, a.bg_01-02,
button.bg_01-02, a.bg_01-05,
button.bg_01-05, a.bg_02-01,
button.bg_02-01, a.bg_03-01,
button.bg_03-01, a.bg_03-02,
button.bg_03-02, a.bg_facebook,
button.bg_facebook, a.bg_twitter,
button.bg_twitter, a.bg_linkedin,
button.bg_linkedin, a.bg_instagram,
button.bg_instagram, a.border_01-05,
button.border_01-05, a.border_white,
button.border_white, .lazyload_wrapper picture,
.lazyload_wrapper img, body:before, body:after, .nav-header_list-item_link, .nav-header_list-item_link-chevron:before, .nav-header_list-item_link-chevron svg, .nav-header_submenu-item_link, .nav-footer_sublist-item_link, .nav-footer_sublist-socials_item-link, .nav-side, .nav-side_list, .nav-side_list-item_link, .nav-side_trigger svg, .nav-socials_list-item_link, .btn-prev svg,
.btn-next svg, .fieldset-legend.js-accordion_trigger svg, .field-feedback, .badge-valid,
.badge-invalid, .input-text, .input-datepicker, .input-text input,
.input-text textarea,
.input-datepicker input,
.input-datepicker textarea, .input-text .label_in-field,
.input-datepicker .label_in-field, .input-datepicker .datepicker-clear svg, .field-select select, .field-select .chevron, .input-radio,
.input-checkbox, .input-radio input + span,
.input-checkbox input + span, .input-radio input + span:after,
.input-radio input + span svg,
.input-checkbox input + span:after,
.input-checkbox input + span svg, .input-radio input + span + label a,
.input-checkbox input + span + label a, .gallery_item-image, .modal-gallery_btn svg, .box-01_link, .panel, .panel-close svg, .panel-container, .list_item-thumb_image, .list_item-info_heading-link, .list_item-info_heading-link:before, .list_item-info_categories-list_item-link, .pagination-01_page .pagination-01_list-item_link, .pagination-01_prev .pagination-01_list-item_link,
.pagination-01_next .pagination-01_list-item_link, .hero.js-slick, .hero.js-slick .slick-dots li button, .hero-content_slide-content_link.bg_01-01, .hero-content_slide-content_link.bg_white, .hero-content_slide-content_info-item_value a, .hero-prev svg,
.hero-next svg, .product-info_categories-list_item-link, .product-share_link-back, .product-share_link-back svg, .form_modal-search .input-submit svg, .faqs-item_question svg, .social-feed_filters-list_wrapper, .social-feed_filters-list_item, .social-feed_item-image, .social-feed_item-text h3 a, .social-feed_item-text small a, .typography a:not(.btn):not(.link), .flash-message, .flash-message_close svg, .header:after, .header-top_block-link,
.header-block_bottom-link, .header-top_block-link svg,
.header-block_bottom-link svg, .box-office_link, .footer-copyright_link {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVENESS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (min-width: 769px) {
  .hide-desktop {
    display: none !important; } }

@media screen and (max-width: 768px) {
  .hide-mobile {
    display: none !important; } }

.visually_hidden {
  position: absolute;
  z-index: 1;
  margin: -1px;
  padding: 0;
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0); }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRID SYSTEM ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* Widths */
  /* Column */
  /* Gutter 96px */
  /* Gutter 64px */
  /* Gutter 48px */
  /* Gutter 24px */
  /* Gutter 12px */
  /* Gutter 6px */ }
  .grid.grid_reverse {
    flex-direction: row-reverse; }
  .grid.grid_valign-middle {
    align-items: center; }
  @media screen and (max-width: 768px) {
    .grid {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  .grid .width_01-12 {
    width: calc(1 / 12 * 100%); }
  .grid .width_02-12 {
    width: calc(2 / 12 * 100%); }
  .grid .width_03-12 {
    width: calc(3 / 12 * 100%); }
  .grid .width_04-12 {
    width: calc(4 / 12 * 100%); }
  .grid .width_05-12 {
    width: calc(5 / 12 * 100%); }
  .grid .width_06-12 {
    width: calc(6 / 12 * 100%); }
  .grid .width_07-12 {
    width: calc(7 / 12 * 100%); }
  .grid .width_08-12 {
    width: calc(8 / 12 * 100%); }
  .grid .width_09-12 {
    width: calc(9 / 12 * 100%); }
  .grid .width_10-12 {
    width: calc(10 / 12 * 100%); }
  .grid .width_11-12 {
    width: calc(11 / 12 * 100%); }
  .grid .width_12-12 {
    width: calc(12 / 12 * 100%); }
  .grid .column {
    box-sizing: border-box; }
    @media screen and (max-width: 768px) {
      .grid .column {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100%; } }
  .grid.gutter_96 {
    margin-left: -48px;
    margin-right: -48px; }
    .grid.gutter_96 > .column {
      padding-left: 48px;
      padding-right: 48px; }
  .grid.gutter_64 {
    margin-left: -32px;
    margin-right: -32px; }
    .grid.gutter_64 > .column {
      padding-left: 32px;
      padding-right: 32px; }
  .grid.gutter_48 {
    margin-left: -24px;
    margin-right: -24px; }
    .grid.gutter_48 > .column {
      padding-left: 24px;
      padding-right: 24px; }
  .grid.gutter_24 {
    margin-left: -12px;
    margin-right: -12px; }
    .grid.gutter_24 > .column {
      padding-left: 12px;
      padding-right: 12px; }
  .grid.gutter_12 {
    margin-left: -6px;
    margin-right: -6px; }
    .grid.gutter_12 > .column {
      padding-left: 6px;
      padding-right: 6px; }
  .grid.gutter_6 {
    margin-left: -3px;
    margin-right: -3px; }
    .grid.gutter_6 > .column {
      padding-left: 3px;
      padding-right: 3px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (RESET) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  line-height: 1.6;
  font-family: "Quicksand", sans-serif;
  font-size: 100%;
  border: 0 solid;
  border-radius: 0;
  appearance: none; }

[type=tel],
[type=text],
[type=email],
[type=password] {
  display: block;
  width: 100%;
  background: transparent; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  appearance: none;
  background: none;
  cursor: pointer; }

[type=submit] {
  cursor: pointer;
  overflow: visible; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

fieldset {
  margin: 0;
  padding: 0;
  border: none; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  display: block;
  width: 100%;
  background: transparent;
  resize: none;
  overflow: auto;
  box-sizing: border-box; }

[type="checkbox"],
[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  appearance: textfield; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

[hidden] {
  display: none; }

select::-ms-expand {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 500; }

h1, .h1 {
  line-height: 1.2;
  font-size: 64px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    h1, .h1 {
      font-size: 48px; } }
  @media screen and (max-width: 768px) {
    h1, .h1 {
      font-size: 32px; } }

h2, .h2 {
  line-height: 1.3;
  font-size: 48px; }
  @media screen and (max-width: 768px) {
    h2, .h2 {
      font-size: 24px; } }

h3, .h3 {
  line-height: 1.4;
  font-size: 32px; }
  @media screen and (max-width: 768px) {
    h3, .h3 {
      font-size: 24px; } }

h4, .h4 {
  line-height: 1.5;
  font-size: 24px; }
  @media screen and (max-width: 768px) {
    h4, .h4 {
      font-size: 18px; } }

h5, .h5 {
  line-height: 1.6;
  font-size: 16px; }

h6, .h6, .field-label,
.fieldset-legend, .typography th {
  line-height: 1.4;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HR LINES --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
hr {
  height: 0;
  overflow: visible;
  box-sizing: content-box; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANCHORS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
a {
  color: #0C0402;
  text-decoration: none;
  text-decoration-skip: objects;
  background-color: transparent; }
  a:active, a:hover {
    outline-width: 0; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT BOLD -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
b,
strong {
  font-weight: inherit;
  font-weight: bold; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT ITALIC ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
i,
em,
dfn {
  font-style: italic; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT HIGHLIGHTED ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
mark {
  color: #333;
  background: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT SMALL ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
small,
.small {
  font-size: 14px; }

sub,
sup {
  position: relative;
  line-height: 0;
  font-size: 75%;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IMAGES ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
figure {
  display: block;
  margin: 0; }
  figure figcaption {
    display: block; }

img {
  width: auto\9;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic; }
  img.align_left {
    float: left; }
  img.align_right {
    float: right; }

svg:not(:root) {
  overflow: hidden; }

/* FUNCTIONS */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ACCORDION -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-accordion .js-accordion_block .js-accordion_container {
  display: none; }

.js-accordion .js-accordion_block.open-disabled .js-accordion_container {
  display: block; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ACCORDION TRIGGER ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* MODAL WINDOW ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-modal_wrapper {
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.88);
  opacity: 0;
  visibility: hidden; }
  @media screen and (max-width: 768px) {
    .js-modal_wrapper:not(.modal_flash-message) {
      background: #FFF;
      overflow: auto; }
      .js-modal_wrapper:not(.modal_flash-message) .js-modal_content {
        position: static;
        top: 0;
        left: 0;
        padding: 0;
        width: auto;
        max-width: none;
        min-height: 0;
        background: none;
        overflow: auto;
        transform: none; } }
  .js-modal_wrapper.fixedHeight {
    overflow: auto; }
    .js-modal_wrapper.fixedHeight .js-modal_content {
      position: relative;
      top: auto;
      left: auto;
      margin: 24px auto;
      transform: none; }
      @media screen and (max-width: 768px) {
        .js-modal_wrapper.fixedHeight .js-modal_content {
          margin: 0; } }
  .js-modal_wrapper.open {
    opacity: 1;
    visibility: visible; }
  .js-modal_wrapper.modal_error:not(.modal_flash-message) .js-modal_content {
    border-top: 4px solid #D91E18; }
  .js-modal_wrapper.modal_alert:not(.modal_flash-message) .js-modal_content {
    border-top: 4px solid #F89406; }
  .js-modal_wrapper.modal_valid:not(.modal_flash-message) .js-modal_content {
    border-top: 4px solid #00B16A; }
  .js-modal_wrapper.modal_update:not(.modal_flash-message) .js-modal_content {
    border-top: 4px solid #1E8BC3; }

.js-modal_content {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  padding: 0 48px;
  width: 100%;
  box-sizing: border-box;
  transform: translate(-50%, -50%); }
  .js-modal_content.wXSmall {
    max-width: calc(440px + 96px); }

/*
	.js-modal_trigger {
		display:inline-block;
	}
	*/
/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-modal_trigger_no-js {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important; }

.no-js .js-modal_wrapper {
  z-index: 1; }
  .no-js .js-modal_wrapper:target {
    z-index: 99999;
    opacity: 1;
    visibility: visible; }

.no-js .js-modal_trigger_no-js {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important; }
  .no-js .js-modal_trigger_no-js + a {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important; }

/* PLUGINS */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAZYLOAD --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.lazyload_wrapper picture,
.lazyload_wrapper img {
  opacity: 0; }
  .lazyload_wrapper picture.lazyloaded,
  .lazyload_wrapper img.lazyloaded {
    opacity: 1; }

.lazyload_wrapper picture.lazyloaded img {
  opacity: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.no-js .lazyload_wrapper picture,
.no-js .lazyload_wrapper img {
  opacity: 1; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* COMPONENTS */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* AJAX LOADER ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
body:before, body:after {
  content: "";
  position: fixed;
  opacity: 0;
  visibility: hidden; }

body:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99998;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.88); }

body:after {
  top: 50%;
  left: 50%;
  z-index: 99999;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
  border: 2px solid #FFF;
  border-top: 2px solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spin 600ms linear infinite; }

body.is-ajax-loading:before, body.is-ajax-loading:after {
  opacity: 1;
  visibility: visible; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo,
.maddermarket-theatre_logo {
  display: block;
  width: auto;
  height: 96px; }
  @media screen and (max-width: 768px) {
    .logo,
    .maddermarket-theatre_logo {
      margin: 0 12px;
      height: 48px; } }

.maddermarket-theatre_logo {
  padding: 6px;
  border: 2px solid #E9423A;
  box-sizing: border-box; }

.maddermarket-theatre_logo-madder,
.maddermarket-theatre_logo-market {
  fill: #E9423A; }

_:-ms-fullscreen, :root .logo,
_:-ms-fullscreen, :root .maddermarket-theatre_logo {
  width: 110px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER LOGO ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo-header .maddermarket-theatre_theatre {
  fill: #0C0402; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER LOGO ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo-footer .maddermarket-theatre_theatre {
  fill: #FFF; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER NAVIGATIONS ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-header {
  justify-content: center; }

.nav-header_list {
  list-style: none; }

.nav-header_list-item.submenu-is-open {
  z-index: 10; }
  @media screen and (min-width: 769px) {
    .nav-header_list-item.submenu-is-open .nav-header_list-item_link-chevron:before {
      opacity: 1; }
    .nav-header_list-item.submenu-is-open .nav-header_list-item_link-chevron svg {
      fill: #E9423A;
      transform: rotate(270deg); } }
  .nav-header_list-item.submenu-is-open .nav-header_submenu {
    opacity: 1;
    visibility: visible; }

.nav-header_list-item_link {
  align-items: center;
  color: #0C0402; }
  @media screen and (max-width: 768px) {
    .nav-header_list-item_link {
      color: #FFF; } }
  @media screen and (min-width: 769px) {
    .nav-header_list-item_link {
      /*
					&:focus {

						svg {
							transform:rotate(270deg);
						}

						& + .nav-header_submenu {
							opacity:1;
							visibility:visible;
						}
					}
					*/ }
      .nav-header_list-item_link:not(.is-current):hover, .nav-header_list-item_link:not(.is-current):focus {
        color: #E9423A; } }
  .nav-header_list-item_link.is-current {
    color: #E9423A;
    cursor: default; }
  @media screen and (max-width: 768px) {
    .nav-header_list-item_link.is-submenu-close svg {
      transform: rotate(90deg); } }

.nav-header_list-item_link-label {
  position: relative;
  z-index: 2;
  line-height: 1.2; }

.nav-header_list-item_link-chevron {
  display: block;
  margin-top: 2px;
  cursor: pointer; }
  .nav-header_list-item_link-chevron:before {
    content: "";
    top: -50%;
    left: -50%;
    background: #EFEFEF;
    border-radius: 100%;
    opacity: 0; }
  .nav-header_list-item_link-chevron svg {
    display: block;
    position: relative;
    z-index: 2;
    fill: #0C0402;
    transform: rotate(90deg); }
    @media screen and (max-width: 768px) {
      .nav-header_list-item_link-chevron svg {
        fill: #FFF;
        transform: rotate(270deg); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER NAVIGATIONS (SUBMENU) ------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-header_submenu {
  list-style: none; }
  @media screen and (min-width: 769px) {
    .nav-header_submenu {
      position: absolute;
      left: auto;
      z-index: 2;
      margin-left: -24px;
      padding: 20px 24px;
      min-width: 200px;
      max-width: 300px;
      background: #2D2D2D;
      opacity: 0;
      visibility: hidden;
      transition-duration: 300ms;
      transition-timing-function: ease-in-out; }
      .nav-header_submenu:after {
        content: "";
        position: absolute;
        top: -24px;
        left: 0;
        right: 0;
        height: 24px;
        background: transparent; } }
  @media screen and (max-width: 768px) {
    .nav-header_submenu {
      padding-top: 0; }
      .nav-header_submenu > li:first-child {
        padding-top: 9px; } }
  @media screen and (min-width: 769px) {
    .nav-header_submenu:before {
      content: "";
      top: -6px;
      left: 18px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #2D2D2D; } }

.nav-header_submenu-item:not(:first-child) {
  padding-top: 12px; }
  @media screen and (max-width: 768px) {
    .nav-header_submenu-item:not(:first-child) {
      padding-top: 6px; } }

.nav-header_submenu-item_link {
  display: block;
  line-height: 1.3;
  color: #FFF; }
  @media screen and (min-width: 769px) {
    .nav-header_submenu-item_link:not(.is-current):hover, .nav-header_submenu-item_link:not(.is-current):focus {
      color: #E9423A; } }
  @media screen and (max-width: 768px) {
    .nav-header_submenu-item_link {
      color: rgba(255, 255, 255, 0.48); } }
  .nav-header_submenu-item_link.is-current {
    color: #E9423A;
    cursor: default; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (PRIMARY) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (min-width: 941px) {
  .nav-primary {
    padding-left: 64px; } }

@media screen and (max-width: 940px) and (min-width: 769px) {
  .nav-primary {
    padding-left: 48px; } }

@media screen and (max-width: 768px) {
  .nav-primary {
    flex-direction: row;
    justify-content: flex-start; } }

@media screen and (max-width: 1110px) {
  .nav-primary_list {
    flex-direction: column;
    width: 100%; } }

@media screen and (min-width: 1111px) {
  .nav-primary_list-item:not(:first-child) {
    padding-left: 32px; } }

@media screen and (max-width: 768px) {
  .nav-primary_list-item:not(:first-child) {
    padding-top: 18px; } }

@media screen and (max-width: 768px) {
  .nav-primary_list-item:last-child {
    margin-bottom: -9px; } }

.nav-primary_list-item_link {
  font-size: 24px;
  font-weight: 700; }
  @media screen and (max-width: 1110px) {
    .nav-primary_list-item_link {
      justify-content: space-between; } }
  @media screen and (max-width: 940px) and (min-width: 769px) {
    .nav-primary_list-item_link {
      font-size: 16px; } }

.nav-primary_list-item_link-label {
  padding-right: 16px; }

.nav-primary_list-item_link-chevron {
  width: 12px;
  height: 12px; }
  .nav-primary_list-item_link-chevron:before {
    margin-top: -20px;
    margin-left: -20px;
    width: 64px;
    height: 64px; }
  .nav-primary_list-item_link-chevron svg {
    width: 12px;
    height: 12px; }

@media screen and (min-width: 769px) {
  .nav-primary_submenu {
    top: 50px; } }

@media screen and (max-width: 768px) {
  .nav-primary_submenu {
    padding-bottom: 0; }
    .nav-primary_submenu > li:last-child {
      padding-bottom: 9px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (SECONDARY) ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-secondary {
  width: 100%; }
  @media screen and (min-width: 1221px) {
    .nav-secondary {
      padding-right: 16px;
      max-width: calc(45% - 16px); } }
  @media screen and (max-width: 1110px) and (min-width: 769px) {
    .nav-secondary {
      width: auto; } }
  @media screen and (max-width: 768px) {
    .nav-secondary {
      padding-top: 36px; } }

@media screen and (max-width: 1220px) and (min-width: 769px) {
  .nav-secondary_list {
    flex-direction: row; } }

@media screen and (max-width: 940px) and (min-width: 769px) {
  .nav-secondary_list {
    flex-wrap: wrap; } }

@media screen and (max-width: 940px) and (min-width: 769px) {
  .nav-secondary_list-item:first-child {
    width: 100%; } }

@media screen and (max-width: 768px) {
  .nav-secondary_list-item:not(:first-child) {
    padding-top: 12px; } }

.nav-secondary_list-item:not(:last-child) {
  padding-right: 24px; }

.nav-secondary_list-item_link-label {
  padding-right: 8px; }

.nav-secondary_list-item_link-chevron {
  width: 8px;
  height: 8px; }
  .nav-secondary_list-item_link-chevron:before {
    margin-top: -8px;
    margin-left: -8px;
    width: 32px;
    height: 32px; }
  .nav-secondary_list-item_link-chevron svg {
    width: 8px;
    height: 8px; }

@media screen and (min-width: 769px) {
  .nav-secondary_submenu {
    top: 36px; } }

@media screen and (max-width: 768px) {
  .nav-secondary_submenu > li:last-child {
    padding-bottom: 12px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (MOBILE) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-header-mobile {
  display: none;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  margin: -6px -6px -6px 6px; }
  @media screen and (max-width: 768px) {
    .nav-header-mobile {
      display: flex; } }

.nav-header-mobile_list {
  flex: 1 1 auto;
  list-style: none; }

.nav-header-mobile_list-item {
  flex: 1 1 auto;
  border-left: 1px solid #EFEFEF; }

.nav-header-mobile_list-item_link {
  justify-content: center;
  align-items: center;
  flex: 1 1 auto; }
  .nav-header-mobile_list-item_link svg {
    display: block;
    width: 18px;
    height: 18px;
    fill: #0C0402; }
  .nav-header-mobile_list-item_link.is-current svg {
    fill: #008D61; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (FOOTER) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-footer_list {
  margin-top: -48px;
  list-style: none; }
  @media screen and (max-width: 768px) {
    .nav-footer_list {
      margin-top: -18px; } }

.nav-footer_list-item {
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .nav-footer_list-item {
      padding-top: 18px; } }

.nav-footer_list-item_heading {
  color: #FFF;
  font-size: 24px;
  font-weight: 700; }
  @media screen and (max-width: 768px) {
    .nav-footer_list-item_heading {
      font-size: 18px; } }

.nav-footer_sublist {
  padding-top: 12px;
  list-style: none; }

.nav-footer_sublist-item_link {
  color: rgba(255, 255, 255, 0.48); }
  @media screen and (min-width: 769px) {
    .nav-footer_sublist-item_link:not(.is-current):hover, .nav-footer_sublist-item_link:not(.is-current):focus {
      color: #FFF; } }
  .nav-footer_sublist-item_link.is-current {
    color: #FFF;
    cursor: default; }

.nav-footer_sublist-socials {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 6px;
  list-style: none; }

.nav-footer_sublist-socials_item {
  padding-top: 6px; }
  .nav-footer_sublist-socials_item:not(:last-child) {
    padding-right: 6px; }

.nav-footer_sublist-socials_item-link {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.24);
  border-radius: 100%; }
  .nav-footer_sublist-socials_item-link svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: #2D2D2D; }
  @media screen and (min-width: 769px) {
    .nav-footer_sublist-socials_item-link:hover, .nav-footer_sublist-socials_item-link:focus {
      background: #FFF; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (SIDE) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .nav-side {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    background: rgba(255, 255, 255, 0.48);
    opacity: 0;
    visibility: hidden; }
    .nav-side.is-active {
      opacity: 1;
      visibility: visible; }
      .nav-side.is-active .nav-side_list {
        transform: translateX(0); } }

.nav-side_list {
  list-style: none; }
  @media screen and (max-width: 768px) {
    .nav-side_list {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      padding: 36px 48px;
      width: calc(100% - 60px);
      height: 100%;
      color: #FFF;
      background: #2D2D2D;
      box-sizing: border-box;
      overflow-y: auto;
      transform: translateX(12px); } }

.nav-side_list-item:not(:first-child) {
  padding-top: 3px; }
  @media screen and (max-width: 768px) {
    .nav-side_list-item:not(:first-child) {
      padding-top: 12px; } }

@media screen and (min-width: 769px) {
  .nav-side_list-item:not(.current-menu-item) .nav-side_list-item_link:hover,
  .nav-side_list-item:not(.current-menu-item) .nav-side_list-item_link:focus {
    color: #008D61; } }

.nav-side_list-item.current-menu-item .nav-side_list-item_link {
  color: #008D61;
  cursor: default; }

.nav-side_list-item_link {
  color: #0C0402;
  font-weight: 500; }
  @media screen and (max-width: 768px) {
    .nav-side_list-item_link {
      color: #FFF;
      font-size: 18px; } }

.nav-side_trigger {
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  padding: 16px 24px;
  width: 100%;
  color: #0C0402;
  font-weight: 500;
  background: #FFF !important; }
  .nav-side_trigger:after {
    content: "";
    left: 0;
    right: 0;
    top: -6px;
    height: 6px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.06) 100%); }
  .nav-side_trigger svg {
    width: 12px;
    height: 12px;
    fill: #0C0402; }

.nav-side.is-affixed:not(.nav-side_border) .inner-wrapper-sticky {
  z-index: 10; }
  .nav-side.is-affixed:not(.nav-side_border) .inner-wrapper-sticky:before {
    content: "";
    top: -24px;
    left: -24px;
    right: -24px;
    bottom: -24px;
    background: #FFF;
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF; }
  .nav-side.is-affixed:not(.nav-side_border) .inner-wrapper-sticky .nav-side_list {
    position: relative;
    z-index: 2; }

@media screen and (min-width: 769px) {
  .nav-side_border .inner-wrapper-sticky .nav-side_list {
    margin-top: 12px;
    padding: 24px;
    background: #FFF;
    border: 2px solid #EFEFEF; } }

.nav-side_border.is-affixed .inner-wrapper-sticky {
  z-index: 10; }
  .nav-side_border.is-affixed .inner-wrapper-sticky .nav-side_list {
    position: relative;
    z-index: 2; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (SOCIALS) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .nav-socials {
    padding-top: 12px; } }

.nav-socials_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none; }

.nav-socials_list-item:not(:last-child) {
  padding-right: 12px; }

.nav-socials_list-item_link {
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 100%; }
  .nav-socials_list-item_link svg {
    display: block;
    width: 18px;
    height: 18px;
    fill: #FFF; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn {
  transition: color 300ms ease-in-out, background 300ms ease-in-out, border 300ms ease-in-out;
  display: inline-block;
  text-align: left;
  box-sizing: border-box; }
  .btn.btn_has-icon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center; }
    .btn.btn_has-icon span {
      padding-left: 12px; }
      @media screen and (max-width: 768px) {
        .btn.btn_has-icon span {
          padding-left: 9px; } }
      .btn.btn_has-icon span:first-child {
        padding-left: 0;
        padding-right: 12px; }
        @media screen and (max-width: 768px) {
          .btn.btn_has-icon span:first-child {
            padding-right: 9px; } }
    .btn.btn_has-icon svg {
      display: block;
      flex-shrink: 0;
      width: 18px;
      height: 18px; }
      @media screen and (max-width: 768px) {
        .btn.btn_has-icon svg {
          width: 12px;
          height: 12px; } }
    @media screen and (min-width: 769px) {
      .btn.btn_has-icon.icon-close svg {
        width: 14px;
        height: 14px; } }
  .btn.icon-minus-90-deg svg {
    transform: rotate(-90deg); }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 1) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-01 {
  padding: 16px 24px;
  font-weight: 500; }
  @media screen and (max-width: 768px) {
    .btn-01 {
      padding: 12px 18px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 2) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-02 {
  padding: 15px 24px;
  font-weight: 500;
  border: 1px solid rgba(12, 4, 2, 0.12); }
  @media screen and (min-width: 769px) {
    .btn-02:hover, .btn-02:focus {
      border-color: #0C0402;
      cursor: pointer; } }
  @media screen and (max-width: 768px) {
    .btn-02 {
      padding: 11px 18px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (PREV / NEXT) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-prev,
.btn-next {
  width: 48px;
  height: 48px; }
  @media screen and (min-width: 769px) {
    .btn-prev,
    .btn-next {
      top: 50%;
      transform: translateY(-50%); } }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .btn-prev,
    .btn-next {
      width: 32px;
      height: 32px; } }
  @media screen and (max-width: 768px) {
    .btn-prev,
    .btn-next {
      bottom: 36px;
      width: 24px;
      height: 24px; } }
  .btn-prev.fill_white svg,
  .btn-next.fill_white svg {
    opacity: .24; }
  .btn-prev svg,
  .btn-next svg {
    display: block;
    width: 48px;
    height: 48px;
    fill: #0C0402; }
    @media screen and (max-width: 1220px) and (min-width: 769px) {
      .btn-prev svg,
      .btn-next svg {
        width: 32px;
        height: 32px; } }
    @media screen and (max-width: 768px) {
      .btn-prev svg,
      .btn-next svg {
        width: 24px;
        height: 24px; } }
  @media screen and (min-width: 769px) {
    .btn-prev:hover svg,
    .btn-prev:focus svg,
    .btn-next:hover svg,
    .btn-next:focus svg {
      fill: #E9423A;
      opacity: 1; } }

.btn-prev {
  left: -96px; }
  .btn-prev svg {
    transform: rotate(180deg); }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .btn-prev {
      left: -48px; } }
  @media screen and (max-width: 768px) {
    .btn-prev {
      left: -24px; } }

.btn-next {
  right: -96px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .btn-next {
      right: -48px; } }
  @media screen and (max-width: 768px) {
    .btn-next {
      right: -24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPE 1) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading-01 {
  font-weight: 300; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form-01:not(:first-child) {
  margin-top: 96px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .form-01:not(:first-child) {
      margin-top: 48px; } }
  @media screen and (max-width: 768px) {
    .form-01:not(:first-child) {
      margin: 24px -24px 0; } }

.form-01 .form-01_intro {
  width: 100%; }

.form-01 .form-01_content {
  margin-top: -24px;
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .form-01 .form-01_content {
      margin-top: -18px;
      padding-top: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD / FIELDSET ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field,
.fieldset {
  padding-top: 24px; }
  @media screen and (max-width: 768px) {
    .field,
    .fieldset {
      padding-top: 18px; } }

.fieldset.js-accordion {
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .fieldset.js-accordion {
      padding-top: 24px; } }
  .fieldset.js-accordion .js-accordion_block.open .js-accordion_trigger svg {
    transform: rotate(270deg); }
  .fieldset.js-accordion .js-accordion_content {
    margin-top: -6px;
    padding-top: 24px; }

.field-label,
.fieldset-legend {
  padding-bottom: 6px; }
  .field-label em,
  .fieldset-legend em {
    margin-left: auto;
    padding-left: 24px;
    color: #666;
    font-weight: 400;
    text-transform: none; }

.fieldset-legend.js-accordion_trigger {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #EFEFEF; }
  .fieldset-legend.js-accordion_trigger span {
    padding-right: 12px; }
  .fieldset-legend.js-accordion_trigger svg {
    display: block;
    width: 12px;
    height: 12px;
    fill: #0C0402;
    transform: rotate(90deg); }

.fieldset .field {
  padding-top: 6px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD FEEDBACK --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field-feedback {
  max-height: 0;
  overflow: hidden;
  opacity: 0; }
  .field-feedback span {
    display: block;
    padding-top: 12px;
    line-height: 1.4;
    font-size: 14px;
    color: #D91E18; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD BADGES ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.badge-valid,
.badge-invalid {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  opacity: 0; }
  .badge-valid svg,
  .badge-invalid svg {
    display: block;
    width: 12px;
    height: 12px;
    fill: #FFF; }

.badge-valid {
  background: #00B16A; }

.badge-invalid {
  background: #D91E18; }

.input-radio .badge-valid,
.input-radio .badge-invalid,
.input-checkbox .badge-valid,
.input-checkbox .badge-invalid {
  top: 50%;
  left: 20px;
  right: auto;
  margin-top: -12px;
  width: 8px;
  height: 8px;
  transform: translateY(-4px); }
  .input-radio .badge-valid svg,
  .input-radio .badge-invalid svg,
  .input-checkbox .badge-valid svg,
  .input-checkbox .badge-invalid svg {
    display: none; }

/* --- Valid --- */
.is-valid .field-feedback,
.is-valid .badge-invalid {
  opacity: 0; }

.is-valid .badge-valid {
  opacity: 1; }

/* --- Invalid --- */
.is-invalid .badge-valid {
  opacity: 0; }

.is-invalid .field-feedback,
.is-invalid .badge-invalid {
  opacity: 1; }

.is-invalid .field-feedback {
  max-height: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT TEXT / TEXTAREA -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-text input,
.input-text textarea,
.input-datepicker input,
.input-datepicker textarea {
  margin: 0;
  padding: 16px 24px;
  width: 100%;
  height: auto;
  line-height: 1.4;
  color: #666;
  font-family: "Quicksand", sans-serif;
  font-size: 100%;
  font-size: 16px;
  box-sizing: border-box;
  background: #FFF;
  border: 1px solid rgba(12, 4, 2, 0.12);
  border-radius: 0;
  appearance: none; }

.input-text input:not([value=""]) + .label_in-field,
.input-text input:focus + .label_in-field,
.input-datepicker input:not([value=""]) + .label_in-field,
.input-datepicker input:focus + .label_in-field {
  z-index: 1;
  padding: 6px 24px;
  font-size: 8px;
  opacity: .48; }

@media screen and (min-width: 769px) {
  .input-text input:not(:disabled):hover, .input-text input:not(:disabled):focus,
  .input-datepicker input:not(:disabled):hover,
  .input-datepicker input:not(:disabled):focus {
    position: relative;
    z-index: 2;
    border: 1px solid rgba(12, 4, 2, 0.24); } }

.input-text input:disabled,
.input-datepicker input:disabled {
  color: rgba(102, 102, 102, 0.24);
  cursor: default; }
  .input-text input:disabled + svg,
  .input-datepicker input:disabled + svg {
    opacity: .24; }

.input-text .label_in-field,
.input-datepicker .label_in-field {
  padding: 16px 24px;
  line-height: 1.4;
  color: #0C0402;
  font-size: 16px;
  box-sizing: border-box;
  cursor: pointer; }

.input-datepicker .datepicker-clear {
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  background: #FFF;
  border: 1px solid rgba(12, 4, 2, 0.12);
  border-left: none; }
  .input-datepicker .datepicker-clear svg {
    display: block;
    width: 12px;
    height: 12px;
    fill: #0C0402; }

.input-datepicker input:disabled + .datepicker-clear {
  cursor: default; }
  .input-datepicker input:disabled + .datepicker-clear svg {
    opacity: .24; }

@media screen and (min-width: 769px) {
  .input-datepicker input:not(:disabled) + .datepicker-clear:hover svg,
  .input-datepicker input:not(:disabled) + .datepicker-clear:focus svg {
    fill: #E9423A; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field-select select {
  margin: 0;
  padding: 16px 24px;
  width: 100%;
  height: auto;
  line-height: 1.4;
  color: #666;
  font-size: 100%;
  font-size: 16px;
  text-transform: none;
  box-sizing: border-box;
  background: #FFF;
  border: 1px solid rgba(12, 4, 2, 0.12);
  border-radius: 0;
  cursor: pointer;
  appearance: none; }
  @media screen and (min-width: 769px) {
    .field-select select:not(:disabled):hover, .field-select select:not(:disabled):focus {
      border: 1px solid rgba(12, 4, 2, 0.24); }
    .field-select select:not(:disabled):focus + .chevron {
      transform: rotate(270deg); } }
  .field-select select:disabled {
    color: rgba(102, 102, 102, 0.24);
    cursor: default; }
    .field-select select:disabled + .chevron {
      opacity: .24; }

.field-select .chevron {
  position: absolute;
  top: 50%;
  right: 24px;
  z-index: 2;
  margin-top: -6px;
  width: 12px;
  height: 12px;
  transform: rotate(90deg); }
  .field-select .chevron svg {
    display: block;
    width: 12px;
    height: 12px;
    fill: #0C0402; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT RADIO / CHECKBOX ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-radio,
.input-checkbox {
  align-items: center; }
  .input-radio input,
  .input-checkbox input {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 1;
    color: #0C0402;
    font-size: 100%;
    font-size: 16px;
    box-sizing: border-box;
    background: none;
    border: 0 solid;
    border-radius: 0;
    opacity: 0;
    cursor: pointer;
    appearance: none;
    transform: translateY(-50%); }
    .input-radio input:checked:not(:disabled) + span:after,
    .input-radio input:checked:not(:disabled) + span svg,
    .input-radio input:checked:not(:disabled) + span:after,
    .input-radio input:checked:not(:disabled) + span svg,
    .input-checkbox input:checked:not(:disabled) + span:after,
    .input-checkbox input:checked:not(:disabled) + span svg,
    .input-checkbox input:checked:not(:disabled) + span:after,
    .input-checkbox input:checked:not(:disabled) + span svg {
      opacity: 1; }
    @media screen and (min-width: 769px) {
      .input-radio input:not(:disabled):hover + span,
      .input-radio input:not(:disabled):focus + span,
      .input-checkbox input:not(:disabled):hover + span,
      .input-checkbox input:not(:disabled):focus + span {
        border-color: rgba(12, 4, 2, 0.24); } }
    .input-radio input:disabled,
    .input-checkbox input:disabled {
      cursor: default; }
      .input-radio input:disabled + span + label,
      .input-checkbox input:disabled + span + label {
        opacity: .24;
        cursor: default; }
  .input-radio input + span,
  .input-checkbox input + span {
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #FFF;
    border: 1px solid rgba(12, 4, 2, 0.12);
    box-sizing: border-box; }
    .input-radio input + span:after,
    .input-radio input + span svg,
    .input-checkbox input + span:after,
    .input-checkbox input + span svg {
      display: block;
      opacity: 0; }
  .input-radio input + span + label,
  .input-checkbox input + span + label {
    flex: 1 1 auto;
    flex-direction: column;
    margin: 0;
    padding: 0 0 0 12px;
    line-height: 1.2;
    cursor: pointer; }
    .input-radio input + span + label a,
    .input-checkbox input + span + label a {
      color: #0C0402;
      text-decoration: underline; }
      @media screen and (min-width: 769px) {
        .input-radio input + span + label a:hover, .input-radio input + span + label a:focus,
        .input-checkbox input + span + label a:hover,
        .input-checkbox input + span + label a:focus {
          color: #E9423A; } }
  .input-radio + .field-feedback:before,
  .input-checkbox + .field-feedback:before {
    left: 6px; }

.input-radio input + span {
  border-radius: 100%; }
  .input-radio input + span:after {
    content: "";
    width: 8px;
    height: 8px;
    background: #008D61;
    border-radius: 100%; }

.input-checkbox input + span svg {
  width: 12px;
  height: 12px;
  fill: #008D61; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT SUBMIT ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-submit {
  align-items: center;
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .input-submit {
      padding-top: 24px; } }
  .input-submit button {
    min-width: 200px;
    text-align: center; }

/* --------------------------------------------------------------------------------------------------- */
/* SLIDER -------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.js-slick:not(.hero-content) {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap; }
  .js-slick:not(.hero-content) .slick-list,
  .js-slick:not(.hero-content) .slick-list .slick-track,
  .js-slick:not(.hero-content) .slick-list .slick-track .slick-slide,
  .js-slick:not(.hero-content) .slick-list .slick-track .slick-slide > div {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1 1 auto; }
  .js-slick:not(.hero-content).has-adaptiveHeight .slick-list,
  .js-slick:not(.hero-content).has-adaptiveHeight .slick-list .slick-track,
  .js-slick:not(.hero-content).has-adaptiveHeight .slick-list .slick-track .slick-slide,
  .js-slick:not(.hero-content).has-adaptiveHeight .slick-list .slick-track .slick-slide > div {
    align-items: flex-start; }

/* --------------------------------------------------------------------------------------------------- */
/* GALLERY ------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.gallery {
  padding-top: 12px; }
  @media screen and (max-width: 768px) {
    .gallery {
      margin-left: -3px !important;
      margin-right: -3px !important; }
      .gallery > .column {
        padding-left: 3px !important;
        padding-right: 3px !important;
        width: 50%; } }

.gallery_item {
  display: block !important;
  margin-top: 12px;
  background: #0C0402;
  overflow: hidden; }
  @media screen and (min-width: 769px) {
    .gallery_item:not(.js-slick_slide):hover .gallery_item-image,
    .gallery_item:not(.js-slick_slide):focus .gallery_item-image {
      opacity: .32;
      transform: scale(1.05); } }
  @media screen and (max-width: 768px) {
    .gallery_item {
      margin-top: 6px; } }

.gallery_item-btn {
  display: block;
  width: 100%;
  height: 100%; }

.gallery_item-image {
  display: block;
  width: 100%;
  height: auto; }
  .gallery_item-image:before {
    content: "(url: " attr(src) ")"; }

/* --------------------------------------------------------------------------------------------------- */
/* GALLERY (INLINE) ---------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.gallery-inline {
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .gallery-inline {
      padding-top: 18px; } }

.gallery-inline_current .gallery_item {
  margin: 0; }

.gallery-inline_thumbnails {
  margin: 6px -3px 0;
  width: calc(100% + 6px); }
  .gallery-inline_thumbnails .gallery_item-wrapper {
    padding: 0 3px; }
  .gallery-inline_thumbnails .gallery_item {
    margin: 0;
    cursor: pointer; }
  .gallery-inline_thumbnails .slick-current .gallery_item-image {
    opacity: .32;
    transform: scale(1.05); }

/* --------------------------------------------------------------------------------------------------- */
/* GALLERY (MODAL) ----------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .modal-gallery_wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0 12px;
    background: rgba(255, 255, 255, 0.88) !important; } }

.modal-gallery_content,
.modal-gallery {
  height: calc(100vh - 144px); }
  @media screen and (max-width: 768px) {
    .modal-gallery_content,
    .modal-gallery {
      width: calc(100vw - 24px);
      height: calc(100vh - 96px); } }

.modal-gallery_item {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto; }

.modal-gallery_item-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: 'object-fit:contain;'; }

.modal-gallery_controls {
  position: fixed;
  top: 24px;
  right: 24px; }
  @media screen and (max-width: 768px) {
    .modal-gallery_controls {
      top: auto;
      left: 50%;
      right: auto;
      bottom: 8px;
      transform: translateX(-50%); } }

.modal-gallery_btn {
  width: 24px;
  height: 24px; }
  @media screen and (max-width: 768px) {
    .modal-gallery_btn {
      display: flex !important;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      margin: 0 2px;
      width: 32px;
      height: 32px;
      background: #0C0402 !important;
      border-radius: 3px; } }
  @media screen and (min-width: 769px) {
    .modal-gallery_btn:not(:last-child) {
      margin-right: 12px; } }
  .modal-gallery_btn svg {
    display: block;
    width: 24px;
    height: 24px;
    fill: #FFF; }
    @media screen and (max-width: 768px) {
      .modal-gallery_btn svg {
        width: 16px;
        height: 16px; } }
  @media screen and (min-width: 769px) {
    .modal-gallery_btn:hover svg,
    .modal-gallery_btn:focus svg {
      fill: #E9423A; } }

.modal-gallery_btn-prev svg {
  transform: rotate(180deg); }

/* --------------------------------------------------------------------------------------------------- */
/* VIDEO --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.wrapper_video {
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }
  .wrapper_video video,
  .wrapper_video iframe {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%; }
  .wrapper_video:not(:first-child) {
    margin-top: 24px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX -------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box-content {
  width: 100%; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 1) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box-01 {
  align-items: center;
  flex: 1 1 auto; }

.box-01_link-wrapper {
  padding-top: 48px;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .box-01_link-wrapper {
      padding-top: 24px; } }

.box-01_link {
  color: #0C0402;
  text-decoration: none;
  border-bottom: 1px solid #0C0402; }
  @media screen and (min-width: 769px) {
    .box-01_link:hover, .box-01_link:focus {
      color: #E9423A;
      border-bottom: 1px solid transparent; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 2) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box-02 {
  padding: 32px 0;
  border-top: 2px solid #EFEFEF;
  border-bottom: 2px solid #EFEFEF; }
  @media screen and (max-width: 768px) {
    .box-02 {
      padding: 24px 0; } }
  @media screen and (max-width: 768px) {
    .box-02 {
      flex-direction: column; } }

.box-02_heading {
  padding-right: 48px;
  min-width: 20%;
  font-size: 24px;
  box-sizing: border-box; }

/* --------------------------------------------------------------------------------------------------- */
/* PANEL --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background: rgba(12, 4, 2, 0.88);
  opacity: 0;
  visibility: hidden; }
  @media screen and (max-width: 768px) {
    .panel {
      background: rgba(255, 255, 255, 0.48); } }
  .panel.is-active {
    opacity: 1;
    visibility: visible; }
    .panel.is-active .panel-container {
      transform: translateX(0); }

.panel-close {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 60px;
  height: 60px;
  background: #FFF !important;
  border: none; }
  .panel-close svg {
    display: block;
    width: 18px;
    height: 18px;
    fill: #0C0402; }

.panel-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 48px;
  width: 100%;
  height: 100%;
  background: #FFF;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translateX(12px); }
  @media screen and (min-width: 769px) {
    .panel-container {
      max-width: 440px; } }
  @media screen and (max-width: 768px) {
    .panel-container {
      padding: 36px 48px;
      width: calc(100% - 60px);
      color: #FFF;
      background: #2D2D2D; } }
  .panel-container.slim {
    bottom: auto;
    padding: 0;
    height: 60px; }

.panel-container_info {
  margin-top: 36px;
  padding-top: 36px;
  border-top: 1px solid rgba(255, 255, 255, 0.12); }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list + .pagination {
  padding: 96px 0 0; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .list + .pagination {
      padding: 48px 0 0; } }
  @media screen and (max-width: 768px) {
    .list + .pagination {
      padding: 24px 0 0; } }

.list_item-thumb {
  display: block;
  background: #0C0402;
  overflow: hidden; }
  .list_item-thumb:before {
    content: "";
    display: block;
    padding-top: 56.25%;
    width: 100%;
    height: 0;
    overflow: hidden; }
  @media screen and (min-width: 769px) {
    .list_item-thumb:hover .list_item-thumb_image,
    .list_item-thumb:focus .list_item-thumb_image {
      opacity: .32;
      transform: scale(1.05); } }

.list_item-thumb_image {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2 !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit:cover;'; }

.list_item-info_heading {
  margin-top: 6px; }

.list_item-info_heading-link {
  display: inline-block;
  color: #0C0402; }
  @media screen and (min-width: 769px) {
    .list_item-info_heading-link {
      padding-left: 24px; } }
  .list_item-info_heading-link:before {
    content: ""; }
    @media screen and (min-width: 769px) {
      .list_item-info_heading-link:before {
        top: 6px;
        left: 0;
        bottom: 6px;
        width: 2px;
        background: #0C0402; } }
  @media screen and (min-width: 769px) {
    .list_item-info_heading-link:hover, .list_item-info_heading-link:focus {
      color: #E9423A; }
      .list_item-info_heading-link:hover:before, .list_item-info_heading-link:focus:before {
        background: #E9423A; } }

.list_item-info_categories,
.list_item-info_categories-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #666; }

.list_item-info_categories-list {
  list-style: none; }

.list_item-info_categories-list_item:not(:last-child) {
  padding-right: 4px; }
  .list_item-info_categories-list_item:not(:last-child):after {
    content: ","; }

.list_item-info_categories-list_item-link {
  color: #666; }
  @media screen and (min-width: 769px) {
    .list_item-info_categories-list_item-link:hover, .list_item-info_categories-list_item-link:focus {
      color: #E9423A; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list-01 {
  margin-top: -24px;
  padding-top: 48px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .list-01.grid {
      margin-left: -6px;
      margin-right: -6px; }
      .list-01.grid > .column {
        padding-left: 6px;
        padding-right: 6px; } }
  @media screen and (max-width: 768px) {
    .list-01 {
      padding-top: 24px; } }
  @media screen and (min-width: 1025px) {
    .list-01 .slick-slide {
      max-width: calc(33.33% - 24px); } }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .list-01 .slick-slide {
      max-width: calc(50% - 24px); } }
  @media screen and (min-width: 769px) {
    .list-01 .slick-slide {
      padding: 0 12px; } }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .list-01 .slick-slide {
      padding: 0 6px; } }
  .list-01.color-inverted {
    color: #FFF; }
    @media screen and (max-width: 768px) {
      .list-01.color-inverted {
        margin-top: -48px;
        width: calc(100% + 48px);
        transform: translateX(-24px); } }
    .list-01.color-inverted .list-01_item-info_heading-link {
      color: #FFF; }
      @media screen and (max-width: 768px) {
        .list-01.color-inverted .list-01_item-info_heading-link {
          padding-left: 24px; } }
      .list-01.color-inverted .list-01_item-info_heading-link:before {
        background: #FFF; }
        @media screen and (max-width: 768px) {
          .list-01.color-inverted .list-01_item-info_heading-link:before {
            top: 6px;
            left: 0;
            bottom: 6px;
            width: 2px; } }
      @media screen and (min-width: 769px) {
        .list-01.color-inverted .list-01_item-info_heading-link:hover, .list-01.color-inverted .list-01_item-info_heading-link:focus {
          color: #FFF; } }
    .list-01.color-inverted + .btn-prev,
    .list-01.color-inverted + .btn-prev + .btn-next {
      bottom: 10px; }
  @media screen and (max-width: 768px) {
    .list-01:not(.color-inverted) .list-01_item-info {
      padding: 0 24px 24px;
      border: 1px solid #EFEFEF;
      border-top: none; } }
  @media screen and (max-width: 768px) {
    .list-01:not(.color-inverted) .list-01_item-info_date,
    .list-01:not(.color-inverted) .list-01_item-info_excerpt,
    .list-01:not(.color-inverted) .list-01_item-info_categories,
    .list-01:not(.color-inverted) .list-01_item-info_btn-wrapper {
      padding-left: 0; } }

.list-01_item {
  display: flex !important;
  padding-top: 24px; }

.list-01_item-info {
  flex: 1 1 auto;
  padding: 0 24px; }
  @media screen and (min-width: 769px) {
    .list-01_item-info {
      align-items: flex-start; } }

.list-01_item-info_date,
.list-01_item-info_btn-wrapper {
  padding: 24px 0 0 24px; }
  @media screen and (min-width: 769px) {
    .list-01_item-info_date,
    .list-01_item-info_btn-wrapper {
      width: calc(100% - 24px); } }

.list-01_item-info_heading:first-child {
  margin-top: 24px; }

.list-01_item-info_excerpt,
.list-01_item-info_categories {
  padding: 12px 0 0 24px; }
  @media screen and (min-width: 769px) {
    .list-01_item-info_excerpt,
    .list-01_item-info_categories {
      width: calc(100% - 24px); } }

.list-01_item-info_categories span {
  padding-right: 4px; }

.list-01_item-info_btn-wrapper {
  justify-content: space-between;
  margin-top: auto; }
  @media screen and (max-width: 1410px) and (min-width: 1025px) {
    .list-01_item-info_btn-wrapper {
      flex-direction: column; } }

.list-01_item-info_btn:not(:only-child) {
  width: calc(50% - 3px); }
  @media screen and (max-width: 1410px) and (min-width: 1025px) {
    .list-01_item-info_btn:not(:only-child) {
      margin-top: 3px;
      width: auto; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 2 & 3) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list-02_item-thumb,
.list-03_item-thumb {
  flex: 1 1 auto; }
  .list-02_item-thumb:before,
  .list-03_item-thumb:before {
    padding-top: 75%; }

@media screen and (min-width: 769px) {
  .list-02_item-info,
  .list-03_item-info {
    flex-direction: row !important; } }

@media screen and (max-width: 768px) {
  .list-02_item-info,
  .list-03_item-info {
    border: 1px solid #EFEFEF;
    border-top: none; } }

@media screen and (max-width: 768px) {
  .list-02_item-info_content-heading,
  .list-03_item-info_heading {
    margin-top: 0;
    padding: 24px 24px 0; } }

.list-02_item-info_content-heading_link,
.list-03_item-info_heading-link {
  color: #008D61; }
  .list-02_item-info_content-heading_link:before,
  .list-03_item-info_heading-link:before {
    background: #008D61 !important; }
  @media screen and (min-width: 769px) {
    .list-02_item-info_content-heading_link:hover, .list-02_item-info_content-heading_link:focus,
    .list-03_item-info_heading-link:hover,
    .list-03_item-info_heading-link:focus {
      color: #0C0402; }
      .list-02_item-info_content-heading_link:hover:before, .list-02_item-info_content-heading_link:focus:before,
      .list-03_item-info_heading-link:hover:before,
      .list-03_item-info_heading-link:focus:before {
        background: #0C0402 !important; } }

.list-02_item-info_content-excerpt,
.list-03_item-info_excerpt {
  padding: 12px 0 0 24px;
  color: #666; }
  @media screen and (max-width: 768px) {
    .list-02_item-info_content-excerpt,
    .list-03_item-info_excerpt {
      padding: 12px 24px 0; } }

.list-02_item-info_content-detail,
.list-03_item-info_detail {
  padding: 24px 0 12px 24px; }
  @media screen and (max-width: 768px) {
    .list-02_item-info_content-detail,
    .list-03_item-info_detail {
      padding: 24px 24px 0; } }

.list-02_item-info_content-detail_item,
.list-03_item-info_detail-item {
  align-items: baseline; }

.list-02_item-info_content-detail_item-label,
.list-03_item-info_detail-item_label {
  flex-shrink: 0;
  width: 20%;
  color: #0C0402;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase; }
  @media screen and (max-width: 768px) {
    .list-02_item-info_content-detail_item-label,
    .list-03_item-info_detail-item_label {
      width: 30%; } }

.list-02_item-info_content-detail_item-value,
.list-03_item-info_detail-item_value {
  flex: 1 1 auto;
  padding-left: 24px;
  color: #666; }

.list-02_item-info_btn-wrapper,
.list-03_item-btn_wrapper {
  flex-shrink: 0; }
  @media screen and (max-width: 768px) {
    .list-02_item-info_btn-wrapper,
    .list-03_item-btn_wrapper {
      flex-direction: row;
      justify-content: space-between;
      margin-left: 0;
      padding: 24px; } }

.list-02_item-info_btn,
.list-03_item-btn {
  flex-shrink: 0; }
  @media screen and (min-width: 769px) {
    .list-02_item-info_btn:not(:first-child),
    .list-03_item-btn:not(:first-child) {
      margin-top: 6px; } }
  @media screen and (max-width: 768px) {
    .list-02_item-info_btn:not(:only-child),
    .list-03_item-btn:not(:only-child) {
      width: calc(50% - 3px); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 2) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list-02 {
  margin-top: -12px; }

.list-02_item {
  padding-top: 12px; }

.list-02_item-info_content {
  flex: 1 1 auto; }

.list-02_item-info_btn-wrapper {
  justify-content: center;
  margin-left: 48px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 2 - VARIANT 2) ---------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list-02-02 {
  margin-top: -48px;
  padding-top: 96px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .list-02-02 {
      padding-top: 48px; } }
  @media screen and (max-width: 768px) {
    .list-02-02 {
      margin-top: -12px;
      padding-top: 24px; } }
  .list-02-02 .list-02_item {
    padding-top: 48px; }
    @media screen and (max-width: 768px) {
      .list-02-02 .list-02_item {
        padding-top: 12px; }
        .list-02-02 .list-02_item > .column:first-child {
          order: 2; }
        .list-02-02 .list-02_item > .column:last-child {
          order: 1; } }
  .list-02-02 .list-02_item-thumb:before {
    padding-top: 56.25%; }
  @media screen and (max-width: 768px) {
    .list-02-02 .list-02_item-info_content {
      padding-bottom: 24px; } }
  .list-02-02 .list-02_item-info_content-detail_item {
    flex-direction: column; }
    .list-02-02 .list-02_item-info_content-detail_item:not(:first-child) {
      padding-top: 12px; }
  .list-02-02 .list-02_item-info_content-detail_item-label {
    width: auto; }
  .list-02-02 .list-02_item-info_content-detail_item-value {
    padding-left: 0; }
  .list-02-02 .list-02_item-info_btn {
    position: absolute;
    right: 0;
    bottom: 24px;
    z-index: 2; }
    @media screen and (max-width: 768px) {
      .list-02-02 .list-02_item-info_btn {
        right: -6px;
        bottom: 6px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 2 - VARIANT 3) ---------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (min-width: 769px) {
  .list-02-03 .list-02_item:not(:first-child) {
    margin-top: 48px;
    padding-top: 48px;
    border-top: 1px solid #EFEFEF; } }

@media screen and (max-width: 768px) {
  .list-02-03 .list-02_item:not(:first-child) {
    padding-top: 12px; } }

.list-02-03 .list-02_item-thumb {
  flex: none; }

.list-02-03 .list-02_item-info_content-detail {
  padding-top: 18px;
  padding-bottom: 0; }

@media screen and (min-width: 769px) {
  .list-02-03 .list-02_item-info_btn {
    min-width: 162px; } }

@media screen and (max-width: 768px) {
  .list-02-03 .list-02_item-info_btn:only-child {
    width: 100%; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 3) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 1100px) and (min-width: 769px) {
  .list-03_item > .grid > .column.width_03-12 {
    width: 40%; }
  .list-03_item > .grid > .column.width_09-12 {
    width: 60%; } }

@media screen and (min-width: 769px) {
  .list-03_item:not(:first-child) {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #EFEFEF; } }

@media screen and (max-width: 768px) {
  .list-03_item:not(:first-child) {
    padding-top: 12px; } }

@media screen and (min-width: 769px) {
  .list-03_item-btn_wrapper {
    margin-top: 6px; } }

@media screen and (max-width: 768px) {
  .list-03_item-btn:only-child {
    width: 100%; } }

.list-03_item-info_heading + .list-03_item-info_detail {
  padding: 12px 0 0 24px; }

.list-03_item-info_detail + .list-03_item-info_excerpt {
  padding: 24px 0 12px 24px; }
  @media screen and (max-width: 768px) {
    .list-03_item-info_detail + .list-03_item-info_excerpt {
      padding: 24px 24px 0; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION (TYPE 01) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pagination-01 {
  align-items: center;
  padding: 0 96px 96px; }
  @media screen and (max-width: 768px) {
    .pagination-01 {
      padding: 0 24px 24px; } }

.pagination-01_list {
  justify-content: center;
  width: 100%;
  list-style: none; }

.pagination-01_page {
  justify-content: center; }
  .pagination-01_page .pagination-01_list-item_link {
    padding: 12px; }
    @media screen and (max-width: 768px) {
      .pagination-01_page .pagination-01_list-item_link {
        padding: 6px 12px; } }
  .pagination-01_page.is-current .pagination-01_list-item_link {
    color: #008D61;
    font-weight: 700; }
  @media screen and (min-width: 769px) {
    .pagination-01_page:not(.is-current):hover .pagination-01_list-item_link,
    .pagination-01_page:not(.is-current):focus .pagination-01_list-item_link {
      color: #E9423A; } }

.pagination-01_prev .pagination-01_list-item_link,
.pagination-01_next .pagination-01_list-item_link {
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #0C0402;
  border-radius: 100%; }
  @media screen and (max-width: 768px) {
    .pagination-01_prev .pagination-01_list-item_link,
    .pagination-01_next .pagination-01_list-item_link {
      width: 32px;
      height: 32px; } }
  .pagination-01_prev .pagination-01_list-item_link svg,
  .pagination-01_next .pagination-01_list-item_link svg {
    display: block;
    width: 18px;
    height: 18px;
    fill: #FFF; }
    @media screen and (max-width: 768px) {
      .pagination-01_prev .pagination-01_list-item_link svg,
      .pagination-01_next .pagination-01_list-item_link svg {
        width: 12px;
        height: 12px; } }

.pagination-01_prev.is-disabled .pagination-01_list-item_link,
.pagination-01_next.is-disabled .pagination-01_list-item_link {
  background: #EFEFEF; }

@media screen and (min-width: 769px) {
  .pagination-01_prev:not(.is-disabled):hover .pagination-01_list-item_link,
  .pagination-01_prev:not(.is-disabled):focus .pagination-01_list-item_link,
  .pagination-01_next:not(.is-disabled):hover .pagination-01_list-item_link,
  .pagination-01_next:not(.is-disabled):focus .pagination-01_list-item_link {
    background: #E9423A; } }

.pagination-01_prev {
  padding-right: 48px; }
  @media screen and (max-width: 768px) {
    .pagination-01_prev {
      padding-right: 24px; } }
  .pagination-01_prev .pagination-01_list-item_link svg {
    transform: rotate(180deg); }

.pagination-01_next {
  padding-left: 48px; }
  @media screen and (max-width: 768px) {
    .pagination-01_next {
      padding-left: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-wrapper {
  min-height: calc(100vh - 332px);
  color: #FFF;
  background: #0C0402; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .hero-wrapper {
      min-height: calc(100vh - 351px); } }
  @media screen and (max-width: 768px) {
    .hero-wrapper {
      min-height: calc(100vh - (60px + (48px * 3))); } }
  .hero-wrapper:before {
    content: "";
    display: block;
    padding-top: 28%;
    width: 100%;
    height: 0;
    overflow: hidden; }
    @media screen and (max-width: 1220px) and (min-width: 769px) {
      .hero-wrapper:before {
        padding-top: 27%; } }

.hero.js-slick {
  width: 100% !important;
  height: 100% !important;
  opacity: 0; }
  .hero.js-slick.is-visible {
    opacity: 1; }
  .hero.js-slick .slick-dots {
    position: absolute;
    left: 50%;
    bottom: -54px;
    z-index: 1;
    transform: translateX(-50%);
    height: 12px;
    list-style: none; }
    @media screen and (max-width: 1220px) and (min-width: 769px) {
      .hero.js-slick .slick-dots {
        bottom: -30px; } }
    @media screen and (max-width: 768px) {
      .hero.js-slick .slick-dots {
        display: none; } }
    .hero.js-slick .slick-dots li {
      margin: 0 3px;
      width: 12px;
      height: 12px; }
      .hero.js-slick .slick-dots li button {
        display: block;
        width: 12px;
        height: 12px;
        font-size: 0px;
        text-indent: -999em;
        background: #FFF !important;
        border-radius: 100%;
        overflow: hidden;
        opacity: .48; }
      .hero.js-slick .slick-dots li.slick-active button {
        opacity: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (BACKGROUND) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-background {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1 !important; }
  .hero-background .slick-track {
    position: absolute;
    height: 100%; }

.hero-background_slide {
  flex: 1 1 auto; }

.hero-background_slide-image,
.hero-background_slide-image picture,
.hero-background_slide-image picture source,
.hero-background_slide-image picture img {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1 !important;
  width: 100% !important;
  height: 100% !important; }

.hero-background_slide-image picture img {
  object-fit: cover;
  font-family: 'object-fit:cover;'; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (CONTENT) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-content_wrapper {
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  margin: 96px 48px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .hero-content_wrapper {
      margin: 48px 12px; } }
  @media screen and (max-width: 768px) {
    .hero-content_wrapper {
      margin: 24px 0; } }

.hero-content_center {
  margin: 0 96px;
  width: calc(100% - 192px); }
  .hero-content_center .slick-slide > div {
    line-height: 0; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .hero-content_center {
      margin: 0 48px;
      width: calc(100% - 96px); } }
  @media screen and (max-width: 768px) {
    .hero-content_center {
      margin: 0 24px;
      width: calc(100% - 48px); }
      .hero-content_center .slick-track {
        display: flex !important;
        flex-direction: row;
        align-items: flex-end; } }

.hero-content_slide {
  flex: 100%; }

.hero-content_slide-content {
  flex: 100%; }

.hero-content_slide-content_heading-wrapper {
  justify-content: center; }

@media screen and (max-width: 1220px) and (min-width: 769px) {
  .hero-content_slide-content_heading {
    font-size: 48px;
    font-weight: 600; } }

@media screen and (max-width: 768px) {
  .hero-content_slide-content_heading {
    padding: 0 18px;
    font-weight: 700; } }

.hero-content_slide-content_text {
  padding-top: 24px;
  line-height: 1.6;
  font-size: 16px; }
  @media screen and (max-width: 768px) {
    .hero-content_slide-content_text {
      padding: 0 18px; } }

@media screen and (min-width: 769px) {
  .hero-content_slide-content_link-wrapper {
    justify-content: flex-end;
    align-items: flex-end; } }

@media screen and (max-width: 768px) {
  .hero-content_slide-content_link-wrapper {
    align-items: flex-start;
    margin-top: 24px; } }

.hero-content_slide-content_link {
  padding: 18px 24px;
  line-height: 1.3;
  color: #FFF;
  font-size: 20px;
  font-weight: 500; }
  @media screen and (max-width: 768px) {
    .hero-content_slide-content_link {
      margin: 0 18px;
      font-size: 16px; } }
  @media screen and (min-width: 769px) {
    .hero-content_slide-content_link.bg_01-01:not(:disabled):hover, .hero-content_slide-content_link.bg_01-01:not(:disabled):focus {
      background: #E9423A !important; } }
  .hero-content_slide-content_link.bg_white {
    color: #E9423A; }
    @media screen and (min-width: 769px) {
      .hero-content_slide-content_link.bg_white:not(:disabled):hover, .hero-content_slide-content_link.bg_white:not(:disabled):focus {
        color: #FFF !important;
        background: #E9423A !important; } }

.hero-content_slide-content_info {
  flex-wrap: wrap;
  flex: 1 1 auto;
  background: #FFF; }
  @media screen and (max-width: 768px) {
    .hero-content_slide-content_info:first-child {
      margin-top: 24px; } }
  .hero-content_slide-content_info:not(:first-child) {
    border-top: 1px solid #EFEFEF; }

.hero-content_slide-content_info-item {
  justify-content: center;
  padding: 18px 24px;
  width: 50%;
  line-height: 1.3;
  color: #0C0402;
  box-sizing: border-box;
  /*
							&:nth-child(even) {
								border-left:1px solid $color_theme_01-05;
							}
							&:nth-child(n+2) {
								border-top:1px solid $color_theme_01-05;
							}
							*/ }
  @media screen and (max-width: 768px) {
    .hero-content_slide-content_info-item {
      padding: 18px; } }
  .hero-content_slide-content_info-item:only-child {
    width: 100%; }
  .hero-content_slide-content_info-item:not(:first-child) {
    border-left: 1px solid #EFEFEF; }

.hero-content_slide-content_info-item_label {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase; }
  @media screen and (max-width: 768px) {
    .hero-content_slide-content_info-item_label {
      font-size: 10px; } }

.hero-content_slide-content_info-item_value strong {
  font-size: 20px;
  font-weight: 500; }
  @media screen and (max-width: 768px) {
    .hero-content_slide-content_info-item_value strong {
      font-size: 14px; } }

@media screen and (max-width: 768px) {
  .hero-content_slide-content_info-item_value span {
    font-size: 12px; } }

.hero-content_slide-content_info-item_value a {
  color: #0C0402; }
  @media screen and (min-width: 769px) {
    .hero-content_slide-content_info-item_value a:hover, .hero-content_slide-content_info-item_value a:focus {
      color: #E9423A; } }

.hero-content_slide-content_info-link_wrapper,
.hero-content_slide-content_info-link {
  justify-content: center;
  align-items: center;
  width: 50%;
  box-sizing: border-box; }

.hero-content_slide-content_info-link_wrapper .hero-content_slide-content_info-link {
  flex: 1 1 auto;
  width: 100%; }

.hero-content_slide-content_info-link {
  padding: 18px 24px;
  line-height: 1.3;
  font-size: 20px;
  font-weight: 500; }
  @media screen and (max-width: 768px) {
    .hero-content_slide-content_info-link {
      font-size: 16px; } }
  .hero-content_slide-content_info-link.link_more-info {
    color: #FFF; }
    @media screen and (min-width: 769px) {
      .hero-content_slide-content_info-link.link_more-info:hover, .hero-content_slide-content_info-link.link_more-info:focus {
        color: #E9423A;
        background: #0C0402 !important; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (PREV / NEXT) ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-content_controls:first-child {
  left: 0; }

.hero-content_controls:last-child {
  right: 0; }

@media screen and (min-width: 769px) {
  .hero-content_controls {
    top: 50%;
    transform: translateY(-50%); } }

@media screen and (max-width: 768px) {
  .hero-content_controls {
    top: auto;
    bottom: 12px; } }

.hero-prev,
.hero-next {
  width: 96px;
  height: 96px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .hero-prev,
    .hero-next {
      width: 48px;
      height: 48px; } }
  @media screen and (max-width: 768px) {
    .hero-prev,
    .hero-next {
      width: 24px;
      height: 24px; } }
  .hero-prev svg,
  .hero-next svg {
    display: block;
    width: 96px;
    height: 96px;
    fill: rgba(255, 255, 255, 0.48); }
    @media screen and (max-width: 1220px) and (min-width: 769px) {
      .hero-prev svg,
      .hero-next svg {
        width: 48px;
        height: 48px; } }
    @media screen and (max-width: 768px) {
      .hero-prev svg,
      .hero-next svg {
        width: 24px;
        height: 24px;
        fill: #FFF; } }
  @media screen and (min-width: 769px) {
    .hero-prev:hover svg,
    .hero-prev:focus svg,
    .hero-next:hover svg,
    .hero-next:focus svg {
      fill: #FFF; } }

.hero-prev svg {
  transform: rotate(180deg); }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (STANDARD) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-standard {
  justify-content: center;
  align-items: center;
  padding: 96px;
  min-height: 50vh;
  color: #FFF;
  background: #0C0402;
  box-sizing: border-box; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .hero-standard {
      padding: 48px; } }
  @media screen and (max-width: 768px) {
    .hero-standard {
      justify-content: flex-end;
      padding: 24px;
      min-height: calc(100vh - 114px); } }

.hero-standard_bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
  opacity: .32; }

.hero-standard_bg img {
  width: 100% !important;
  height: 100% !important;
  opacity: .64;
  object-fit: cover;
  font-family: 'object-fit:cover;'; }

.hero-standard_bg.align-top img {
  object-position: top center;
  font-family: 'object-fit:cover;object-position:top center;'; }

.hero-standard_bg.align-bottom img {
  object-position: bottom center;
  font-family: 'object-fit:cover;object-position:bottom center;'; }

.hero-standard_content-wrapper {
  position: relative;
  z-index: 3;
  width: 100%; }

@media screen and (max-width: 768px) {
  .hero-standard_content {
    flex-direction: column;
    align-items: center; } }

.hero-standard_content.pos-right {
  justify-content: flex-end; }

.hero-standard_content-logo {
  padding-right: 48px;
  width: 100%;
  max-width: 174px; }
  @media screen and (max-width: 768px) {
    .hero-standard_content-logo {
      padding-right: 0;
      max-width: 96px; } }

.hero-standard_content-heading_wrapper {
  flex: 1 1 auto;
  width: 100%;
  max-width: 600px; }
  @media screen and (max-width: 768px) {
    .hero-standard_content-heading_wrapper {
      padding-top: 24px;
      text-align: center; } }

.hero-standard_content-heading {
  font-weight: 300; }

.hero-standard_content-subheading {
  padding-top: 12px;
  font-weight: 400; }
  @media screen and (max-width: 768px) {
    .hero-standard_content-subheading {
      font-size: 18px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (STANDARD) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero-404 {
  align-items: center;
  box-sizing: border-box; }
  .hero-404:before {
    display: none; }

.hero-404_content-wrapper {
  position: relative;
  z-index: 3;
  width: 100%; }

.hero-404_content-heading {
  font-size: 96px; }

.hero-404_content-subheading {
  padding-top: 24px; }

.hero-404_content-text {
  padding-top: 12px; }

a.hero-404_content-link {
  margin-top: 64px; }
  @media screen and (min-width: 769px) {
    a.hero-404_content-link:hover, a.hero-404_content-link:focus {
      color: #FFF !important;
      background: #E9423A !important; } }
  @media screen and (max-width: 768px) {
    a.hero-404_content-link {
      margin-top: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRODUCT INFO ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.product-info {
  width: 100%;
  max-width: calc((1220px / 2) - 32px); }

.product-info_dates {
  padding-bottom: 9px;
  line-height: 1.3; }

.product-info_dates-divider {
  flex-shrink: 0;
  margin: 10px 12px 0;
  width: 24px;
  height: 1px;
  background: #FFF; }

.product-info_block,
.product-info_btn-wrapper {
  justify-content: space-between; }

.product-info_categories,
.product-info_price,
.product-info_badge {
  width: calc(50% - 16px); }
  @media screen and (max-width: 768px) {
    .product-info_categories,
    .product-info_price,
    .product-info_badge {
      width: calc(50% - 12px); } }

.product-info_block {
  padding-top: 18px; }

.product-info_categories span {
  display: block; }

.product-info_categories-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none; }

.product-info_categories-list_item:not(:last-child) {
  padding-right: 4px; }
  .product-info_categories-list_item:not(:last-child):after {
    content: ","; }

.product-info_categories-list_item-link {
  color: #FFF;
  font-weight: 500; }
  @media screen and (min-width: 769px) {
    .product-info_categories-list_item-link:hover, .product-info_categories-list_item-link:focus {
      color: #E9423A; } }

.product-info_price strong {
  font-weight: 500; }

.product-info_btn-wrapper {
  padding-top: 48px;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .product-info_btn-wrapper {
      padding-top: 24px; } }

.product-info_btn {
  width: auto; }

.product-info_badge {
  align-items: center; }

.product-info_badge-symbol {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-weight: 700;
  border: 2px solid #FFF;
  border-radius: 100%; }
  .product-info_badge-symbol svg {
    flex-shrink: 0;
    display: block;
    width: 18px;
    height: 18px;
    fill: #FFF; }

.product-info_badge-label {
  padding-left: 16px;
  color: rgba(255, 255, 255, 0.48); }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRODUCT REVIEWS -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.product-reviews_wrapper {
  padding-top: 96px; }
  .product-reviews_wrapper:first-child {
    margin-top: -96px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .product-reviews_wrapper {
      padding-top: 48px; }
      .product-reviews_wrapper:first-child {
        margin-top: -48px; } }
  @media screen and (max-width: 768px) {
    .product-reviews_wrapper {
      padding-top: 24px; }
      .product-reviews_wrapper:first-child {
        margin-top: -24px; } }

@media screen and (max-width: 768px) {
  .product-reviews {
    padding: 0;
    border: none; } }

.product-reviews_slider-wrapper {
  flex: 1 1 auto;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .product-reviews_slider-wrapper {
      padding-top: 12px; } }

.product-reviews_slider {
  width: 100%; }

.product-reviews_slider-item_rating-bg {
  display: block;
  width: auto;
  height: 24px; }
  .product-reviews_slider-item_rating-bg svg {
    display: block;
    width: auto;
    height: 24px;
    fill: #EFEFEF; }

.product-reviews_slider-item_rating-over {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: auto;
  height: 24px;
  overflow: hidden; }
  .product-reviews_slider-item_rating-over.rating_10-50 {
    width: 24px; }
  .product-reviews_slider-item_rating-over.rating_15-50 {
    width: 40px; }
  .product-reviews_slider-item_rating-over.rating_20-50 {
    width: 52px; }
  .product-reviews_slider-item_rating-over.rating_25-50 {
    width: 68px; }
  .product-reviews_slider-item_rating-over.rating_30-50 {
    width: 80px; }
  .product-reviews_slider-item_rating-over.rating_35-50 {
    width: 96px; }
  .product-reviews_slider-item_rating-over.rating_40-50 {
    width: 108px; }
  .product-reviews_slider-item_rating-over.rating_45-50 {
    width: 124px; }
  .product-reviews_slider-item_rating-over.rating_50-50 {
    width: 136px; }
  .product-reviews_slider-item_rating-over svg {
    display: block;
    width: auto;
    height: 24px;
    fill: #FF8F00; }

.product-reviews_slider-item_text {
  padding-top: 24px; }
  @media screen and (max-width: 768px) {
    .product-reviews_slider-item_text {
      padding-top: 12px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRODUCT SHARE ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.product-share {
  align-items: center;
  margin-top: 96px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .product-share {
      margin-top: 48px; } }
  @media screen and (max-width: 768px) {
    .product-share {
      align-items: flex-start;
      margin-top: 24px; } }

.product-share_link-back_wrapper {
  justify-content: flex-start; }

.product-share_link-back {
  align-items: center; }
  @media screen and (min-width: 769px) {
    .product-share_link-back:hover, .product-share_link-back:focus {
      color: #E9423A; }
      .product-share_link-back:hover svg, .product-share_link-back:focus svg {
        fill: #E9423A; } }
  .product-share_link-back svg {
    display: block;
    width: 18px;
    height: 18px;
    fill: #0C0402;
    transform: rotate(90deg); }
  .product-share_link-back span {
    padding-left: 12px;
    font-weight: 500; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SEARCH (MODAL) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .modal-search-desktop_wrapper {
    display: none !important; } }

.modal-search-desktop_content {
  align-items: center;
  top: 192px !important;
  max-width: calc(1220px + 96px);
  box-sizing: border-box;
  transform: translate(-50%, 0%) !important; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .modal-search-desktop_content {
      top: 96px !important; } }

.form_modal-search {
  width: 100%;
  color: #FFF; }
  .form_modal-search .input-text {
    padding-right: 112px;
    border-bottom: 2px solid #FFF; }
    @media screen and (max-width: 1220px) and (min-width: 769px) {
      .form_modal-search .input-text {
        padding-right: 96px; } }
    .form_modal-search .input-text label,
    .form_modal-search .input-text input {
      padding: 0 0 24px;
      color: #FFF;
      font-size: 96px;
      font-weight: 300;
      background: none;
      border: none; }
      @media screen and (max-width: 1220px) and (min-width: 769px) {
        .form_modal-search .input-text label,
        .form_modal-search .input-text input {
          font-size: 48px; } }
    .form_modal-search .input-text label {
      right: 112px; }
      @media screen and (max-width: 1220px) and (min-width: 769px) {
        .form_modal-search .input-text label {
          right: 96px; } }
    .form_modal-search .input-text input:not([value=""]) + .label_in-field,
    .form_modal-search .input-text input:focus + .label_in-field {
      padding: 0 0 24px;
      font-size: 16px;
      opacity: .48; }
    @media screen and (min-width: 769px) {
      .form_modal-search .input-text input:hover, .form_modal-search .input-text input:focus {
        border: none; } }
  .form_modal-search .input-submit {
    position: absolute;
    top: 50%;
    right: 24px;
    z-index: 2;
    margin-top: -12px;
    padding: 0;
    width: 64px;
    height: 64px;
    transform: translateY(-50%); }
    @media screen and (max-width: 1220px) and (min-width: 769px) {
      .form_modal-search .input-submit {
        width: 48px;
        height: 48px; } }
    .form_modal-search .input-submit svg {
      display: block;
      width: 64px;
      height: 64px;
      fill: #FFF; }
      @media screen and (max-width: 1220px) and (min-width: 769px) {
        .form_modal-search .input-submit svg {
          width: 48px;
          height: 48px; } }
    @media screen and (min-width: 769px) {
      .form_modal-search .input-submit:hover svg,
      .form_modal-search .input-submit:focus svg {
        fill: #E9423A; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SEARCH (PANEL) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form_panel-search {
  align-items: center; }
  .form_panel-search .input-text {
    width: 100%; }
    .form_panel-search .input-text label {
      color: #FFF; }
    .form_panel-search .input-text input {
      color: #FFF;
      background: none;
      border: none; }
  .form_panel-search .input-submit {
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding-top: 0;
    width: 60px;
    height: 60px; }
    .form_panel-search .input-submit svg {
      display: block;
      width: 18px;
      height: 18px;
      fill: #FFF; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FILTERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .product-filters .field-label,
  .product-filters .fieldset-legend {
    color: #FFF; } }

@media screen and (max-width: 768px) {
  .product-filters .fieldset-legend.js-accordion_trigger {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12); }
    .product-filters .fieldset-legend.js-accordion_trigger svg {
      fill: #FFF; } }

@media screen and (max-width: 768px) {
  .product-filters .input-text input,
  .product-filters .input-datepicker input,
  .product-filters .input-datepicker .datepicker-clear,
  .product-filters .field-select,
  .product-filters .input-radio input + span,
  .product-filters .input-checkbox input + span {
    color: #FFF;
    background: rgba(255, 255, 255, 0.12);
    border: none; } }

.product-filters .input-text svg {
  position: absolute;
  top: 50%;
  right: 24px;
  z-index: 4;
  width: 18px;
  height: 18px;
  fill: #0C0402;
  transform: translateY(-50%); }
  @media screen and (max-width: 768px) {
    .product-filters .input-text svg {
      fill: #FFF; } }

@media screen and (max-width: 768px) {
  .product-filters .input-datepicker .datepicker-clear svg {
    fill: #FFF; } }

@media screen and (max-width: 768px) {
  .product-filters .field-select select {
    color: #FFF;
    background: none;
    border: none; }
  .product-filters .field-select .chevron svg {
    fill: #FFF; } }

@media screen and (max-width: 768px) {
  .product-filters .input-radio input + span:after {
    background: #FFF; } }

@media screen and (max-width: 768px) {
  .product-filters .input-checkbox input + span svg {
    fill: #FFF; } }

@media screen and (max-width: 768px) {
  .product-filters_container {
    padding: 24px; } }

.product-filters_btn-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .product-filters_btn-wrapper {
      padding-top: 24px; } }

.product-filters_btn {
  flex: 1 1 auto; }
  .product-filters_btn:first-child {
    margin-right: 6px; }

.product-filters_btn.btn-02 span {
  flex: 1 1 auto; }

.product-filters_btn.btn-02 svg {
  width: 14px;
  height: 14px; }

@media screen and (max-width: 768px) {
  .product-filters_btn.btn-02 {
    color: #FFF !important;
    border-color: rgba(255, 255, 255, 0.12) !important; }
    .product-filters_btn.btn-02 svg {
      fill: #FFF !important; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FILTERS (TRIGGER) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.product-filters_trigger-wrapper {
  justify-content: space-between;
  align-items: center; }

@media screen and (min-width: 769px) {
  .product-filters_trigger-container {
    padding-left: 48px; } }

@media screen and (max-width: 920px) and (min-width: 769px) {
  .product-filters_trigger-container {
    flex-direction: column; } }

@media screen and (max-width: 768px) {
  .product-filters_trigger-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #FFF; } }

@media screen and (max-width: 768px) {
  .product-filters_trigger-container:after {
    content: "";
    left: 0;
    right: 0;
    top: -6px;
    height: 6px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.06) 100%); } }

.product-filters_trigger-container .btn {
  flex-shrink: 0; }
  @media screen and (max-width: 768px) {
    .product-filters_trigger-container .btn {
      justify-content: space-between;
      flex: 1 1 auto;
      order: 2;
      padding: 16px 24px;
      width: 100%;
      color: #0C0402 !important;
      font-weight: 500;
      background: none !important;
      border: none !important;
      border-left: 1px solid #EFEFEF !important; } }
  @media screen and (max-width: 768px) {
    .product-filters_trigger-container .btn svg {
      fill: #0C0402 !important; } }
  @media screen and (min-width: 921px) {
    .product-filters_trigger-container .btn + .btn {
      margin-left: 6px; } }
  @media screen and (max-width: 920px) and (min-width: 769px) {
    .product-filters_trigger-container .btn + .btn {
      margin-top: 6px; } }
  @media screen and (max-width: 768px) {
    .product-filters_trigger-container .btn + .btn {
      order: 1;
      border-left: none !important; } }
  @media screen and (max-width: 768px) {
    .product-filters_trigger-container .btn + .btn svg {
      width: 18px !important;
      height: 18px !important; } }
  .product-filters_trigger-container .btn:disabled {
    display: none; }

/*
.product-filters {
	flex:none;

	& > .box-content {
		@extend .animate;

		@media screen and (max-width:768px)
		{
			position:fixed;
			top:0; left:0; right:0; bottom:0;
			z-index:1001;
			background:rgba(#FFF, .48);
			opacity:0;
			visibility:hidden;

			&.is-active {
				opacity:1;
				visibility:visible;

				& > .grid {
					transform:translateX(0);
				}
			}
		}

		& > .grid {
			@extend .animate;

			@media screen and (max-width:768px)
			{
				display:block;
				position:absolute;
				top:0; right:0; bottom:0;
				z-index:1;
				padding:24px;
				width:calc(100% - 60px); height:100%;
				color:#FFF;
				background:$color_theme_01-02;
				box-sizing:border-box;
				overflow-y:auto;
				transform:translateX(12px);
			}
		}
	}

	.field {
		justify-content:flex-end;

		@media screen and (min-width:769px)
		{
			padding-top:0;
		}
	}

	.field-select {

		@media screen and (max-width:768px)
		{
			background:rgba(#FFF, .12);
			border:none;

			select {
				color:#FFF;
			}
			.chevron svg {
				fill:#FFF;
			}
		}
	}

	.input-checkbox {

		@media screen and (min-width:769px)
		{
			padding:16px 0;
		}
		@media screen and (max-width:768px)
		{
			input + span {
				background:rgba(#FFF, .12);
				border:none;

				svg {
					fill:#FFF;
				}
			}
		}
	}

	&.nav-side {

		.nav-side_list {

			@media screen and (max-width:768px)
			{
				padding:24px;
			}
		}

		h2 + .field {
			padding-top:24px;
		}
		.field {
			padding-top:6px;
		}

		.input-checkbox {
			padding:0;
		}
	}
}
*/
/* --------------------------------------------------------------------------------------------------- */
/* BOOKING ------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.booking-table {
  padding-top: 96px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .booking-table {
      padding-top: 48px; } }
  @media screen and (max-width: 768px) {
    .booking-table {
      padding-top: 24px; } }

.booking-table_subheading {
  font-size: 24px; }
  @media screen and (max-width: 768px) {
    .booking-table_subheading {
      font-size: 18px; } }

.booking-table_month {
  padding-top: 24px; }

.booking-table_item {
  margin-top: 12px;
  border: 4px solid #EFEFEF; }
  @media screen and (max-width: 768px) {
    .booking-table_item {
      flex-direction: column; } }
  .booking-table_item.is-disabled .booking-table_item-date {
    color: rgba(45, 45, 45, 0.24); }
  .booking-table_item.is-disabled .booking-table_item-timeslot {
    opacity: .24; }
    .booking-table_item.is-disabled .booking-table_item-timeslot button {
      background: #666 !important;
      cursor: default; }

.booking-table_item-date,
.booking-table_item-timeslots {
  flex-shrink: 0; }

.booking-table_item-date {
  padding: 24px;
  font-weight: 600;
  width: 25%;
  border-right: 2px solid #EFEFEF;
  box-sizing: border-box; }
  @media screen and (max-width: 768px) {
    .booking-table_item-date {
      padding: 16px 18px;
      width: 100%;
      border-right: none;
      border-bottom: 2px solid #EFEFEF; } }

@media screen and (min-width: 769px) {
  .booking-table_item-timeslots {
    width: 75%; } }

.booking-table_item-timeslot {
  align-items: center;
  padding: 24px;
  box-sizing: border-box; }
  @media screen and (max-width: 768px) {
    .booking-table_item-timeslot {
      padding: 16px 18px; } }
  .booking-table_item-timeslot:not(:first-child) {
    border-top: 2px solid #EFEFEF; }
  .booking-table_item-timeslot.is-disabled {
    opacity: .24; }
    .booking-table_item-timeslot.is-disabled button {
      background: #666 !important;
      cursor: default; }

.booking-table_item-timeslot_lhc {
  flex: 1 1 auto;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .booking-table_item-timeslot_lhc {
      flex-direction: column;
      align-items: flex-start; } }

.booking-table_item-timeslot_message {
  flex: 1 1 auto;
  font-size: 12px; }
  @media screen and (min-width: 769px) {
    .booking-table_item-timeslot_message {
      padding-left: 24px; } }

.booking-table_item-timeslot_message-block span:not(:last-child):after {
  content: ","; }

.booking-table_item-timeslot_link {
  flex-shrink: 0;
  margin: -12px -12px -12px 0;
  padding-left: 24px; }
  @media screen and (max-width: 768px) {
    .booking-table_item-timeslot_link {
      padding-left: 18px; } }

/* --------------------------------------------------------------------------------------------------- */
/* FAQS ---------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.faqs-item:not(:first-child) {
  padding-top: 6px; }
  @media screen and (max-width: 768px) {
    .faqs-item:not(:first-child) {
      padding-top: 2px; } }

.faqs-item.open .faqs-item_question {
  color: #0C0402 !important;
  background: none !important; }
  .faqs-item.open .faqs-item_question svg {
    fill: #0C0402;
    transform: rotate(90deg); }
  @media screen and (min-width: 769px) {
    .faqs-item.open .faqs-item_question:hover, .faqs-item.open .faqs-item_question:focus {
      color: #0C0402 !important;
      background: none !important; } }

.faqs-item_question {
  align-items: center;
  padding: 24px 32px;
  width: 100%;
  font-weight: 600;
  text-align: left;
  box-sizing: border-box; }
  @media screen and (max-width: 768px) {
    .faqs-item_question {
      padding: 16px 18px; } }
  .faqs-item_question span {
    flex: 1 1 auto;
    padding-right: 24px; }
  .faqs-item_question svg {
    display: block;
    width: 18px;
    height: 18px;
    fill: #FFF; }
    @media screen and (max-width: 768px) {
      .faqs-item_question svg {
        width: 14px;
        height: 14px; } }

.faqs-item_answer {
  padding: 0 32px 42px; }
  @media screen and (max-width: 768px) {
    .faqs-item_answer {
      padding: 0 18px 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL FEED ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.social-feed {
  overflow: hidden; }

.social-feed_filters-wrapper {
  align-items: flex-end; }
  @media screen and (max-width: 768px) {
    .social-feed_filters-wrapper {
      flex-direction: column;
      align-items: flex-start; } }

.social-feed_filters {
  padding: 0 0 8px 48px; }
  @media screen and (max-width: 768px) {
    .social-feed_filters {
      padding: 24px 0 0;
      width: 100%; } }

.social-feed_filters-trigger {
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  padding-bottom: 6px;
  width: 100%;
  color: #0C0402;
  font-weight: 500;
  border-bottom: 1px solid #EFEFEF; }
  .social-feed_filters-trigger svg {
    display: block;
    width: 14px;
    height: 14px;
    fill: #0C0402;
    transform: rotate(90deg); }

@media screen and (max-width: 768px) {
  .social-feed_filters-list_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    background: rgba(255, 255, 255, 0.48);
    opacity: 0;
    visibility: hidden; }
    .social-feed_filters-list_wrapper.is-active {
      opacity: 1;
      visibility: visible; }
      .social-feed_filters-list_wrapper.is-active > .social-feed_filters-list {
        transform: translateX(0); } }

.social-feed_filters-list {
  list-style: none; }
  @media screen and (max-width: 768px) {
    .social-feed_filters-list {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      padding: 36px 48px;
      width: calc(100% - 60px);
      height: 100%;
      color: #FFF;
      background: #2D2D2D;
      box-sizing: border-box;
      overflow-y: auto;
      transform: translateX(12px); } }

.social-feed_filters-list_item:not(:last-child) {
  padding-right: 24px; }
  @media screen and (max-width: 768px) {
    .social-feed_filters-list_item:not(:last-child) {
      padding: 0 0 12px 0; } }

.social-feed_filters-list_item:not(.is-active) {
  cursor: pointer; }

.social-feed_filters-list_item.is-active {
  color: #E9423A; }

@media screen and (min-width: 769px) {
  .social-feed_filters-list_item:hover, .social-feed_filters-list_item:focus {
    color: #E9423A; } }

@media screen and (max-width: 768px) {
  .social-feed_filters-list_item {
    font-size: 18px;
    font-weight: 500; } }

.masonry_grid {
  margin: -12px -6px 0;
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .masonry_grid {
      margin: -12px 0 0;
      padding-top: 24px; } }

.masonry_item {
  width: 25%; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .masonry_item {
      width: 33.33%; } }
  @media screen and (max-width: 768px) {
    .masonry_item {
      width: auto; } }
  .masonry_item .edge {
    padding: 0 6px; }
    @media screen and (max-width: 768px) {
      .masonry_item .edge {
        padding: 0; } }
  .masonry_item.hidden {
    display: none; }

.social-feed_item {
  margin-top: 12px;
  background: #EFEFEF; }

.social-feed_item-tag {
  display: inline-block;
  padding: 9px 18px;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase; }
  .social-feed_item-tag:first-child {
    margin: 12px 0 0 12px; }
  .social-feed_item-tag:not(:first-child) {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 2; }

.social-feed_item-thumb {
  display: block;
  background: #0C0402;
  overflow: hidden; }
  @media screen and (min-width: 769px) {
    .social-feed_item-thumb:hover .social-feed_item-image,
    .social-feed_item-thumb:focus .social-feed_item-image {
      opacity: .32;
      transform: scale(1.05); } }

.social-feed_item-image {
  display: block;
  width: 100%;
  height: auto; }

.social-feed_item-text {
  padding: 24px; }
  .social-feed_item-text h3 {
    font-size: 18px; }
    @media screen and (max-width: 768px) {
      .social-feed_item-text h3 {
        font-size: 16px; } }
    .social-feed_item-text h3 a {
      text-decoration: none; }
      @media screen and (min-width: 769px) {
        .social-feed_item-text h3 a:hover, .social-feed_item-text h3 a:focus {
          color: #E9423A; } }
    .social-feed_item-text h3 + small + p {
      padding-top: 18px;
      font-weight: 400; }
  .social-feed_item-text p {
    font-weight: 500; }
    .social-feed_item-text p a {
      word-break: break-all; }
  .social-feed_item-text small {
    display: block;
    line-height: 1.4; }
    .social-feed_item-text small:not(:first-child) {
      padding-top: 12px; }
    @media screen and (min-width: 769px) {
      .social-feed_item-text small a:hover, .social-feed_item-text small a:focus {
        color: #E9423A; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL FEED ITEM NEWS -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.item_news .social-feed_item,
.item_news .social-feed_item-text {
  flex: 1 1 auto; }

.item_news-btn_wrapper {
  align-items: flex-end;
  margin-top: auto;
  padding-top: 24px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography {
  color: #666; }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography p, .typography ul, .typography ol {
    padding-top: 1.5em; }
    @media screen and (max-width: 768px) {
      .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography p, .typography ul, .typography ol {
        padding-top: 1em; } }
    .typography h1:first-child, .typography h2:first-child, .typography h3:first-child, .typography h4:first-child, .typography h5:first-child, .typography h6:first-child, .typography p:first-child, .typography ul:first-child, .typography ol:first-child {
      padding-top: 0; }
  .typography.half-spacing h1, .typography.half-spacing h2, .typography.half-spacing h3, .typography.half-spacing h4, .typography.half-spacing h5, .typography.half-spacing h6, .typography.half-spacing p, .typography.half-spacing ul, .typography.half-spacing ol {
    padding-top: .75em; }
    @media screen and (max-width: 768px) {
      .typography.half-spacing h1, .typography.half-spacing h2, .typography.half-spacing h3, .typography.half-spacing h4, .typography.half-spacing h5, .typography.half-spacing h6, .typography.half-spacing p, .typography.half-spacing ul, .typography.half-spacing ol {
        padding-top: .375em; } }
  .typography.typo-center {
    text-align: center; }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography strong, .typography .BoldText {
    color: #0C0402; }
  .typography ul {
    list-style: none;
    counter-reset: counter; }
    @media screen and (min-width: 769px) {
      .typography ul {
        margin-top: -.5em; } }
    .typography ul li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      line-height: 1.4; }
      .typography ul li:before {
        flex-shrink: 0; }
  .typography ul li:before {
    content: "";
    margin: 10px 12px 0 0;
    width: 5px;
    height: 5px;
    background: #0C0402;
    border-radius: 100%; }
    @media screen and (max-width: 768px) {
      .typography ul li:before {
        margin-top: 7px; } }
  .typography ol {
    padding-left: 16px;
    color: #008D61;
    font-weight: 500; }
    .typography ol li {
      color: #666;
      font-weight: 400; }
  .typography a:not(.btn):not(.link) {
    text-decoration: none;
    word-break: break-word;
    border-bottom: 1px solid #0C0402; }
    @media screen and (min-width: 769px) {
      .typography a:not(.btn):not(.link):hover, .typography a:not(.btn):not(.link):focus {
        color: black;
        border-bottom: 1px solid transparent; } }
  .typography blockquote {
    padding: 48px 0;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .typography blockquote {
        padding: 18px 0; } }
    .typography blockquote:before, .typography blockquote:after {
      content: "";
      left: 96px;
      right: 96px;
      height: 1px;
      background: #EFEFEF; }
      @media screen and (max-width: 768px) {
        .typography blockquote:before, .typography blockquote:after {
          left: 24px;
          right: 24px; } }
    .typography blockquote:before {
      top: 0; }
    .typography blockquote:after {
      bottom: 0; }
  .typography .typography-table_wrapper {
    margin-top: -12px;
    overflow-x: auto; }
    .typography .typography-table_wrapper table {
      min-width: 600px; }
  .typography table {
    width: 100%;
    border: none;
    border-collapse: collapse; }
  .typography th, .typography td {
    padding: 16px 24px 8px 0;
    border-bottom: 1px solid #EFEFEF; }
  .typography th {
    color: #0C0402;
    text-align: left; }
  .typography .BoldText {
    font-weight: 700; }
  .typography .ItalicText {
    font-style: italic; }
  .typography .UnderlineText {
    text-decoration: underline; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE HEADING ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page-heading_wrapper {
  padding: 48px 96px;
  flex: 0 0 auto; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .page-heading_wrapper {
      padding: 48px; } }
  @media screen and (max-width: 768px) {
    .page-heading_wrapper {
      padding: 24px; } }
  .page-heading_wrapper .page-preheading,
  .page-heading_wrapper .page-subheading {
    display: block;
    padding-bottom: 3px;
    color: rgba(255, 255, 255, 0.64);
    font-size: 14px;
    font-weight: 400; }
  .page-heading_wrapper .page-preheading {
    padding-bottom: 3px; }
  .page-heading_wrapper .page-subheading {
    padding-top: 12px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block:not(:first-child), .product-share_link-back_wrapper:not(:first-child) {
  padding-top: 96px; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .pb-block:not(:first-child), .product-share_link-back_wrapper:not(:first-child) {
      padding-top: 48px; } }
  @media screen and (max-width: 768px) {
    .pb-block:not(:first-child), .product-share_link-back_wrapper:not(:first-child) {
      padding-top: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (MEDIA + TEXT) -------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .pb-block_media-text_text {
    padding-top: 18px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (GRID OF TEXT ELEMENTS) ----------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_grid-text .column {
  padding-top: 1.5em; }
  @media screen and (max-width: 768px) {
    .pb-block_grid-text .column {
      padding-top: 1em; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (CTA) ----------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_cta-content {
  padding: 48px 96px;
  text-align: center; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .pb-block_cta-content {
      padding: 48px; } }
  @media screen and (max-width: 768px) {
    .pb-block_cta-content {
      padding: 24px; } }

/* --------------------------------------------------------------------------------------------------- */
/* FLASH MESSAGE -------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.flash-messages {
  position: fixed;
  left: 12px;
  bottom: 12px;
  z-index: 99999;
  line-height: 1.4;
  color: #FFF;
  font-size: 14px; }
  @media screen and (max-width: 768px) {
    .flash-messages {
      right: 12px; } }
  .flash-messages.is-all-dismissed {
    display: none; }

.flash-message {
  justify-content: space-between;
  position: relative;
  top: auto;
  left: auto;
  padding: 0;
  max-width: 360px;
  transform: none; }
  .flash-message + .flash-message {
    margin-top: 6px; }
  .flash-message.flash_error, .flash-message.flash_invalid {
    background: #D91E18; }
    .flash-message.flash_error .flash-message_close, .flash-message.flash_invalid .flash-message_close {
      background: #b01813; }
  .flash-message.flash_alert {
    background: #F89406; }
    .flash-message.flash_alert .flash-message_close {
      background: #cb7905; }
  .flash-message.flash_valid {
    background: #00B16A; }
    .flash-message.flash_valid .flash-message_close {
      background: #00834f; }
  .flash-message.flash_update {
    background: #1E8BC3; }
    .flash-message.flash_update .flash-message_close {
      background: #186f9b; }
  .flash-message.is-dismissed {
    opacity: 0;
    transform: translateX(12px);
    visibility: hidden; }

.flash-message_close {
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
  width: 48px; }
  .flash-message_close svg {
    display: block;
    width: 14px;
    height: 14px;
    fill: #FFF;
    opacity: .48; }
  @media screen and (min-width: 769px) {
    .flash-message_close:hover svg,
    .flash-message_close:focus svg {
      opacity: 1; } }

/* --------------------------------------------------------------------------------------------------- */
/* WIDGETS ------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.widgets {
  flex: 1 1 auto;
  margin-top: -12px; }
  .widgets:not(:first-child) {
    padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .widgets {
      margin-top: -6px;
      padding-bottom: 24px; }
      .widgets .widget:first-child {
        border: none;
        padding: 0; }
      .widgets:not(:first-child) {
        padding-top: 24px; } }

.widget {
  margin-top: 12px;
  padding: 24px 24px 0;
  border: 2px solid #EFEFEF;
  /*
		.nav-side {
			padding-bottom:24px;
		}
		*/ }
  @media screen and (max-width: 768px) {
    .widget {
      margin-top: 6px; } }

.widget-heading {
  font-size: 18px;
  font-weight: 700; }
  .widget-heading:not(:first-child) {
    padding-top: 18px; }

.widget-image_wrapper,
.widget-button {
  margin: 0 -26px;
  width: calc(100% + 52px); }

.widget-image_wrapper:first-child {
  margin-top: -26px; }

.widget-image_wrapper:not(:first-child) {
  padding-top: 18px; }

.widget-text {
  padding-top: 12px; }
  .widget-text:last-child {
    padding-bottom: 24px; }

.widget-button {
  margin-top: 18px;
  margin-bottom: -2px;
  text-align: center; }

@media screen and (max-width: 768px) {
  .nav-side + .widgets {
    padding-top: 0; } }

/* --------------------------------------------------------------------------------------------------- */
/* SPEKTRIX ------------------------------------------------------------------------------------------ */
/* --------------------------------------------------------------------------------------------------- */
.spektrix-iframe {
  display: block;
  width: 100%;
  min-height: 770px;
  border: none;
  /*
	@media screen and (max-width:768px)
	{
		margin:-24px -24px 0;
		width:calc(100% + 48px);
	}
	*/ }

.pb-block_spektrix:not(:first-child) {
  margin-top: 96px;
  border-top: 4px solid #EFEFEF; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .pb-block_spektrix:not(:first-child) {
      margin-top: 48px; } }
  @media screen and (max-width: 768px) {
    .pb-block_spektrix:not(:first-child) {
      margin-top: 24px; } }

.pb-block_spektrix h6 {
  color: #008D61; }

.pb-block_spektrix iframe {
  margin-top: 24px; }

.pb-block_spektrix.pb-block_spektrix_my-account iframe {
  height: 460px; }

.pb-block_spektrix.pb-block_spektrix_cart iframe {
  height: 410px; }

.pb-block_spektrix.pb-block_spektrix_error iframe {
  height: 135px; }

/* --------------------------------------------------------------------------------------------------- */
/* POST PASSWORD FORM -------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.post-password-form p:first-child {
  margin-bottom: 24px;
  font-weight: bold; }

.post-password-form label {
  line-height: 1.4;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase; }

.post-password-form input[type="password"] {
  margin: 0 0 24px;
  padding: 16px 24px;
  width: 100%;
  height: auto;
  line-height: 1.4;
  color: #666;
  font-family: Quicksand,sans-serif;
  font-size: 100%;
  font-size: 16px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #0c04021f;
  border-radius: 0;
  appearance: none; }

.post-password-form input[type="submit"] {
  margin: 0;
  line-height: 1.6;
  font-family: Quicksand,sans-serif;
  font-size: 100%;
  border: 0 solid;
  border-radius: 0;
  text-transform: none;
  color: #fff !important;
  background: #e9423a !important;
  appearance: none;
  cursor: pointer;
  overflow: visible;
  transition: color .3s ease-in-out, background .3s ease-in-out, border .3s ease-in-out;
  display: inline-block;
  box-sizing: border-box;
  padding: 16px 24px;
  font-weight: 500;
  width: auto;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  min-width: 200px;
  text-align: center; }
  .post-password-form input[type="submit"]:hover {
    background: #cc271f !important; }

/* LAYOUT */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wrapper {
  flex-shrink: 0;
  margin-top: 160px;
  min-height: calc(100vh - 160px); }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .wrapper {
      margin-top: 198px;
      min-height: calc(100vh - 198px); } }
  @media screen and (max-width: 768px) {
    .wrapper {
      margin-top: 60px;
      min-height: calc(100vh - 60px); } }
  @media screen and (max-width: 768px) {
    .wrapper.page-has-sidebar {
      margin-bottom: 54px;
      min-height: calc(100vh - 114px); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-container {
  flex: 1 1 auto; }

@media screen and (max-width: 768px) {
  .col-lhc {
    order: 2;
    padding-top: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE TAG MANAGER ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.gtm-noscript {
  display: none;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.header {
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 32px;
  background: #FFF; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .header {
      padding: 24px; } }
  @media screen and (max-width: 768px) {
    .header {
      padding: 6px; } }
  .header:after {
    content: "";
    left: 0;
    right: 0;
    bottom: -6px;
    height: 6px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0; }
  .header.is-sticky:after {
    opacity: 1; }

.header-content {
  width: 100%; }
  @media screen and (max-width: 940px) and (min-width: 769px) {
    .header-content > .grid {
      flex-wrap: nowrap;
      margin-left: -24px;
      margin-right: -24px; }
      .header-content > .grid > .column {
        padding-left: 24px;
        padding-right: 24px; } }

.header-content_lhc,
.header-content_rhc {
  flex-direction: row !important; }
  @media screen and (max-width: 1110px) and (min-width: 769px) {
    .header-content_lhc,
    .header-content_rhc {
      flex-shrink: 0;
      width: auto !important; } }

@media screen and (max-width: 1110px) and (min-width: 769px) {
  .header-content_rhc {
    flex: 1 1 auto;
    justify-content: flex-end; } }

.header-block {
  justify-content: center;
  padding-left: 16px;
  width: 100%;
  max-width: calc(55% - 16px);
  line-height: 1.3; }
  @media screen and (max-width: 1220px) {
    .header-block {
      display: none; } }

.header-block_top .box-office_label {
  display: block; }

.header-block_bottom {
  padding-top: 9px; }

.header-top_block-link,
.header-block_bottom-link {
  align-items: center;
  font-size: 14px; }
  .header-top_block-link svg,
  .header-block_bottom-link svg {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    fill: #0C0402; }
  .header-top_block-link span,
  .header-block_bottom-link span {
    padding-left: 12px; }
  @media screen and (min-width: 769px) {
    .header-top_block-link:not(.is-current):hover, .header-top_block-link:not(.is-current):focus,
    .header-block_bottom-link:not(.is-current):hover,
    .header-block_bottom-link:not(.is-current):focus {
      color: #E9423A; }
      .header-top_block-link:not(.is-current):hover svg, .header-top_block-link:not(.is-current):focus svg,
      .header-block_bottom-link:not(.is-current):hover svg,
      .header-block_bottom-link:not(.is-current):focus svg {
        fill: #E9423A; } }
  .header-top_block-link.is-current svg,
  .header-block_bottom-link.is-current svg {
    fill: #008D61; }

.header-top_block-link.js-search_trigger {
  display: none; }

.header-block_bottom-link {
  color: #0C0402; }
  .header-block_bottom-link:first-child {
    padding-right: 16px; }
  .header-block_bottom-link + .header-block_bottom-link:not(:last-child) {
    padding-left: 16px; }
  .header-block_bottom-link:not(:last-child) {
    width: 100%; }
  .header-block_bottom-link.js-search_trigger {
    opacity: 0;
    visibility: hidden; }

@media screen and (max-width: 768px) {
  spektrix-basket-item-count {
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 2;
    width: 24px;
    height: 24px;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    background: #E9423A;
    border-radius: 100%; } }

spektrix-basket-item-count span {
  margin: 0 !important;
  padding: 0 !important; }
  @media screen and (min-width: 769px) {
    spektrix-basket-item-count span {
      padding: 0 0 0 3px !important; }
      spektrix-basket-item-count span:before {
        content: "("; }
      spektrix-basket-item-count span:after {
        content: ")"; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER (TOP) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.header-top {
  display: none;
  margin: -24px -24px 24px;
  padding: 16px 24px;
  line-height: 1.2;
  width: 100%;
  color: rgba(255, 255, 255, 0.48);
  background: #2D2D2D; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .header-top {
      display: flex;
      flex-direction: row; } }
  .header-top .box-office {
    padding-right: 12px;
    width: auto;
    max-width: none; }
  .header-top .box-office_label {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase; }
  .header-top .box-office_link {
    color: #FFF;
    font-size: 18px;
    font-weight: 700; }
  .header-top .opening-hours {
    padding: 4px 0 0;
    width: auto;
    color: rgba(255, 255, 255, 0.48);
    font-size: 14px; }
  .header-top .header-top_block {
    justify-content: flex-end;
    flex: 1 1 auto; }
  .header-top .header-top_block-link {
    color: rgba(255, 255, 255, 0.48); }
    .header-top .header-top_block-link svg {
      fill: #FFF; }
    .header-top .header-top_block-link span {
      padding-left: 8px; }
    .header-top .header-top_block-link + .header-top_block-link {
      padding-left: 24px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX OFFICE ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (min-width: 769px) {
  .box-office {
    padding-right: 16px;
    width: 100%;
    max-width: calc(50% - 25px); } }

@media screen and (max-width: 768px) {
  .box-office {
    font-size: 18px; } }

@media screen and (min-width: 769px) {
  .box-office_link:hover, .box-office_link:focus {
    color: #E9423A; } }

@media screen and (max-width: 768px) {
  .box-office_link {
    color: #FFF; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* OPENING HOURS ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (min-width: 769px) {
  .opening-hours {
    padding-left: 16px;
    color: #666;
    width: 100%;
    max-width: calc(50% - 25px); } }

@media screen and (max-width: 768px) {
  .opening-hours {
    padding-top: 9px;
    color: rgba(255, 255, 255, 0.64); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer {
  align-items: center;
  padding: 96px 96px 48px;
  background: #2D2D2D; }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .footer {
      padding: 48px 48px 24px; } }
  @media screen and (max-width: 768px) {
    .footer {
      padding: 24px; } }

.footer-content {
  width: 100%; }

.footer-copyright {
  padding-top: 96px;
  color: rgba(255, 255, 255, 0.48); }
  @media screen and (max-width: 1220px) and (min-width: 769px) {
    .footer-copyright {
      padding-top: 48px; } }
  @media screen and (max-width: 768px) {
    .footer-copyright {
      padding-top: 24px;
      font-size: 12px; } }

.footer-copyright_link {
  color: rgba(255, 255, 255, 0.48);
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.48); }
  @media screen and (min-width: 769px) {
    .footer-copyright_link:hover, .footer-copyright_link:focus {
      color: #FFF;
      border-bottom: 1px solid transparent; } }
