/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading {
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPE 1) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading-01 {
	font-weight:300;
}
