/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL FEED ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.social-feed {
	overflow:hidden;
}

.social-feed_filters-wrapper {
	@extend .flex_row;
	align-items:flex-end;

	@media screen and (max-width:768px)
	{
		flex-direction:column;
		align-items:flex-start;
	}
}

		.social-feed_filters {
			padding:0 0 8px 48px;

			@media screen and (max-width:768px)
			{
				padding:24px 0 0;
				width:100%;
			}
		}
			.social-feed_filters-trigger {
				@extend .flex_row;
				justify-content:space-between;
				align-items:center;
				position:relative;
				z-index:2;
				padding-bottom:6px;
				width:100%;
				color:$color_theme_01-01;
				font-weight:500;
				border-bottom:1px solid $color_theme_01-05;

				svg {
					display:block;
					width:14px; height:14px;
					fill:$color_theme_01-01;
					transform:rotate(90deg);
				}
			}
			.social-feed_filters-list_wrapper {
				@extend .animate;

				@media screen and (max-width:768px)
				{
					position:fixed;
					top:0; left:0; right:0; bottom:0;
					z-index:1001;
					background:rgba(#FFF, .48);
					opacity:0;
					visibility:hidden;

					&.is-active {
						opacity:1;
						visibility:visible;

						& > .social-feed_filters-list {
							transform:translateX(0);
						}
					}
				}
			}
				.social-feed_filters-list {
					@extend .flex_row;
					list-style:none;

					@media screen and (max-width:768px)
					{
						display:block;
						position:absolute;
						top:0; right:0; bottom:0;
						z-index:1;
						padding:36px 48px;
						width:calc(100% - 60px); height:100%;
						color:#FFF;
						background:$color_theme_01-02;
						box-sizing:border-box;
						overflow-y:auto;
						transform:translateX(12px);
					}
				}
					.social-feed_filters-list_item {
						@extend .animate;

						&:not(:last-child) {
							padding-right:24px;

							@media screen and (max-width:768px)
							{
								padding:0 0 12px 0;
							}
						}
						&:not(.is-active) {
							cursor:pointer;
						}
						&.is-active {
							color:$color_theme_02-01;
						}

						@media screen and (min-width:769px)
						{
							&:hover,
							&:focus {
								color:$color_theme_02-01;
							}
						}
						@media screen and (max-width:768px)
						{
							font-size:18px;
							font-weight:500;
						}
					}

.masonry_grid {
	margin:-12px -6px 0; padding-top:48px;

	@media screen and (max-width:768px)
	{
		margin:-12px 0 0; padding-top:24px;
	}
}
	.masonry_item {
		width:25%;

		@media screen and (max-width:1220px) and (min-width:769px)
		{
			width:33.33%;
		}
		@media screen and (max-width:768px)
		{
			width:auto;
		}

		.edge {
			padding:0 6px;

			@media screen and (max-width:768px)
			{
				padding:0;
			}
		}

		&.hidden { display:none; }
	}

.social-feed_item {
	@extend .relative;
	margin-top:12px;
	background:$color_theme_01-05;
}
	.social-feed_item-tag {
		display:inline-block;
		padding:9px 18px;
		color:#FFF;
		font-size:12px;
		font-weight:500;
		text-transform:uppercase;

		&:first-child {
			margin:12px 0 0 12px;
		}
		&:not(:first-child) {
			position:absolute;
			top:12px; left:12px;
			z-index:2;
		}
	}
	.social-feed_item-thumb {
		display:block;
		background:$color_theme_01-01;
		overflow:hidden;

		@media screen and (min-width:769px)
		{
			&:hover .social-feed_item-image,
			&:focus .social-feed_item-image {
				opacity:.32;
				transform:scale(1.05);
			}
		}
	}
		.social-feed_item-image {
			@extend .animate;
			display:block;
			width:100%; height:auto;
		}
	.social-feed_item-text {
		padding:24px;

		h3 {
			font-size:18px;

			@media screen and (max-width:768px)
			{
				font-size:$font_size_base;
			}

			a {
				@extend .animate;
				text-decoration:none;

				@media screen and (min-width:769px)
				{
					&:hover,
					&:focus {
						color:$color_theme_02-01;
					}
				}
			}

			& + small + p {
				padding-top:18px;
				font-weight:400;
			}
		}
		p {
			font-weight:500;

			a {
				word-break:break-all;
			}
		}
		small {
			display:block;
			line-height:$line_height_small;

			&:not(:first-child) {
				padding-top:12px;
			}

			a {
				@extend .animate;

				@media screen and (min-width:769px)
				{
					&:hover,
					&:focus {
						color:$color_theme_02-01;
					}
				}
			}
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL FEED ITEM NEWS -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.item_news {

	.social-feed_item,
	.social-feed_item-text {
		@extend .flex_column;
		flex:1 1 auto;
	}
}

.item_news-btn_wrapper {
	@extend .flex_column;
	align-items:flex-end;
	margin-top:auto; padding-top:24px;
}
