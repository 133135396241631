/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX -------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box {
}
	.box-content {
		width:100%;
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 1) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box-01 {
	@extend .flex_column;
	align-items:center;
	flex:1 1 auto;
}
	.box-01_link-wrapper {
		padding-top:48px;
		text-align:center;

		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}
	}
		.box-01_link {
			@extend .animate;
			color:$color_theme_01-01;
			text-decoration:none;
			border-bottom:1px solid $color_theme_01-01;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:$color_theme_02-01;
					border-bottom:1px solid transparent;
				}
			}
		}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 2) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box-02 {
	@extend .flex_row;
	padding:32px 0;
	border-top:2px solid $color_theme_01-05; border-bottom:2px solid $color_theme_01-05;

	@media screen and (max-width:768px)
	{
		padding:24px 0;
	}

	@media screen and (max-width:768px)
	{
		flex-direction:column;
	}
}
	.box-02_heading {
		padding-right:48px;
		min-width:20%;
		font-size:24px;
		box-sizing:border-box;
	}
